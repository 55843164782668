import React from 'react';

import { Link } from 'react-router-dom';
import ArtificialIntelligenceBookImage from '../assets/Images/eGuide05.png'
import monetizationStrategyBookImage from '../assets/Images/eGuide02.png'
import electricVehilcleBookImage from '../assets/Images/eGuide03.png'
import uxMobileBookImage from '../assets/Images/eGuide06.png'
import cloudbasedBookImage from '../assets/Images/eGuide05.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UnitedStateImage1 from '../assets/Images/eGuide19.png'
import UnitedStateImage2 from '../assets/Images/eGuide20.png'
import dubleCircle from '../assets/SVG/dbl_crcle.svg'
import maskCircles from '../assets/SVG/Mask-Group-901736.svg'
const ArtificialInteligenceFooter = () => {
  return (
    <>
      <div className='bg-MakeMoneyFromAppBgImage bg-cover bg-no-repeat overflow-hidden'>
        <div className='container mx-auto py-5'>
          <div className='w-full flex justify-between'>
            <div className='text-white flex flex-col justify-center gap-y-5 w-[50%]'>
              <h1 className='text-[40px] font-bold'>Artificial Intelligence (AI) – The Next Big Thing in Logistics</h1>
              <p className='text-xl leading-8'>According to a survey AI technology can increase productivity by 40-45%. Want to know how? Go through this resolute dossier to find an answer.</p>
              <p className='text-secondaryText'>0 View | 1 min | Published On: 26/04/2023 Last Updated: 15/05/2023</p>
            </div>
            <div className='flex items-center justify-center relative'>
              <figure className='w-[50%] animate-custom-bounce transition-transform'>
                <img className='w-[600px]' src={ArtificialIntelligenceBookImage} alt="Strategies Guide" />
              </figure>
              <figure className='absolute -right-[100px] -bottom-10'>
                <img src={dubleCircle} alt="Dumble Circles" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-greyMediumBg'>
        <div className='container py-5'>
          <div className='grid grid-cols-3 space-x-10'>
            <div className='col-span-2'>
              <div className='text-xl leading-8 font-normal flex flex-col space-y-10 relative z-0'>
                <figure className='absolute -left-20 -top-12 -z-10'>
                  <img src={maskCircles} alt="MAsk Circles" />
                </figure>
                <p>Needless to say, AI has offered a pre-planned approach, better process methodology, and faster & accurate ways to achieve business targets. A reason why it has established its roots in various industry verticals. Then how can the logistics industry remain behind?</p>
                <p>But, how do you design and develop a mobile app that offers seamless UX?</p>
                <p>A recent survey reveals that 60-65% of industry leaders are in favor of the point that the logistics, transportation, and supply chain industry is in dire need of profound transformation. Then which technology can perform better than AI?</p>
                <p>A report by Accenture states that 36% of large, small, and mid-sized companies have successfully adopted AI as prime technology for their logistics and supply chain processes. In addition to this, many organizations are in a queue to implement this technology at a rapid pace.</p>
                <p >Well, if you own a logistics business or somehow you are related to logistics and supply chain this e-guide is for you. After going through this guide you will get to know:</p>
                <ul>
                  <li className="relative">
                    <span className="absolute h-[10px] w-[10px] bg-secondaryBg rounded-full left-0 top-1/2 transform -translate-y-1/2"></span>
                    <p className='ml-7'>Why is AI necessary for logistics?</p>
                  </li>
                  <li className="relative">
                    <span className="absolute h-[10px] w-[10px] bg-secondaryBg rounded-full left-0 top-1/2 transform -translate-y-1/2"></span>
                    <p className='ml-7'>How can it help you earn more ROI?  </p>
                  </li>
                  <li className="relative">
                    <span className="absolute h-[10px] w-[10px] bg-secondaryBg rounded-full left-0 top-1/4 transform -translate-y-1/2"></span>
                    <p className='ml-7'>What are the challenges you may encounter while implementing AI and much more?</p>
                  </li>
                </ul>
                <p>So without any further delay download the pdf of this eGuide to take your logistics business to the next level.</p>
              </div>
            </div>
            <div>
              <form className='drop-shadow-md bg-whiteBg p-3 flex flex-col space-y-3'>
                <small className='text-primaryText font-medium'>Avail your free copy now!</small>
                <h2 className='text-2xl font-bold text-black'>Get going with this ebook in PDF Format</h2>
                <label>Kindly fill out the form below and get access to a download link right away in your inbox.</label>
                <input className='border-b-[1px] focus:outline-none  p-2 w-full' type='text' name='' placeholder='Full Name' />
                <input className='border-b-[1px] focus:outline-none p-2 w-full ' type='email' name='' placeholder='Email' />
                <input className='border-b-[1px] focus:outline-none p-2 w-full ' type='tel' name='' placeholder='Phone Number' />

                <div className="flex items-start">
                  <input type="checkbox" id="vehicle1" name="" value="" className="mt-1 h-5 w-5 mr-2" />
                  <label className='text-md font-bold text-text' htmlFor="vehicle1">I agree to opt for further conversations with SCube</label>
                </div>

                <p>You can end the conversations anytime. To have extended information regarding the same, kindly go through our <span className='text-xl text-primaryText'>privacy policy.</span></p>
                <button className='bg-primaryBg rounded-md w-full text-white py-2 my-4'>
                  Download this ebook
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        <h1 className='text-3xl font-semibold text-center'>Related eGuides</h1>
        <div className='grid grid-cols-3 space-x-5 py-4'>
          <div className='bg-[#f3fffb] rounded-xl'>
            <div className='p-4 hover:text-primaryText'>
              <Link to={''} className='flex flex-col space-y-3'>
                <figure className='flex items-center justify-center'>
                  <img className='w-[170px]' src={monetizationStrategyBookImage} alt="ElectricVehiles" />
                </figure>
                <p className='font-[500] '>Electric Vehicle Software Development - A Comprehensi</p>
                <p className='text-text'>Needless to say, Electric Vehicles, or what we call EVs, are in dire need of...</p>
                <div className='flex justify-between'>
                  <p className='text-sm font-bold text-text'>Huzaifa Ali</p>
                  <p className=' text-text'>May-17-2023</p>
                </div>
              </Link>
            </div>
          </div>

          <div className='flex flex-col gap-y-5'>
            <div className='bg-[#edeffe] rounded-xl'>
              <div className='p-4 hover:text-primaryText'>
                <Link to={''} className='flex justify-between items-center gap-x-4'>
                  <figure className='flex items-center justify-center'>
                    <img className='w-[230px]' src={electricVehilcleBookImage} alt="ElectricVehiles" />
                  </figure>
                  <div className='flex flex-col space-y-2'>
                    <p className='font-[500] '>Electric Vehicle Software Development - A Comprehensi</p>
                    <p className='text-text'>Needless to say, Electric Vehicles, or what we call EVs, are in dire need of...</p>
                    <div className='flex justify-between'>
                      <p className='text-sm font-bold text-text'>Ameer Hamza</p>
                      <p className=' text-text'>May-17-2023</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className='bg-[#fffcea] rounded-xl'>
              <div className='p-4 hover:text-primaryText'>
                <Link to={''} className='flex justify-between items-center gap-x-4'>
                  <figure className='flex items-center justify-center'>
                    <img className='w-[300px] h-[150px]' src={cloudbasedBookImage} alt="ElectricVehiles" />
                  </figure>
                  <div className='flex flex-col space-y-2'>
                    <p className='font-[500] '>Artificial Intelligence (AI) - The Next Big Thing in</p>
                    <p className='text-text'>Needless to say, AI has offered a pre-planned approach, better process methodology, and faster &...</p>
                    <div className='flex justify-between'>
                      <p className='text-sm font-bold text-text'>Ameer Hamza</p>
                      <p className=' text-text'>May-17-2023</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>

          <div className='bg-[#fff2f2] rounded-xl'>
            <div className='p-4 hover:text-primaryText'>
              <Link to={''} className='flex flex-col space-y-3'>
                <figure className='flex items-center justify-center'>
                  <img className='w-[180px]' src={uxMobileBookImage} alt="ElectricVehiles" />
                </figure>
                <p className='font-[500] '>Mastering Cloud-Based Mobile App Development: An Insi</p>
                <p className='text-text'>Are you looking to develop a mobile app that can scale seamlessly and is cost-...</p>
                <div className='flex justify-between'>
                  <p className='text-sm font-bold text-text'>Suleman Ali</p>
                  <p className=' text-text'>Mar-12-2023</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='flex'>
        <Link to={'#!'} className='bg-gradiBg rounded-[50px] w-auto mx-auto px-4 py-[12px] '>
                <p className='text-md font-bold text-white flex items-center gap-2  hover:text-end'>View All 
                <span className='animate-bounce-horizontal hover:text-start text-end bg-whiteBg text-black rounded-full flex items-center justify-center h-8    w-8 '><ArrowForwardIcon fontSize='small' /></span></p>
            </Link>
        </div>

        <div class="grid grid-cols-2 divide-x py-5 divide-Bg">
          <div className='text-[80px] font-bold leading-snug'>
            <p>Join <span className='text-[#00649E]'>60,000+</span> Subscribers</p>
          </div>

          <form>
            <div className='flex flex-col space-y-6 pl-[60px]'>
              <label className='text-2xl font-medium'>Get the weekly updates on the newest brand stories, business models and technology right in your inbox.</label>
              <input className='border-b-[1px] border-danger focus:border-danger focus:outline-none text-xl p-2 w-2/3' type='email' name='' placeholder='Enter Your Email' />
              <div>
                <button className='bg-primaryBg px-5 py-[12px] rounded-lg text-white'>Submit</button>
              </div>
            </div>
          </form>
        </div>

        <div className='grid grid-cols-2 divide-x divide-primaryBg'>
          <div className='flex gap-x-3 items-center justify-center'>
            <figure>
              <img src={UnitedStateImage1} alt="United State" />
            </figure>
            <div>
              <h2 className='text-2xl font-semibold'>PAKISTAN</h2>
              <small className='font-medium'>Jinnah Park, Office No #05, Rahimyar Khan, Pakistan</small>
            </div>
          </div>

          <div className='flex gap-x-3 items-center justify-center'>
            <figure>
              <img src={UnitedStateImage2} alt="United State" />
            </figure>
            <div>
              <h2 className='text-2xl font-semibold'>PAKISTAN</h2>
              <small className='font-medium'>Hassan Colony, Apartment No #101, Rahimyar Khan, Pakistan</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtificialInteligenceFooter;
