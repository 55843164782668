import React, { useState } from 'react'
import PortfolioModal from 'service/portfolioModal';
import SearchIcon from '@mui/icons-material/Search';
import Androidicon from 'assets/png/portflio/android.png'
import IosStore from 'assets/png/portflio/iosStore.png'
import WebIcon from 'assets/png/portflio/webicon.png'
export default function Portfolio() {

    // State to track the selected category
    const [selectedCategory, setSelectedCategory] = useState('All');

    const handleCategoryClick = (clickedCategory) => {
        setSelectedCategory(clickedCategory);
    };

    const getFilteredCards = () => {
        if (selectedCategory === 'All') {
            return PortfolioModal; // Return all cards if category is 'All'
        }
        else if (selectedCategory === 'Automation') {
            return PortfolioModal.filter(card => card.type === 'automation');
        }
        else if (selectedCategory === 'Comminucation') {
            return PortfolioModal.filter(card => card.type === 'communication');
        }
        else if (selectedCategory === 'Healthcare') {
            return PortfolioModal.filter(card => card.type === 'healthcare');
        }
        else if (selectedCategory === 'Car Rental') {
            return PortfolioModal.filter(card => card.type === 'carRental');
        }
        else if (selectedCategory === 'Education') {
            return PortfolioModal.filter(card => card.type === 'education');
        }
        else if (selectedCategory === 'Music') {
            return PortfolioModal.filter(card => card.type === 'music');
        }
        else if (selectedCategory === 'Entertainment') {
            return PortfolioModal.filter(card => card.type === 'entertainment');
        }
        else if (selectedCategory === 'Ecommerce App') {
            return PortfolioModal.filter(card => card.type === 'ecommerceApp');
        }
        else if (selectedCategory === 'Travel & Tourism') {
            return PortfolioModal.filter(card => card.type === 'travelAndTourism');
        }
        else if (selectedCategory === 'Gaming') {
            return PortfolioModal.filter(card => card.type === 'gaming');
        }
        else if (selectedCategory === 'Finance') {
            return PortfolioModal.filter(card => card.type === 'finance');
        }
        else if (selectedCategory === 'Real Estate') {
            return PortfolioModal.filter(card => card.type === 'realEstate');
        }
        else {
            return PortfolioModal.filter(card => card.type === 'marketting');
        }
    };

    // List of categories
    const categories = [
        'All',
        'Automation',
        'Comminucation',
        'Car Rental',
        'Education',
        'Ecommerce App',
        'Entertainment',
        'Finance',
        'Gaming',
        'Healthcare',
        'Music',
        'Marketting',
        'Real Estate',
        'Travel & Tourism',
    ];
    return (
        <>
            <section className='tablet:py-24 sm:py-12 container mx-auto '>
                <div className='text-center'>
                    <div className='flex flex-row justify-center font-black md:text-5xl tablet:text-4xl sm:text-2xl leading-5'>
                        <h1 className='pl-3 mb-2.5'> <span className='text-primaryText'>Transform </span>
                            The World With Your Idea.</h1>
                    </div>
                    <div>
                        <p className='tablet:text-xl sm:text-base leading-6'>From dream to reality, here are some apps we are proud to be part of.</p>
                    </div>
                </div>
                <div className='flex tablet:flex-row flex-col justify-between md:mt-20 w-full'>
                    <div className='flex flex-column gap-4 tablet:w-[20%] relative'>
                        {/* <div>
                            <input type="text" className='border-2 w-[160px] absolute' />
                            <button>
                                <SearchIcon className='relative -right-[134px] bg-[#00649E] text-white rounded p-1' />
                            </button>
                        </div> */}
                        <div className='w-full pt-16 sticky top-16 bottom-0'>
                            <div className='tablet:bg-[#E8F5FF] tablet:rounded-3xl tablet:h-[400px]  overflow-y-scroll custom-scrollbar'>
                                <ul className='tablet:p-4 flex tablet:flex-col sm:flex-nowrap gap-4'>
                                    {categories.map((category) => (
                                        <li key={category} className='cursor-pointer text-text font-bold sm:bg-[#E8F5FF] sm:p-3 tablet:p-0 sm:rounded-xl whitespace-nowrap' onClick={() => handleCategoryClick(category)}>
                                            {category}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                    {/* Display filtered cards based on the selected category */}
                    <div className='tablet:ms-12 pt-2 tablet:w-[80%]'>
                        <h3 className='pb-6 font-bold tablet:text-4xl sm:text-2xl text-danger'>{selectedCategory}</h3>
                        {getFilteredCards().map((card) => (
                            <div key={card.id} className='mb-12 rounded-4 bg-cover p-[20px]  tablet:h-auto' style={{ backgroundImage: `url(${card.image})` }}>
                                <div className='flex tablet:flex-row sm:flex-col sm:items-center justify-center tablet:items-start tablet:gap-x-5 sm:gap-3 w-full'>
                                    <div className='flex flex-column items-stretch justify-stretch gap-y-3 tablet:w-3/4 ps-8'>
                                        <div className='flex flex-row items-center gap-x-3'>
                                            <figure className='max-w-full max-h-full'>
                                                <img src={card.titleImg} alt="Card Image" className='tablet:w-[100px] sm:w-[70px] h-[80px] mix-blend-multiply object-scale-down object-contain' />
                                            </figure>
                                            <h1 className='tablet:text-3xl sm:text-xl font-bold' style={{ color: `${card.titleColor}` }}>{card.title}</h1>
                                        </div>
                                        <p className='tablet:w-[400px] sm:text-xs tablet:text-base text-text'>{card.description}</p>
                                        <div className='flex flex-row justify-between w-[80%]'>
                                            {card.platform === "Web" && (
                                                <div className='flex flex-row justify-between tablet:w-80'>
                                                    <div>
                                                        <h5 className='font-semibold' style={{ color: `${card.titleColor}` }}>Platforms</h5>
                                                        <p className='font-medium'>{card.platform}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {card.platform === "Android,Web and iOS" && (
                                                <div className='w-100'>
                                                    <div className='flex flex-row justify-between tablet:w-80'>
                                                        <h5>Downloads</h5>
                                                        <h5 className='ms-4 font-semibold' style={{ color: `${card.titleColor}` }}>Platforms</h5>
                                                    </div>
                                                    <div className='flex flex-row justify-between pt-0 mt-0 font-bold'>
                                                        <p>{card.downloads}</p>
                                                        <p className='text-center'>{card.platform}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {card.platform === "Android and iOS" && (
                                                <div className=' w-100'>
                                                    <div className='flex flex-row justify-between'>
                                                        <h5>Downloads</h5>
                                                        <h5 className='ms-4'>Platforms</h5>
                                                    </div>
                                                    <div className='flex flex-row justify-between pt-0 mt-0 font-bold'>
                                                        <p>{card.downloads}</p>
                                                        <p >{card.platform}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {/* <h5>Downloads</h5>
                                            <h5 className='ms-4'>Platforms</h5> */}
                                        </div>
                                        {card.platform === "Web" && (
                                            <div className='flex flex-row justify-between tablet:w-80'>
                                                <a href={card.weblink} target="_blank" rel='noreferrer'>
                                                    <img src={WebIcon} alt="WebIcon" style={{ width: "40px" }} />
                                                </a>
                                            </div>
                                        )}
                                        {card.platform === "Android,Web and iOS" && (
                                            <div className='flex flex-row justify-between tablet:w-80'>
                                                <a href={card.weblink} target="_blank" rel='noreferrer'>
                                                    <img src={WebIcon} alt="WebIcon" style={{ width: "40px" }} />
                                                </a>
                                                <a href={card.weblink} target="_blank" rel='noreferrer'>
                                                    <img src={Androidicon} alt="Androidicon" style={{ width: "40px" }} />
                                                </a>
                                                <a href={card.iOSlink} target="_blank" rel='noreferrer'>
                                                    <img src={IosStore} alt="IoSstore" style={{ width: "40px" }} />
                                                </a>
                                            </div>
                                        )}
                                        {card.platform === "Android and iOS" && (
                                            <div className='flex flex-row justify-between tablet:w-80'>
                                                <a href={card.weblink} target="_blank" rel='noreferrer'>
                                                    <img src={WebIcon} alt="WebIcon" style={{ width: "40px" }} />
                                                </a>
                                                <a href={card.weblink} target="_blank" rel='noreferrer'>
                                                    <img src={Androidicon} alt="Androidicon" style={{ width: "40px" }} />
                                                </a>
                                                <a href={card.iOSlink} target="_blank" rel='noreferrer'>
                                                    <img src={IosStore} alt="IoSstore" style={{ width: "40px" }} />
                                                </a>
                                            </div>
                                        )}

                                    </div>
                                    <div className='flex items-center justify-center tablet:w-[50%]'>
                                        <img src={card.mobileImg} alt="card" className='tablet:w-[180px] tablet:h-[350px] sm:w-[120px] sm:h-[200px]' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}
