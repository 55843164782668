import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import swift from '../../assets/Images/Swift.png'
import objC from '../../assets/Images/objC.png'
import kotlin from '../../assets/Images/Kotlin.png'
import flutter from '../../assets/Images/flutter.png'
import native from '../../assets/Images/react.png'
import node from '../../assets/Images/node.png'
import react from '../../assets/Images/react.png'
import angular from '../../assets/Images/angular.png'
import vue from '../../assets/Images/vueJs.png'
import net from '../../assets/Images/dotNet.png'
import typeScript from '../../assets/Images/typeScript.png'
import python from '../../assets/Images/python.png'
import java from '../../assets/Images/java.png'
import php from '../../assets/Images/php.png'

const TechnologyStack = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            delay:200,
            once: true
        });
    }, []);

    return (
        <>
            <div className='sm:py-3'>
                <h2 data-aos="fade-up" className='sm:text-[20px] md:text-[48px] pt-5 font-bold text-center mb-3'>Technology Stack</h2>
                <ul data-aos="zoom-in-down" className='container mx-auto flex items-center justify-between flex-wrap md:py-5'>
                    <li className='flex items-center justify-between sm:flex-col md:flex-row w-full'>
                        <div className="flex items-center justify-start gap-2 text-center relative md:py-4 sm:py-2 mb-0  md:before:border-t before:border-solid  before:absolute before:top-0 before:w-full before:right-0 before:left-0 before:max-w-360 before:opacity-23 
                          md:after:border-b after:border-solid after:border-tech-blue after:absolute after:bottom-[0px] after:w-full after:bg-cover after:bg-no-repeat after:bg-center after:right-0 after:left-0 after:m-auto after:max-w-360 after:opacity-23 after:border-box ">
                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={swift} alt='swift' />
                                </figure>
                                <p>Swift</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={objC} alt='swift' />
                                </figure>
                                <p>objC</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={kotlin} alt='swift' />
                                </figure>
                                <p>Kotlin</p>
                            </div>
                        </div>

                        <div className="flex items-center justify-start gap-2 text-center relative md:py-4 sm:py-2 mb-0 md:before:border-t before:border-solid before:absolute before:top-0 before:w-full before:right-0 before:left-0  before:max-w-360 before:opacity-23 
                        md:after:border-b after:border-solid after:absolute after:bottom-[0px] after:w-full after:right-0 after:left-0 after:m-auto after:max-w-360 after:opacity-23 after:border-box ">
                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={flutter} alt='swift' />
                                </figure>
                                <p>Flutter</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={native} alt='swift' />
                                </figure>
                                <p>React Native</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={node} alt='swift' />
                                </figure>
                                <p>Node JS</p>
                            </div>
                        </div>
                    </li>

                    <li className='flex items-center justify-around sm:flex-col md:flex-row  w-[100%]'>
                        <div className="flex items-center justify-start gap-2 text-center relative md:py-4 sm:py-1 mb-0  md:before:border-t before:border-solid before:border-tech-blue before:absolute before:top-0 before:w-full before:right-0 before:left-0 before:max-w-360 before:opacity-23 
                      md:after:border-b after:border-solid after:absolute after:bottom-[0px] after:w-full after:right-0 after:left-0 after:max-w-360 after:opacity-23 after:border-box ">
                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={react} alt='swift' />
                                </figure>
                                <p>React JS</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={angular} alt='swift' />
                                </figure>
                                <p>Angular</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={vue} alt='swift' />
                                </figure>
                                <p>Vue JS</p>
                            </div>
                        </div>
                    </li>

                    <li className='flex items-center justify-between sm:flex-col md:flex-row w-full'>
                        <div className="flex items-center justify-start gap-2 text-center relative md:py-4 sm:py-2 mb-0  md:before:border-t before:border-solid before:absolute before:top-0 before:w-full before:right-0 before:left-0 before:max-w-360 before:opacity-23 
                      md:after:border-b after:border-solid after:absolute after:bottom-[0px] after:w-full after:right-0 after:left-0 after:max-w-360 after:opacity-23 after:border-box ">
                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={net} alt='swift' />
                                </figure>
                                <p>.Net</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={typeScript} alt='swift' />
                                </figure>
                                <p>Type Script</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={node} alt='swift' />
                                </figure>
                                <p>Node JS</p>
                            </div>
                        </div>

                        <div className="flex items-center justify-start gap-2 text-center relative md:py-4 sm:py-2 mb-0 md:before:border-t before:border-solid before:border-tech-blue before:absolute before:top-0 before:w-full before:right-0 before:left-0 before:max-w-360 before:opacity-23 
                          md:after:border-b after:border-solid after:absolute after:bottom-[10px] after:w-full after:right-0 after:left-0 after:max-w-360 after:opacity-23 after:border-box ">
                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={python} alt='swift' />
                                </figure>
                                <p>Python</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={java} alt='swift' />
                                </figure>
                                <p>Java</p>
                            </div>

                            <div className="flex flex-column items-center justify-start gap-2 text-center relative hover:scale-105 custom-transition">
                                <figure className='border-1 border-gray-300 p-3 rounded-xl hover:shadow-[2px_3px_10px_#00000029]'>
                                    <img className='md:w-[65px] sm:w-[170px]' src={php} alt='swift' />
                                </figure>
                                <p>Php</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default TechnologyStack;
