import React, { useState } from 'react';
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import EnterpriseBgImage from 'assets/png/EnterpriseSoft/entp-hero-bg.jpg'
import FirmWithCustomerImage from 'assets/png/EnterpriseSoft/entr_bnr.png'
import compImage from 'assets/png/EnterpriseSoft/compr_sec.svg'
import figCapImage1 from 'assets/png/EnterpriseSoft/figCap1.svg'
import figCapImage2 from 'assets/png/EnterpriseSoft/figCap2.svg'
import figCapImage3 from 'assets/png/EnterpriseSoft/figCap3.svg'
import figCapImage4 from 'assets/png/EnterpriseSoft/figCap4.svg'
import figCapImage5 from 'assets/png/EnterpriseSoft/figCap5.svg'
import figCapImage6 from 'assets/png/EnterpriseSoft/figCap6.svg'
import figCapImage7 from 'assets/png/EnterpriseSoft/figCap7.svg'
import figCapImage8 from 'assets/png/EnterpriseSoft/figCap8.svg'
import figCapImage9 from 'assets/png/EnterpriseSoft/figcap9.svg'
import cardBgImage from 'assets/png/EnterpriseSoft/imp_cta1.webp'
import productLifeCycle from 'assets/png/EnterpriseSoft/plm1.svg'
import productDataBackup from 'assets/png/EnterpriseSoft/plm2.svg'
import productQAImage from 'assets/png/EnterpriseSoft/enterprise-icon-1.svg'
import productDataImage from 'assets/png/EnterpriseSoft/enterprise-icon-2.svg'
import productQAITImage from 'assets/png/EnterpriseSoft/enterprise-icon-3.svg'
import productDataBackupImage from 'assets/png/EnterpriseSoft/enterprise-icon-4.svg'
import productSLAImage from 'assets/png/EnterpriseSoft/enterprise-icon-5.svg'
import productInfrastructureImage from 'assets/png/EnterpriseSoft/enterprise-icon-6.svg'
import enterpriseCardImage1 from 'assets/png/EnterpriseSoft/enterprise-icon-10.svg'
import enterpriseCardImage11 from 'assets/png/EnterpriseSoft/enterprise-icon-11.svg'
import enterpriseCardImage12 from 'assets/png/EnterpriseSoft/enterprise-icon-12.svg'
import enterpriseCardImage13 from 'assets/png/EnterpriseSoft/enterprise-icon-13.svg'
import enterpriseCardImage14 from 'assets/png/EnterpriseSoft/enterprise-icon-14.svg'
import ChooseUsCards from 'component/ChooseUsCards';
import RecentAchivements from 'component/RecentAchivements';
import FrequentlyQuestions from 'component/FrequentlyQuestions';
import ContactForm from 'component/ContactForm';

const EnterpriseSoftwareDevelopment = () => {
    const [EngagementModelOpen, setEngagementModelOpen] = useState(false);
    return (
        <div className='relative'>
            <div className='bg-center bg-no-repeat bg-cover h-full w-full' style={{ backgroundImage: `URL(${EnterpriseBgImage})` }}>
                <div className='container mx-auto py-16'>
                    <div className='flex md:flex-row sm:flex-col items-center md:justify-center sm:justify-between'>
                        <div className='md:w-2/3 flex flex-col  gap-y-5'>
                            <h1 className='md:text-5xl tablet:text-3xl sm:text-2xl font-bold text-white' style={{ lineHeight: '1.3' }}>Flourish Your <span className='text-secondaryText'>Firm with Custom Enterprise Software</span> Development</h1>
                            <p className='md:text-xl text-white'>We excel in building one-of-a-kind enterprise software solutions with future-
                                ready tech suites to pave the way for your smooth digital transformation.</p>
                            <div>
                                <button className='btn bg-gradiBg' onClick={() => setEngagementModelOpen(true)}>Book a Free Consultation <EastSharpIcon />
                                </button>
                            </div>
                        </div>
                        <div className='md:w-1/2 sm:mt-16 md:mt-0'>
                            <figure className='flex items-center md:justify-end md:justify-center animate-custom-bounce'>
                                <img className='md:h-[350px] tablet:h-[320px] sm:h-[250px]' src={FirmWithCustomerImage} alt="Enterprise Bg Mobile Image" />
                            </figure>
                        </div>
                    </div>
                    {/* Comprehensive services */}
                    <div className='md:my-16 mx-auto'>
                        <div className='text-white text-center space-y-5'>
                            <h1 className='md:text-5xl tablet:text-3xl sm:text-2xl font-bold '>Comprehensive Enterprise Software Development Services for your Business Needs</h1>
                            <p className='md:text-xl sm:text-base'>Experience the next-generation enterprise software solutions with us and achieve the ultimate business hike.</p>
                        </div>
                        <div className='flex items-start sm:flex-col md:flex-row w-full py-5 relative'>
                            <div className='sm:hidden md:block md:w-[50%] py-4 md:sticky top-[100px] bottom-0'>
                                <figure className='animate-custom-bounce h-full w-full'>
                                    <img className='w-[430px]' src={compImage} alt="Comprehensive Image" />
                                </figure>
                            </div>
                            <div className='md:w-[50%] grid grid-cols-1 gap-y-5'>
                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage1} alt="CRM" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>CRM Solution</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Take the realm of your sales high and streamline the entire process with CRM solutions. Get an optimized answer for all the relationship</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage2} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>ERP Solutions</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Our robust ERP solution maintains your business efficiency, workforce management, workflow, financial data, and other details.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage3} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Pol & GPS Tracking</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>We integrate advanced Pol & GPS Tracking and ensure your business proficiency giving you an analytical eye & the ultimate authority to control your way.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage4} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Real-Time Data Processing</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>With Real-Time Data Processing and smart analytics, avail the maximum Concurrency, Correctness, Safety, Stability & proper Tracking altogether.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage5} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Human Resource Management</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Our enterprise software developers build such software that gives your HR the ultimate key to managing the employee’s lifecycle & streamline your business pragmatically.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage6} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Service Planning & Scheduling</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>With Integral Service Planning, Scheduling features, Program Evaluation and Review Techniques achieve the vision of your project.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage7} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Payment Processing Solutions</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Accelerate your ROI with POS solutions that make paying easy and fast. Add multiple payment options to suit your business requirements.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage8} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>AI & IoT Connectivity Solutions</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Bring the power of AI to your business and save the time/effort of your sales team helping them focus on clients & less on initial proceedings.</p>
                                </div>

                                <div className='p-3 rounded-2xl' style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <div className='text-white'>
                                        <figure className='flex items-center gap-3'>
                                            <img src={figCapImage9} alt="ERP" />
                                            <figcaption>
                                                <h1 className='text-2xl font-semibold'>Enterprise Solutions</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <p className='text-white md:text-lg tablet:text-base indent-20'>Get incredible enterprise custom software development solutions to cover your entire business, products, and services efficiently. We have solutions for all business sizes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card */}
                    <div className='h-[300px] bg-center bg-no-repeat overflow-hidden rounded-3xl' style={{ backgroundImage: `URL(${cardBgImage})` }}>
                        <div className='flex justify-between sm:flex-col md:flex-row items-center p-4 h-full'>
                            <div className='flex flex-col gap-y-5'>
                                <h1 className='md:text-5xl tablet:text-3xl sm:text-2xl font-bold text-white'>Don’t Let Readymade Solutions <span className='md:block'>Limit Your Vision</span></h1>
                                <p className='tablet:text-xl  text-white'>Be in the limelight with top-notch customized solutions and
                                    <span className='md:block'>satisfy all your business needs, your way!</span> </p>
                            </div>
                            <div>
                                <button className='btn bg-whiteBg text-dark hover:bg-primaryBg'>Explore More &nbsp; <EastSharpIcon /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-whiteBg'>
                <div className='container mx-auto py-16'>
                    <div className='text-center space-y-5'>
                        <h1 className='md:text-5xl tablet:text-3xl sm:text-2xl font-bold'>Product Lifecycle Management (PLM)</h1>
                        <p className='text-dark tablet:text-xl'>Drive higher business efficiency with advanced enterprise software development solutions.</p>
                    </div>
                    <div className='flex md:flex-row sm:flex-col items-center justify-stretch gap-5 my-16'>
                        <div className='md:w-1/2'>
                            <figure className='animate-custom-bounce'>
                                <img src={productLifeCycle} alt="product Life Cycle management" />
                            </figure>
                        </div>
                        <div className='md:w-1/2 grid md:grid-cols-1 gap-y-5'>
                            <div className='flex gap-x-3 justify-arround border-t-[1px] border-r-[1px] border-danger'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productQAImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>Quality Assurance (QA) Testing</h1>
                                    <p className='text-dark'>Comprehensive quality assurance (QA) testing enterprise software development services to deliver robust aligned software that adds high value to your business.</p>
                                </div>
                            </div>

                            <div className='flex gap-x-3 justify-arround'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productDataImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>Data Migrations & Upgrades</h1>
                                    <p className='text-dark'>Our enterprise software development company focuses on cloud-based data migrations, system upgrades, and significant modernization services to maintain data integrity.</p>
                                </div>
                            </div>

                            <div className='flex gap-x-3 justify-arround'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productQAITImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>QIT Security Services</h1>
                                    <p className='text-dark'>Our threat audits analyse the most critical vulnerabilities of an enterprise and implement the encryptions you require.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Second Half Part */}
                    <div className='flex md:flex-row flex-col items-center justify-stretch gap-5 md:my-16'>
                        <div className='md:w-1/2 grid md:grid-cols-1 gap-y-5'>
                            <div className='flex gap-x-3 justify-arround'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productDataBackupImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>Data Backup</h1>
                                    <p className='text-dark'>Robust data backup and recovery strategies for cloud-based and hybrid servers are designed to ensure business continuity.</p>
                                </div>
                            </div>

                            <div className='flex gap-x-3 justify-arround'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productSLAImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>SLA Support Services</h1>
                                    <p className='text-dark'>We ensure contracted Service Level Agreements (SLA), guaranteeing optimal monitoring, reporting, and management simultaneously.</p>
                                </div>
                            </div>

                            <div className='flex gap-x-3 justify-arround'>
                                <div className='w-[120px] mt-3'>
                                    <img src={productInfrastructureImage} alt="productQAImage" />
                                </div>
                                <div>
                                    <h1 className='tablet:text-3xl sm:text-2xl font-bold'>Infrastructure Support Services</h1>
                                    <p className='text-dark'>Our enterprise software development services back your system with IT infrastructure support, collaboration, desk & network management, and system administration services.</p>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-1/2'>
                            <figure className='animate-custom-bounce'>
                                <img src={productDataBackup} alt="product Life Cycle management" />
                            </figure>
                        </div>
                    </div>
                    {/* Smart Applications */}
                    <div className='md:py-5'>
                        <div className='text-center space-y-7'>
                            <h1 className='md:text-5xl tablet:text-4xl sm:text-2xl font-bold text-black'>Develop AI-Powered Smart Applications For Your Enterprise</h1>
                            <p className='text-text tablet:text-xl'>We integrate embedded and AI-powered enterprise software solutions to help you develop an application with industrial automation mechanisms (IIoT).</p>
                        </div>
                        {/* Cards */}
                        <div className='flex flex-wrap items-stretch  justify-center gap-4 mt-5'>

                            <div className='md:w-[30%] h-full tablet:w-[45%] hover:-mt-3 custom-transition'>
                                <div className='bg-[#F5F5F5] p-3 flex flex-col items-center justify-center gap-y-3 text-center border-1 border-secondaryText rounded-2xl'>
                                    <figure>
                                        <img className='object-cover max-h-[80px]' src={enterpriseCardImage1} alt="enterpriseCardImage1" />
                                    </figure>
                                    <h1 className='text-black text-xl font-bold'>Streamline Workflow</h1>
                                    <p className='text-text'>Tackle the business challenges with AI-powered processes and achieve a streamlined workflow to get enhanced performance and productivity.</p>
                                </div>
                            </div>
                            <div className='md:w-[30%] tablet:w-[45%] hover:-mt-3 custom-transition'>
                                <div className='bg-[#F0FFFF] p-3 flex flex-col items-center justify-center gap-y-3 text-center border-1 border-secondaryText rounded-2xl'>
                                    <figure>
                                        <img className='object-cover max-h-[80px]' src={enterpriseCardImage11} alt="enterpriseCardImage2" />
                                    </figure>
                                    <h1 className='text-black text-xl font-bold'>Agile methodology</h1>
                                    <p className='text-text'>We follow the agile methodology for clients and developers that soothes the enterprise software development process management on both sides.</p>
                                </div>
                            </div>
                            <div className='md:w-[30%] tablet:w-[45%] hover:-mt-3 custom-transition'>
                                <div className='bg-[#F0FFF0] p-3 flex flex-col items-center justify-center gap-y-3 text-center border-1 border-secondaryText rounded-2xl'>
                                    <figure>
                                        <img className='object-cover max-h-[80px]' src={enterpriseCardImage12} alt="enterpriseCardImage1" />
                                    </figure>
                                    <h1 className='text-black text-xl font-bold'>Scalable Implementation</h1>
                                    <p className='text-text'>Our enterprise software developers are experts in building scalable products and accelerating your growth for enhanced transformation.</p>
                                </div>
                            </div>

                            <div className='md:w-[30%] tablet:w-[45%] hover:-mt-3 custom-transition'>
                                <div className='bg-[#FFFFF0] p-3 flex flex-col items-center justify-center gap-y-3 text-center border-1 border-secondaryText rounded-2xl'>
                                    <figure>
                                        <img className='object-cover max-h-[80px]' src={enterpriseCardImage13} alt="enterpriseCardImage1" />
                                    </figure>
                                    <h1 className='text-black text-xl font-bold'>Robust architecture</h1>
                                    <p className='text-text'>With our unstinted technology expertise and teamwork, we create compelling software that fits any business environment for a longer time.</p>
                                </div>
                            </div>

                            <div className='md:w-[30%] tablet:w-[45%] hover:-mt-3 custom-transition'>
                                <div className='bg-[#F8F6F0] p-3 flex flex-col items-center justify-center gap-y-3 text-center border-1 border-secondaryText rounded-2xl'>
                                    <figure>
                                        <img className='object-cover max-h-[70px]' src={enterpriseCardImage14} alt="enterpriseCardImage1" />
                                    </figure>
                                    <h1 className='text-black text-xl font-bold'>100% Efficiency</h1>
                                    <p className='text-text'>Our enterprise software development company holds expertise and excellence together as a catalyst to achieve your goals faster and smarter with our workmanship and competency.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* SCube Cards */}
            <ChooseUsCards />
            {/* Recent Achivement */}
            <RecentAchivements/>
            {/* Frequently Asked Questions */}
            <FrequentlyQuestions/>
            {/* Contact Form */}
            <ContactForm/>
            {EngagementModelOpen && (
                <EngagementModel onClose={() => setEngagementModelOpen()} />
            )}
        </div>
    );
}

export default EnterpriseSoftwareDevelopment;
