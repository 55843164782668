import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="white"
      className={`${id === open ? "rotate-180" : ""} md:h-10 md:w-10 p-2 sm:h-7 sm:w-7 rounded-full transition-transform bg-gradiBg `}>
      <path strokeLinecap="round " strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const FrequentlyQuestions = () => {
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <div data-aos="fade-up" className="container flex flex-col items-center justify-between gap-4 md:py-[60px] sm:py-[20px]">
        <span  className="md:text-4xl sm:text-xl font-bold md:mb-4 sm:mb-2">Top Frequently Asked Questions</span>
        <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(1)}>How much does it cost for a mobile app developed
            by a top mobile app development company in Pakistan?</AccordionHeader>
          <AccordionBody className="text-text md:text-lg sm:text-sm">
            The cost to develop a basic mobile app in Pakistan can range between 80k AED to 1100k AED. SCube as a premier mobile app development company in Pakistan will be able to define the actual development cost based on the set of required features and the nature of the app (hybrid or native).
          </AccordionBody>
        </Accordion>

        <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(2)}>
            How much time does it take to build an app in Pakistan?
          </AccordionHeader>
          <AccordionBody className="text-text text-lg">
            When it comes to mobile app development in Pakistan, the time varies as per the functionality and complexity of the project. But on average, the midsize app project takes anywhere around 2-4 months.
          </AccordionBody>
        </Accordion>

        <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(3)}>
            How to launch an app in Pakistan?
          </AccordionHeader>
          <AccordionBody className="text-text text-lg">
            It’s simple! Just hire SCube as your trusted Mobile App Development Company in Pakistan, and watch your app launching on both Google Play and Apple App Store.
          </AccordionBody>
        </Accordion>

        <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(4)}>
            Do you offer mobile app support services in Pakistan?
          </AccordionHeader>
          <AccordionBody className="text-text text-lg ">
            As the best mobile app development company in Pakistan, we not only offer you the advantage of support services but can also help you modify or update your existing app per your specific business requirement and as per the market need.
          </AccordionBody>
        </Accordion>
      </div>
    </>
  );
}
export default FrequentlyQuestions;
