import React, { useState } from 'react'
import Blockimg from 'assets/png/block.png'
import Discovery from 'assets/png/discoverey.png'
import Discovery1 from 'assets/png/div_icn.svg'
import AgileDevelopment from 'assets/png/Agile-Development.png'
import TechnicalSupport from 'assets/png/Technical suppport.png'
import OntimeDelivery from 'assets/png/On-Time-Delivery.png'
import OntimeDelivery2 from 'assets/png/On-Time-Delivery2.svg'
import Qualityfirst from 'assets/png/Quality-First-Approach.png'
import Qualityfirst2 from 'assets/png/Quality-First-Approach2.png'
import Communication from 'assets/png/Easy-Communication.png'
import Communication2 from 'assets/png/Easy-Communication2.svg'
import Technicalsupport2 from 'assets/png/247-Technical-Support.png'

export default function AppDevelopmentMethodology() {
    const [activeItem, setActiveItem] = useState(1)
    const handleMouseEnter = (index) => {
        if (activeItem !== index) {
            setActiveItem(index)
        }
    }
    return (
        <>
            <div className='container py-10'>
                <div className=' flex flex-row w-full'>
                    <div className='flex flex-row w-[40%]'>
                        <div className="flex pt-3">
                            <span className="border-r-[2px] h-[93%] border-black border-dashed"></span>
                        </div>
                        <div className='main'>

                            <ul className='cursor-pointer relative -left-[5px] text-[26px] font-black'>
                                {/* 1 */}
                                <div className='flex flex-row items-center'>
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                        <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`} >
                                        <span className={`${activeItem === 1 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(1)}>
                                            Data Protection
                                        </span>
                                    </li>
                                </div>

                                {/* 2 */}
                                <div className='flex flex-row items-center pt-10'>
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                        <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`}>
                                        <span className={`${activeItem === 2 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(2)}>
                                            Agile Development
                                        </span>
                                    </li>
                                </div>
                                {/* 3 */}
                                <div className='flex flex-row items-center pt-10'>
                                <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`}>
                                        <span className={`${activeItem === 3 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(3)}>
                                            On-Time Delivery
                                        </span>
                                    </li>
                                </div>
                                {/* 4 */}
                                <div className='flex flex-row items-center pt-10'>
                                <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`}>
                                        <span className={`${activeItem === 4 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(4)}>
                                            Quality First Approach
                                        </span>
                                    </li>
                                </div>
                                {/* 5 */}
                                <div className='flex flex-row items-center pt-10'>
                                <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`}>
                                        <span className={`${activeItem === 5 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(5)}>
                                            Easy Communication
                                        </span>
                                    </li>
                                </div>
                                {/* 6 */}
                                <div className='flex flex-row items-center pt-10'>
                                <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full inline-flex h-3 w-3 rounded-full bg-[#00649E]">
                                    <span className="after:animate-ping after:bg-[#01A1FE] after:h-3 after:w-3 after:rounded-full" />
                                    </span>
                                    <li className={`flex flex-row items-center ps-4`}>
                                        <span className={`${activeItem === 6 ? 'text-[#00649e]' : ''}`} onMouseEnter={() => handleMouseEnter(6)}>
                                            24/7 Technical Support
                                        </span>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                    {/* details  */}
                    <div className='w-[60%]'>
                        {/* 01 */}
                        <div id='text-1' className={`flex flex-column ${activeItem === 1 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>01</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={Discovery} alt="Discovery" />
                                <div className='flex flex-column ps-4'>
                                    <img src={Discovery1} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            Data Protection
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            We serve our clients even after deploying the app. You can connect with us anytime and our experts will reach out to you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 02 */}
                        <div id='text-2' className={`flex flex-column ${activeItem === 2 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>02</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={AgileDevelopment} alt="Technical-Support" />
                                <div className='flex flex-column ps-4'>
                                    <img src={TechnicalSupport} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            Agile Development
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            We use AGILE methodologies to complete the projects in time using fewer resources and offering cost-effective solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 03 */}
                        <div id='text-3' className={`flex flex-column ${activeItem === 3 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>03</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={OntimeDelivery} alt="Technical-Support" />
                                <div className='flex flex-column ps-4'>
                                    <img src={OntimeDelivery2} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            On-Time Delivery
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            We have employed approaches to cut down on the time required for app development. We use Flutter and React Native tools to achieve this goal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 04 */}
                        <div id='text-4' className={`flex flex-column ${activeItem === 4 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>04</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={Qualityfirst} alt="Technical-Support" />
                                <div className='flex flex-column ps-4'>
                                    <img src={Qualityfirst2} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            Quality First Approach
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            It goes without saying, we choose quality over everything. No app leaves our premises without being thoroughly tested for 100% assurance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 05 */}
                        <div id='text-5' className={`flex flex-column ${activeItem === 5 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>05</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={Communication} alt="Technical-Support" />
                                <div className='flex flex-column ps-4'>
                                    <img src={Communication2} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            Easy Communication
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            We foster transparency and open communication with clients throughout the process to modify our mobile app development services accordingly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 06 */}
                        <div id='text-6' className={`flex flex-column ${activeItem === 6 ? '' : 'hidden'}`}>
                            <div className='flex flex-row justify-between'>
                                <h2 className='font-bold text-[160px] text-[#EEEEEE]'>06</h2>
                                <span>
                                    <img src={Blockimg} alt="Blockimg" />
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <img className='w-[40%]' src={Technicalsupport2} alt="Technical-Support" />
                                <div className='flex flex-column ps-4'>
                                    <img src={OntimeDelivery2} alt="Discovery1" className='w-20' />
                                    <div>
                                        <h3 className='font-bold text-[28px]'>
                                            24/7 Technical Support
                                        </h3>
                                        <p className='text-xl font-normal leading-8'>
                                            We serve our clients even after deploying the app. You can connect with us anytime and our experts will reach out to you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
