import React from 'react';
import spinner3 from 'assets/Images/spinner3.gif'
import { useContactFormContext } from 'service/ContactFormContext';
const ContactFormDashboard = () => {
    const {
        name,
        setName,
        email,
        setEmail,
        phone,
        setPhone,
        subject,
        setSubject,
        description,
        setDescription,
        error,
        loading,
        handleSubmit,
    } = useContactFormContext();
    return (
        <div>
            <div className='backdrop-filter backdrop-blur-3xl bg-brightness-200 h-auto rounded-[2rem] px-4 py-[15px]' data-aos="zoom-in">
                <h1 className='md:text-[47px] sm:text-[30px] sm:text-center md:text-left text-dark font-bold md:pb-[25px] sm:pb-5'>Request a Quote</h1>
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 text-black'>
                    <input className='p-[12px] rounded' type='text' name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' />
                    {error.name && <h1 className='text-danger text-xs'>{error.name}</h1>}

                    <input className='p-[12px] rounded' type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                    {error.email && <h1 className='text-danger text-xs'>{error.email}</h1>}

                    <input className='p-[12px] rounded appearance-none' type='phone_no' value={phone} onChange={(e) => setPhone(e.target.value)} name='number' placeholder='Mobile Number' />
                    {error.phone && <h1 className='text-danger text-xs'>{error.phone}</h1>}

                    <input className='p-[12px] rounded appearance-none' type='text' name='subject' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject' />
                    {error.subject && <h1 className='text-danger text-xs '>{error.subject}</h1>}

                    <textarea className='p-[12px] rounded' rows={1} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='What`s your Project About' />
                    {error.description && <h1 className='text-danger text-xs'>{error.description}</h1>}

                    <div>
                        <button type='submit' className='btn w-full md:mt-6 sm:mt-4 bg-gradiBg rounded'>
                            {
                                loading ?
                                    <span className='flex items-center justify-center gap-1 p-0 m-0'>
                                        <span>Submitting</span>
                                        <img className='h-7 w-10' src={spinner3} alt="Loading...." />
                                    </span>
                                    :
                                    <span>Get in touch</span>
                            }
                        </button>

                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactFormDashboard;
