import React from 'react'
// import Client1 from '../../assets/png/client_logo_1.png'
import Client1 from 'assets/png/client_logo_1.png'
import Client2 from 'assets/png/client_logo_2.png'
import Client3 from 'assets/png/client_logo_3.png'
import Client4 from 'assets/png/client_logo_4.png'
import Client5 from 'assets/png/client_logo_5.png'
import Client6 from 'assets/png/client_logo_6.png'
import Client7 from 'assets/png/client_logo_7.png'
import Client8 from 'assets/png/client_logo_8.png'
import Client9 from 'assets/png/client_logo_9.png'
import Client10 from 'assets/png/client_logo_10.png'
import Client11 from 'assets/png/client_logo_11.png'
import Client12 from 'assets/png/client_logo_12.png'
import Client13 from 'assets/png/client_logo_13.png'
import Client14 from 'assets/png/client_logo_14.png'
import Client15 from 'assets/png/client_logo_15.png'
import Client16 from 'assets/png/client_logo_16.png'
import Client17 from 'assets/png/client_logo_17.png'
import Client18 from 'assets/png/client_logo_18.png'
import Client19 from 'assets/png/client_logo_19.png'
import Client20 from 'assets/png/client_logo_20.png'
import Client21 from 'assets/png/client_logo_21.png'
import Client22 from 'assets/png/client_logo_22.png'
import Client23 from 'assets/png/client_logo_23.png'
import Client24 from 'assets/png/client_logo_24.png'
import Client25 from 'assets/png/client_logo_25.png'
import Client26 from 'assets/png/client_logo_26.png'
import Client27 from 'assets/png/client_logo_27.png'
import Client28 from 'assets/png/client_logo_28.png'
import Client29 from 'assets/png/client_logo_29.png'
import Client30 from 'assets/png/client_logo_30.png'
import Client31 from 'assets/png/client_logo_31.png'
import Client32 from 'assets/png/client_logo_32.png'
import Client33 from 'assets/png/client_logo_33.png'
import Client34 from 'assets/png/client_logo_34.png'
import Client35 from 'assets/png/client_logo_35.png'
import Client36 from 'assets/png/client_logo_36.png'

export default function OurPartner() {
    return (
        <>
            <div className='container'>
                <ul className='flex flex-column'>
                    {/* row 1 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2'>
                            <figure>
                                <img src={Client1} alt="Client1" />
                            </figure>
                        </li>
                        <li className='border-t-0 border-l-2'>
                            <figure>
                                <img src={Client2} alt="Client2" />
                            </figure>
                        </li>
                        <li className='border-t-0 border-l-2'>
                            <figure>
                                <img src={Client3} alt="Client3" />
                            </figure>
                        </li>
                        <li className='border-t-0 border-l-2'>
                            <figure>
                                <img src={Client4} alt="Client4" />
                            </figure>
                        </li>
                        <li className='border-t-0 border-l-2'>
                            <figure>
                                <img src={Client5} alt="Client5" />
                            </figure>
                        </li>
                        <li className='border-t-0 border-l-2 border-r-2'>
                            <figure>
                                <img src={Client6} alt="Client6" />
                            </figure>
                        </li>
                    </div>
                    {/* row 2 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2'>
                            <figure>
                                <img src={Client7} alt="Client7" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-t-2 border-x-2'>
                            <figure>
                                <img src={Client8} alt="Client8" />
                            </figure>
                        </li>
                        <li className='border-t-2 border-b-2'>
                            <figure>
                                <img src={Client9} alt="Client9" />
                            </figure>
                        </li>
                        <li className='border-l-2 border-t-2 border-b-2'>
                            <figure>
                                <img src={Client10} alt="Client10" />
                            </figure>
                        </li>
                        <li className='border-2'>
                            <figure>
                                <img src={Client11} alt="Client11" />
                            </figure>
                        </li>
                        <li className='border-l-0 border-t-2 border-b-2 border-r-2'>
                            <figure>
                                <img src={Client12} alt="Client12" />
                            </figure>
                        </li>
                    </div>
                    {/* row 3 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2'>
                            <figure>
                                <img src={Client13} alt="Client13" />
                            </figure>
                        </li>
                        <li className='border-l-2 border-b-2 border-r-2'>
                            <figure>
                                <img src={Client14} alt="Client14" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client15} alt="Client15" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client16} alt="Client16" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client17} alt="Client17" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client18} alt="Client18" />
                            </figure>
                        </li>
                    </div>
                    {/* row 4 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2 border-r-2'>
                            <figure>
                                <img src={Client19} alt="Client18" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client20} alt="Client20" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client21} alt="Client21" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client22} alt="Client22" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client23} alt="Client23" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client24} alt="Client24" />
                            </figure>
                        </li>
                    </div>
                    {/* row 5 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2 border-r-2'>
                            <figure>
                                <img src={Client25} alt="Client25" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client26} alt="Client26" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client27} alt="Client27" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client28} alt="Client28" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client29} alt="Client29" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client30} alt="Client30" />
                            </figure>
                        </li>
                    </div>
                    {/* row 6 */}
                    <div className='flex flex-row'>
                        <li className='border-l-0 border-b-2 border-r-2'>
                            <figure>
                                <img src={Client31} alt="Client31" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client32} alt="Client32" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client33} alt="Client33" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client34} alt="Client34" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client35} alt="Client35" />
                            </figure>
                        </li>
                        <li className='border-b-2 border-r-2'>
                            <figure>
                                <img src={Client36} alt="Client36" />
                            </figure>
                        </li>
                    </div>
                </ul>
            </div>
        </>
    )
}
