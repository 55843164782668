import React from 'react';
import ContactForm from '../component/ContactForm';

const TermsAndConditions = () => {
    return (
        <div className='container'>
            <h1 className='text-black md:text-5xl sm:text-3xl font-bold text-center mb-3 pt-4'>Terms and Conditions</h1>
            <div className='md:text-xl text-text flex flex-col space-y-5 pb-4'>
                <p>The maintenance charges are for maintenance of white-labeled application hosted on our servers including periodic updates.</p>
                <p>Whitelabeling the platform does not include configuration of the platform. SCube would guide the client and would share all possible training and training material for the client to configure the platform but SCube would not be responsible to configure the platform, the client would have to complete the configuration himself.</p>
                <p>No response situation: In case of no response from the Client’s side for more than 7 days despite reminders from the Company’s side, Company may put the development on hold. In case of no response from the Client’s side for more than 14 days despite reminders from the Company’s side, the Company may terminate the contract.</p>
                <p>Payment terms:<span className='block'>The kick-off amount is non-refundable.</span></p>
                <p>18% GST is included on above mentioned cost (in case of Indian clients)</p>
                <p>Renewal in case of plan expiry:<span className='block'>Post plan expiry, standard cost will be applicable as per the choice made by the client among the then available plans.</span></p>
                <h3><span className='block text-black text-3xl font-bold'>Timeline:</span>The timeline to deliver the solution is 10-12 working days.</h3>
                <p>Please note: The timeline will be applicable from the date of project kick off and once all the requirements are shared by the client.</p>
            </div>
            <ContactForm />
        </div >
    );
}

export default TermsAndConditions;
