import React, { useState } from 'react';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import SmartHomeImage from '../assets/Images/iot-application-dev04.png'
import SmartHomeLogo from '../assets/Images/iot-application-dev03.png'
import smartHomeMobileLogo1 from '../assets/Images/iot-application-dev06.png'
import smartHomeMobileLogo2 from '../assets/Images/iot-application-dev07.png'
import smartHomeMobileLogo3 from '../assets/Images/iot-application-dev08.png'
import smartHomeMobileLogo4 from '../assets/Images/iot-application-dev09.png'
import smartHomeMobileLogo5 from '../assets/Images/iot-application-dev10.png'

import SmartHomeDashboardImage1 from '../assets/Images/iot-application-dev12.png'
import SmartHomeDashboardLogo from '../assets/Images/iot-application-dev13.png'
import SmartHomeDashboardIcon from '../assets/Images/iot-application-dev14.png'
import SmartHomeConnectedDevices from '../assets/Images/iot-application-dev18.png'
import SmartHomeConnectedDevicesLogo from '../assets/Images/iot-application-dev17.png'
import SmartHomeConnectedDevicesIcon from '../assets/Images/iot-application-dev16.png'
import SmartHomeControlEquipmentImage from '../assets/Images/iot-application-dev22.png'
import SmartHomeControlEquipmentLogo from '../assets/Images/iot-application-dev21.png'
import SmartHomeControlEquipmentIcon from '../assets/Images/iot-application-dev20.png'
import SmartHomeControlDesignedByMobileImage1 from '../assets/Images/iot-application-dev24.png'
import SmartHomeControlDesignedByMobileImage2 from '../assets/Images/iot-application-dev25.png'
import numberStackWeb from '../assets/Images/iot-application-dev26.png'
import numberStackMobile from '../assets/Images/iot-application-dev27.png'
import numberStackTeam from '../assets/Images/iot-application-dev28.png'
import numberStackProject from '../assets/Images/iot-application-dev29.png'
import RecentAchivements from '../component/RecentAchivements';
import ContactForm from '../component/ContactForm';
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';

const IotAppDevelopmentFooter = () => {
    const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);

    return (
        <>
            <div className='bg-iotDevelopmentFooter bg-cover bg-center bg-no-repeat md:min-h-[500px] sm:min-h-[400px] w-full flex items-center justify-center bg-blend-multiply bg-neutral-300'>
                <div className='text-white flex flex-col items-center gap-y-5 text-center'>
                    <h1 className='md:text-5xl tablet:text-4xl sm:text-3xl font-bold'>Top Notch IoT App Development Company</h1>
                    <p className='text-xl'>Welcome to the world of Smart Solutions & Perfect Connectivity!</p>
                    <div className='mt-5'>
                        <button className='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>
                            Get a Quote &nbsp;<span><EastSharpIcon/></span>
                        </button>
                    </div>
                </div>
            </div>

            <div className='bg-greyMudBg'>
                <div className='container mx-auto '>
                    <div className='text-center md:py-[70px] sm:py-10 space-y-8'>
                        <h1 className='md:text-5xl sm:text-2xl font-bold'>IoT App Development – Bringing the power of internet to real world</h1>
                        <p className='text-text md:text-xl '>The era of IoT is advancing at a rapidly swift pace. It’s changing the way we interact with our devices and get things done. SCube is a highly specialized IoT application development company that stands at the forefront of this IoT revolution. Our hands on experience in designing and developing myriad of mobile apps to keep you connected with your home and business appliances is simply unmatched. Building IoT solutions that cater to your personal/business needs is our prime concern. Our applications are guaranteed to simplify the remote monitoring and communication of your devices.</p>
                    </div>
                </div>
            </div>

            <div className='bg-whiteBg'>
                <div className='containermx-auto'>
                    <div className='md:mx-[70px]'>
                        <h1 className='text-center text-black md:text-5xl sm:text-2xl font-bold sm:py-5 md:py-10'>
                            IoT App Development delivering stunning Experience
                        </h1>

                        <div className='bg-iotDevelopmentSmartHome bg-no-repeat bg-cover bg-center'>
                            <div className='flex justify-between items-center p-[50px]'>
                                <div className='flex flex-col gap-y-3'>
                                    <figure >
                                        <img className='max-h-[70px] max-w-[80px]' src={SmartHomeLogo} alt='Smart Home' />
                                    </figure>
                                    <h3 className='text-xl font-semibold'>INTERNET OF THINGS</h3>
                                    <h1></h1>
                                    <h3>SMART HOME</h3>
                                    <span>Bringing innovation to your home</span>
                                </div>
                                <div>
                                    <figure>
                                        <img className='max-h-[450px] ' src={SmartHomeImage} alt='Internet On things' />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className='bg-iotDevelopmentSmartHome2 bg-no-repeat bg-cover bg-center w-full'>
                            <div className='flex items-center justify-between md:py-[70px] sm:py-10 md:px-[60px] sm:px-5'>
                                <figure>
                                    <img src={smartHomeMobileLogo1} alt='Smart Phone' />
                                </figure>
                                <figure>
                                    <img src={smartHomeMobileLogo2} alt='Smart Phone' />
                                </figure>
                                <figure>
                                    <img src={smartHomeMobileLogo3} alt='Smart Phone' />
                                </figure>
                                <figure>
                                    <img src={smartHomeMobileLogo4} alt='Smart Phone' />
                                </figure>
                                <figure>
                                    <img src={smartHomeMobileLogo5} alt='Smart Phone' />
                                </figure>
                            </div>
                        </div>
                        <div className='bg-iotDevelopmentSmartHomeDashboard bg-no-repeat bg-right-bottom'>
                            <div className='flex items-center gap-x-5 px-14 relative'>
                                <div>
                                    <figure className='sm:invisible md:visible'>
                                        <img className='h-[500px] w-[650px] -mt-10' src={SmartHomeDashboardImage1} alt='Phone Dashboard' />
                                    </figure>
                                    <figure>
                                        <img className='absolute top-10 left-[250px] h-[100px] ' src={SmartHomeDashboardLogo} alt='Phone Dashboard' />
                                    </figure>
                                </div>

                                <div className='relative '>
                                    <h2 className='text-3xl font-bold text-orange'>
                                        <img className='absolute top-3 -left-14' src={SmartHomeDashboardIcon} alt='Smart Home Dashboard' />
                                        DASHBOARD
                                    </h2>
                                    <p className='text-md'>Greet yourself to an interactive Dashboard screen. From here, you can set the desired temperature of any connected device with a single tap on the screen.</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-orangeBg opacity-90'>
                            <div className='bg-iotDevelopmentSmartHomeConnectDevices bg-no-repeat bg-cover bg-center'>
                                <div className='flex items-center p-5 relative w-full'>
                                    <div className='text-white relative'>
                                        <h1 className='md:text-3xl sm:text-2xl font-bold'>
                                            <span className="flex items-center">
                                                CONNECTED DEVICES
                                                <img className='ml-2' src={SmartHomeConnectedDevicesIcon} alt='DashboardIcon' />
                                            </span>
                                        </h1>
                                        <p className='text-md mt-2'>Connected Devices screen gives you an overview of all the devices you’d like to control. Use the power button to turn off/on any device with absolute ease.</p>
                                    </div>

                                    <div className='sm:hidden md:block'>
                                        <figure>
                                            <img className='w-[700px] ' src={SmartHomeConnectedDevices} alt='Connected Devices' />
                                            <img className='absolute top-1/3 z-0' src={SmartHomeConnectedDevicesLogo} alt='Connected Devices' />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-iotDevelopmentSmartHomeControlEquipment bg-no-repeat bg-contain bg-left-top'>
                            <div className='flex items-center justify-between p-5 relative'>
                                <div className='relative'>
                                    <h1 className='md:text-3xl sm:text-xl font-bold'>
                                        <span className="flex items-center text-orange">
                                            CONTROL EVERY EQUIPMENT
                                            <img className='ml-2' src={SmartHomeControlEquipmentIcon} alt='DashboardIcon' />
                                        </span>
                                    </h1>
                                    <p className='text-md mt-2'>Take a closer look at all the appliances and their energy consumption rate. Your smart energy consumption just got a whole lot easier.</p>
                                </div>

                                <div className='sm:hidden md:block'>
                                    <figure >
                                        <img className='w-[600px] ' src={SmartHomeControlEquipmentImage} alt='Connected Devices' />
                                        <img className='absolute top-1/3 z-0' src={SmartHomeControlEquipmentLogo} alt='Connected Devices' />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className='bg-iotDevelopmentSmartHomeDesignedBy bg-bottom bg-contain bg-no-repeat'>
                            <div className='bg-iotDevelopmentSmartHomeDesignedByOverlapDotes overflow-hidden flex items-center flex-col p-4'>
                                <div className='flex md:gap-5 sm:gap-3 m-5'>
                                    <figure>
                                        <img className='w-[220px]' src={SmartHomeControlDesignedByMobileImage1} alt='Designed By Sube' />
                                    </figure>
                                    <figure>
                                        <img className='w-[220px]' src={SmartHomeControlDesignedByMobileImage2} alt='Designed By Sube' />
                                    </figure>
                                </div>
                                <div className='text-center'>
                                    <h1 className='text-orange text-xl font-semibold'>Designed By:</h1>
                                    <h1 className='text-3xl text-white font-semibold'><sup>&#169;</sup>SCube Team</h1>
                                </div>
                            </div>
                        </div>

                        <div className='w-full py-[70px]'>
                            <div className='flex flex-col gap-y-10'>
                                <h1 className='md:text-5xl tablet:text-4xl sm:text-3xl font-bold text-center'>Numbers stack up to awesome</h1>
                                <div className='grid md:grid-cols-4 sm:grid-cols-2 md:gap-x-20 sm:gap-x-5'>
                                    <div className='flex flex-col items-center justify-center gap-y-1   '>
                                        <figure className='mb-2'>
                                            <img className='w-[85px]' src={numberStackWeb} alt='Web Applications' />
                                        </figure>
                                        <h1 className='text-3xl font-semibold'>200+</h1>
                                        <p className='text-text text-xl'>Web Applications</p>
                                    </div>

                                    <div className='flex flex-col items-center justify-center gap-y-1 text-center'>
                                        <figure className='mb-2'>
                                            <img className='w-[85px]' src={numberStackMobile} alt='Web Applications' />
                                        </figure>
                                        <h1 className='text-3xl font-semibold'>300+</h1>
                                        <p className='text-text text-xl'>Mobile Applications</p>
                                    </div>

                                    <div className='flex flex-col items-center justify-center gap-y-1 text-center'>
                                        <figure className='mb-2'>
                                            <img className='w-[85px]' src={numberStackTeam} alt='Web Applications' />
                                        </figure>
                                        <h1 className='text-3xl font-semibold'>250+</h1>
                                        <p className='text-text text-xl'>Experienced Team</p>
                                    </div>

                                    <div className='flex flex-col items-center justify-center gap-y-1 text-center'>
                                        <figure className='mb-2'>
                                            <img className='w-[85px]' src={numberStackProject} alt='Web Applications' />
                                        </figure>
                                        <h1 className='text-3xl font-semibold'>500+</h1>
                                        <p className='text-text text-xl'>Projects Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Recent Awards */}
            <RecentAchivements />

            <div className='bg-greyMudBg'>
                <div className='flex flex-col items-center justify-center gap-y-5 py-5'>
                    <h1 className='md:text-5xl sm:text-2xl text-black font-bold'>Let's chat about your project</h1>
                    <div>
                    <button className='btn bg-gradiBg'onClick={()=> setEngagementModelOpen(true)}>Get Started &nbsp;<EastOutlinedIcon />
                    </button>
                    </div>
                </div>
            </div>
            {/* Contact Form */}
            <ContactForm />

            {isEngagementModelOpen && (
                <EngagementModel onClose={() => setEngagementModelOpen()} />
            )}
        </>
    );
}

export default IotAppDevelopmentFooter;
