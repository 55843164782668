import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import trustedCard1 from 'assets/Images/trusted-card-1.png';
import trustedCard2 from 'assets/Images/trusted-card-2.png';
import trustedCard3 from 'assets/Images/trusted-card-3.png';
import trustedCard4 from 'assets/Images/trusted-card-4.png';
import DashboardCamelImage from 'assets/Images/dashboard-ae-camel.svg'
import dashboardCard1 from 'assets/Images/second-card-1.svg'
import dashboardCard2 from 'assets/Images/second-card-2.svg'
import dashboardCard3 from 'assets/Images/second-card-3.svg'
import dashboardCard4 from 'assets/Images/second-card-4.svg'
import dubaiCityImage from 'assets/Images/dubai-image2.png'
import TechnologyStack from './carousel/TechnologyStack';
import ServicesInDubai from './ServicesInDubai';
import FrequentlyQuestions from './FrequentlyQuestions';
import ImageCarasoul from './carousel/ImageCarasoul';
import IndustriesWeCatter from './IndustriesWeCatter';
import ContactForm from './ContactForm';
import RecentAchivements from './RecentAchivements';
import RotateCards from './RotateCards';
import ChooseUsCards from './ChooseUsCards';
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import ContactFormDashboard from './ContactFormDashboard';

export default function Dashboard() {
  const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);
 
  useEffect(() => {
    AOS.init({
      duration: 1200,
      delay: 300,
      once: true,
      disable: 'mobile'
    });
  }, []);

  return (
    <>
      <section className='bg-dashboard bg-no-repeat bg-cover relative phedo z-[1]'>
        <div className='container flex space-y-5 md:flex-row sm:flex-col md:py-16 sm:py-5 text-white relative z-[2]'>
          <div className='text-white self-center md:w-3/5 sm:text-center md:text-start' data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h1 className='md:text-5xl sm:text-3xl tablet:text-4xl tablet:font-bold sm:font-extrabold'>
            Top <span className='sm:text-4xl md:text-5xl'> Software</span> Development Company in <span className='text-green-500'>Pakistan.</span>
            </h1> <br />
            <p className='md:text-xl sm:text-md leading-8'>
              SCube is an elite Software development company in Pakistan that offers top-notch IT solutions under a single roof. With over a decade of presence in the mobile apps development market and hands-on experience in delivering 2500+ projects to the world’s most prestigious start-ups & Fortune 500 companies, we carry the caliber to help you make a mark in the global business community.
            </p>
          </div>
          <div className='md:w-1.5/5 md:ml-6'>
           <ContactFormDashboard/>
          </div>
        </div>
      </section>

      {/* Trusted By section */}

      <section className="container mx-auto">
        <div className='flex w-full lg:flex-row sm:flex-col items-center py-5' data-aos="fade-up">
          <div className='lg:w-[25%] sm:w-[100%]'>
            <h1 className='lg:text-5xl sm:text-4xl font-bold pb-4 sm:text-center'>Trusted by</h1>
          </div>
          <div className='lg:w-[75%] sm:w-[100%] tablet:flex gap-3 sm:grid sm:grid-cols-2 '>
            <div className='bg-white rounded-lg lg:h-[140px] sm:w-[100%] rounded-lg transition duration-500 hover:scale-105 flex justify-center items-center hover:shadow-[2px_3px_10px_#00000029] shadow-md'>
              <figure className=''>
                <img className='object-contain' src={trustedCard1} alt='Card-1' />
              </figure>
            </div>
            <div className='bg-white p-2 rounded-lg lg:h-[140px] sm:w-[100%] rounded-lg transition duration-500 hover:scale-105 flex justify-center items-center hover:shadow-[2px_3px_10px_#00000029] shadow-md'>
              <figure>
                <img className='object-contain' src={trustedCard2} alt='Card-1' />
              </figure>
            </div>
            <div className='bg-white p-2 rounded-lg lg:h-[140px] sm:w-[100%] rounded-lg transition duration-500 hover:scale-105 flex justify-center items-center hover:shadow-[2px_3px_10px_#00000029] shadow-md'>
              <figure className=''>
                <img className='object-contain' src={trustedCard4} alt='Card-1' />
              </figure>
            </div>
            <div className='bg-white p-2 rounded-lg lg:h-[140px] sm:w-[100%] rounded-lg transition duration-500 hover:scale-105 flex justify-center items-center hover:shadow-[2px_3px_10px_#00000029] shadow-md'>
              <figure className=''>
                <img className='object-contain' src={trustedCard3} alt='Card-1' />
              </figure>
            </div>
          </div>
        </div>
        <hr />

        {/* Second Cards */}
        {/* 1st column */}
        <div className='flex gap-[2.1rem] flex-wrap mt-14'>
          <div className='sm:w-[100%] md:w-[100%] lg:w-[48%] sm:text-center md:text-left' data-aos="fade-up-right">
            <h1 className='lg:text-5xl sm:text-3xl font-bold mb-4'>Why Should You Launch a Business Mobile App in the world?</h1>
            <p className='tablet:text-xl sm:text-lg text-dark font-semibold'>Using the latest technology and industry expertise, we built top-end Android and iOS-based applications that add value to the business and user experience.</p>
          </div>

          <div className='bg-[#D6C7FF] lg:h-[320px] sm:w-[100%] md:w-[48%]  lg:w-[23%] rounded-lg  transition duration-500 hover:scale-105' data-aos="zoom-out-up">
            <div className='py-[50px] px-[20px] flex flex-col justify-center items-center gap-2 text-center'>
              <figure className='bg-white p-2 rounded-lg'>
                <img src={dashboardCard1} alt='Card-1' />
              </figure>
              <h3 className='text-xl font-bold'> Rise in<span className='lg:block'> downloads</span></h3>
              <p className='text-lg font-medium'> Mobile app downloading in the UAE surged <span>23% in 2022</span>                                                                    </p>
            </div>
          </div>

          <div className=' bg-[#B4FFB4] lg:h-[320px] sm:w-[100%] md:w-[48%]  lg:w-[23%] rounded-lg  transition duration-500 hover:scale-105' data-aos="zoom-out-up">
            <div className='py-[50px] px-[20px] flex flex-col justify-center items-center gap-2 text-center'>
              <figure className='bg-white p-2 rounded-lg'>
                <img src={dashboardCard2} alt='Card-1' />
              </figure>
              <h3 className='text-xl font-bold'> Increase in in-app<span className='lg:block'> Purchases</span></h3>
              <p className='text-lg font-medium'>In-app purchases in the UAE increased by<span> 9% in a year</span>                                                                    </p>
            </div>
          </div>

          {/* 2nd column */}

          <div className=' lg:block sm:hidden md:w-[100%] md:w-[48%] mt-4'>
            <img className='col-span-2' src={DashboardCamelImage} alt="camel Here" />
          </div>

          <div className='bg-[#B4D5FF] lg:h-[320px] sm:w-[100%] md:w-[48%] lg:w-[23%] rounded-lg  transition duration-500 hover:scale-105' data-aos="zoom-out-up">
            <div className='py-[50px] px-[20px] flex flex-col justify-center items-center gap-2 text-center'>
              <figure className='bg-white p-2 rounded-lg'>
                <img src={dashboardCard3} alt='Card-1' />
              </figure>
              <h3 className='text-xl font-bold'> Boost in app<span className='lg:block'> Installations </span></h3>
              <p className='text-lg font-medium'> Mobile apps got 41% more  <span>installations in 2022</span></p>
            </div>
          </div>

          <div className='bg-[#FFC7DC] lg:h-[320px] sm:w-[100%] md:w-[48%] lg:w-[23%] rounded-lg transition duration-500 hover:scale-105' data-aos="zoom-out-up">
            <div className='py-[50px] px-[20px] flex flex-col justify-center items-center gap-2 text-center'>
              <figure className='bg-white p-2 rounded-lg'>
                <img src={dashboardCard4} alt='Card-1' />
              </figure>
              <h3 className='text-xl font-bold'> Popularity Among<span className='lg:block'> Businesses</span></h3>
              <p className='text-lg font-medium'>84% of the UAE businesses are considering<span> mobile apps</span>                                                                    </p>
            </div>
          </div>
        </div>
      </section>
      {/* Android rollout with different page */}
      <ServicesInDubai />

      {/* CTA 2 */}
      <div>
        <IndustriesWeCatter />
        {/* Our Process Section */}
        <RotateCards />
      </div>

      {/* Cards */}
      <TechnologyStack />
      <ChooseUsCards />

      {/* Our recent achivements */}
      <RecentAchivements />

      {/* Swiper */}

      <div className='container mx-auto'>
        <div className='tablet:py-20 sm:py-10'>
          <div className='flex justify-between w-full tablet:h-[300px] sm:h-[300px] bg-black rounded-3xl'>
            <div data-aos="fade-left" className='flex flex-col justify-between py-5 lg:pl-10 sm:p-4 lg:p-0 text-white sm:text-center lg:text-start lg:w-[55%] sm:w-full'>
              <h1 className='lg:text-4xl sm:text-2xl font-bold leading-relaxed' style={{ lineHeight: '1.3' }}>Take Your Business to all new heights with the Top Software Development Company in Pakistan</h1>
              <div className=''>
                <button className='btn bg-gradiBg'
                  onClick={() => setEngagementModelOpen(true)}>
                  Request a demo &nbsp;<span><EastSharpIcon /></span>
                </button>
              </div>
            </div>
            <div className='lg:w-[50%] lg:block sm:hidden bg-gradient-to-b from-secondaryText to-primaryText clip-path-polygon rounded-r-3xl'>
              <img className='lg:w-full h-full pl-6' src={dubaiCityImage} alt='Dubai_City' />
            </div>
          </div>
        </div>
        <h1 data-aos="fade-up" className='md:text-5xl tablet:text-3xl sm:text-2xl text-center font-bold leading-snug pb-5'>Explore Our Outstanding Software Development Portfolio</h1>
      </div>
      {/* Image Carousal */}
      <ImageCarasoul />
      {/* Accordions */}
      <FrequentlyQuestions />
      {/* contact form */}
      <ContactForm />

      {
        isEngagementModelOpen &&
        <EngagementModel onClose={() => setEngagementModelOpen()} />
      }
    </>
  );
}