import React, { useState } from 'react';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import multiDomainEnterpriceImage from 'assets/nav/dedicatedTeam01.png'
import ContinuousSupportImage from 'assets/nav/dedicatedTeam02.png'
import PowerfulInfrastructureImage from 'assets/nav/dedicatedTeam03.png'
import ActionableInsightsImage from 'assets/nav/dedicatedTeam04.png'
import TimeZoneIndependenceImage from 'assets/nav/dedicatedTeam05.png'
import WorldClassProjectImage from 'assets/nav/dedicatedTeam06.png'
import DeliveryManagementImage from 'assets/nav/dedicatedTeam07.png'
import requiredFulfilmentImage from 'assets/nav/dedicatedTeam1re.png'
import WeConnectImage from 'assets/nav/weConnect1.png'
import WeConnectDollarImage from 'assets/nav/weConnect2.png'
import WeConnectTickImage from 'assets/nav/weConnectt1.png'
// Team Images
import ameerImage from 'assets/Team/ameerHamza.jpg'
import bhagChand from 'assets/Team/bhagChand.jpg'
import sohail from 'assets/Team/sohail.jpg'
import ceo from 'assets/nav/ceo.jpg'
import usamaImage from 'assets/Team/usama tariq.jpg'
import hamzaTariqImage from 'assets/Team/hamza tariq.jpg'
import huzaifaImage from 'assets/Team/huzaifa.jpg'

import TechnologyStack from '../component/carousel/TechnologyStack'
import ImageCarasoul from '../component/carousel/ImageCarasoul';
import ChooseUsCards from '../component/ChooseUsCards';
import RecentAchivements from '../component/RecentAchivements';
import ContactForm from '../component/ContactForm';
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={3}
			stroke="white"
			className={`${id === open ? "rotate-180" : ""} md:h-10 md:w-10 p-2 sm:h-7 sm:w-7 rounded-full transition-transform bg-gradiBg `}>
			<path strokeLinecap="round " strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
		</svg>
	);
}

const DedicatedTeam = () => {

	const [open, setOpen] = useState(0);
	const handleOpen = (value) => setOpen(open === value ? 0 : value);

	const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);

	return (
		<>
			<div className='bg-DedicatedTeamNavbarBg bg-cover bg-center tablet:h-screen w-full flex items-center justify-center bg-blend-multiply bg-neutral-500'>
				<div className='container mx-auto text-center space-y-5 sm:py-5 md:py-0'>
					<h1 className='md:text-5xl sm:text-4xl font-semibold text-white'>Hire a Dedicated Development Team</h1>
					<p className='tablet:text-xl text-white mb-5'>We have dedicated development team services to fit all your business needs, technologically and operationally.</p>
					<div>
						<button class='bg-gradiBg btn sm:text-sm md:text-xl' onClick={() => setEngagementModelOpen(true)}>
							Hire Dedicated Developers &nbsp;<span><EastSharpIcon /></span>
						</button>
					</div>
				</div>
			</div>
			<div className='container mx-auto '>
				<div className='text-center py-5 space-y-3'>
					<h1 className='md:text-[50px] sm:text-3xl font-bold '>Why Hire a Specialized Development Team?</h1>
					<p className='text-xl'>By hiring a dedicated development team, you can add flexibility, visibility, optimal management, and more to your
						software/application development process and get expert advice and consultation.</p>
				</div>

				<div className='grid md:grid-cols-3 gap-3 text-center'>
					<div className='overflow-hidden rounded-[20px]'>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${multiDomainEnterpriceImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>Multi-Domain Expertise</h1></span>
						</div>
					</div>

					<div className='overflow-hidden rounded-[20px]'>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${ContinuousSupportImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>Continuous Support</h1></span>
						</div>
					</div>

					<div className='overflow-hidden  rounded-[20px] row-span-3'>
						<div className='h-full bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${DeliveryManagementImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[125%]'>Dedicated Delivery Management</h1></span>
						</div>
					</div>

					<div className='overflow-hidden  rounded-[20px] '>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${PowerfulInfrastructureImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>Powerful Infrastructure</h1></span>
						</div>
					</div>

					<div className='overflow-hidden  rounded-[20px]'>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${ActionableInsightsImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>Actionable Insights</h1></span>
						</div>
					</div>

					<div className='overflow-hidden  rounded-[20px]'>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${TimeZoneIndependenceImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>Time-Zone Independence</h1></span>
						</div>
					</div>

					<div className='overflow-hidden  rounded-[20px]'>
						<div className='h-[320px] bg-no-repeat bg-contain relative '>
							<div className='zoom-in' style={{ backgroundImage: `url(${WorldClassProjectImage})` }}>
							</div>
							<span className='text-3xl text-center text-white font-semibold relative'> <h1 className='mt-[40%]'>World-Class Project Management</h1></span>
						</div>
					</div>
				</div>
				<div>
					<div className='text-center py-5 space-y-3'>
						<h1 className='md:text-5xl sm:text-3xl font-bold'>All Development Requirements Fulfilled</h1>
						<p className='text-xl'>Our dedicated development team model aims to fulfill all your software needs, may it be mobile app development, video game development, or web development.</p>
					</div>
					<div className='w-full flex justify-between relative'>
						<div className='sm:hidden md:block w-[50%]'>
							<div style={{ position: 'sticky', top: '100px', bottom: '0px' }}>
								<figure className='h-full w-full'>
									<img src={requiredFulfilmentImage} alt="Required fulfilment" />
								</figure>
							</div>
						</div>
						<div className='md:w-[43%] sm:w-full'>
							<div className='flex flex-col space-y-4'>
								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg'>
									<h1 className='text-dark text-2xl font-semibold  mb-2'>iOS App Developers:</h1>
									<p className='text-text'>Get iOS app developers to help you champion all iOS app development needs. We have developers adept in development in both Objective-C and Swift.</p>
								</div>

								<div className='rounded-lg p-3 shadow-custom-shadow hover:bg-gradiBg'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Android App Developers:</h1>
									<p className='text-text'>Choose from a giant pool of talented Android app developers to get a robust, reliable, and scalable Android application.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Cross-Platform Developers:</h1>
									<p className='text-text'>Get developers proficient in cross-platform development to develop a platform-agnostic mobile app that can work on both Android and iOS platforms.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Web Developers:</h1>
									<p className='text-text'>Build a powerful web app or website with our web developers who are skilled in all the trending web technologies, including HTML, CSS, JS and its frameworks, and XSS.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>UI/UX Designers:</h1>
									<p className='text-text'>Add experienced UI/UX designers to your in-house development team who will spearhead in creating captivating and delightful user experiences and interfaces.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Backend Developers:</h1>
									<p className='text-text'>Get backend development experts to empower your mobile app development projects with leading technologies like NodeJS, PHP, and ROR.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Frontend Developers:</h1>
									<p className='text-text'>Bring world-class front-end development to your project by leveraging leading technologies like JavaScript, React, and Angular.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Full-Stack Developers:</h1>
									<p className='text-text'>Fulfill all your frontend and backend development requirements simultaneously with skilled full-stack developers.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>AI Developers:</h1>
									<p className='text-text'>Bring innovation to your project by adding artificial intelligence to it. We have skilled AI developers to work in chatbot development, AI integration, and more.</p>
								</div>

								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>ML Experts:</h1>
									<p className='text-text'>Develop and implement machine learning models with ML experts at SCube. Learn and adapt according to the data and make accurate customer behavior predictions.</p>
								</div>
								<div className='rounded-lg p-4 shadow-custom-shadow hover:bg-gradiBg hover:text-white'>
									<h1 className='text-dark text-2xl font-semibold mb-2'>Game Developers:</h1>
									<p className='text-text'>Build a groundbreaking video game using leading gaming engines like Unity, Unreal Engine, Blender, Maya, and CryEngine. Our game development experts have helped realize outstanding games for mobile platforms.</p>
								</div>
							</div>

						</div>
					</div>
					<div className='text-center py-5 space-y-3'>
						<h1 className='md:text-[47px] sm:text-3xl font-bold'>How We Engage?</h1>
						<p className='text-lg'>SCube is your digital transformation partner offering flexibility and agility to thrive in the global market. Our hiring models fit even the most unpredictable development prerequisites with consideration and alignment to scope, scale, and industry verticals.</p>
					</div>
				</div>
			</div>

			<div className='relative'>
				<div className='w-full bg-darkBg rounded-[50px] '>
					<div className='flex md:flex-row sm:flex-col px-[60px] py-[70px] gap-5' style={{ position: 'sticky', top: '200px', zIndex: '1' }}>
						<div className='md:w-1/2'>
							<figure>
								<img src={WeConnectImage} alt="How We Connect" />
							</figure>
						</div>
						<div className='md:w-1/2'>
							<div className='space-y-7'>
								<figure>
									<img src={WeConnectDollarImage} alt="Tick" />
								</figure>
								<h1 className='text-3xl font-bold text-white'>Fixed-Cost Model</h1>
								<div className='bg-gradient-to-r from-dark to-[#33B4FF] h-2 w-[100px] rounded-2xl'></div>
								<div className='flex items-end gap-2'>
									<img src={WeConnectTickImage} alt="We Connect" />
									<p className='text-xl text-white'>Well-defined deliverables and deadlines.</p>
								</div>

								<div className='flex items-end gap-2'>
									<img src={WeConnectTickImage} alt="We Connect" />
									<p className='text-xl text-white'>Billing on the basis of pre-defined milestones.</p>
								</div>

								<div className='flex items-end gap-2'>
									<img src={WeConnectTickImage} alt="We Connect" />
									<p className='text-xl text-white'>Includes the use of market-dominating technologies</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='container mx-auto'>
					<div className="max-w-sm w-full lg:max-w-full lg:flex mx-auto tablet:my-10">
						<div className="tablet:h-[350px] sm:h-[280px] tablet:w-[30%] sm:w-[90%] mx-auto flex-none bg-contain bg-center bg-no-repeat rounded-3xl text-center overflow-hidden"
							style={{ backgroundImage: `url(${ceo})` }}
							title="CEO">
						</div>
						<div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 w-full">
							<div>
								<a href="##" className="text-gray-900 font-bold text-2xl mb-2 hover:text-indigo-600 transition duration-500 ease-in-out">Suleman Ali</a>
								<p className="text-xl text-gray-600">CEO</p>
								<p className="text-gray-500 text-base mt-4">Meet our esteemed CEO, the driving force behind SCube Software House in Pakistan, whose unwavering dedication and visionary leadership inspire our team to pursue innovation and excellence in every aspect of our work.</p>

								<div className="my-4 flex">
									<a href="##">
										<svg className="mr-3" width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<g data-name="Layer 2">
												<g data-name="facebook">
													<rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
													<path
														d="M17 3.5a.5.5 0 0 0-.5-.5H14a4.77 4.77 0 0 0-5 4.5v2.7H6.5a.5.5 0 0 0-.5.5v2.6a.5.5 0 0 0 .5.5H9v6.7a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-6.7h2.62a.5.5 0 0 0 .49-.37l.72-2.6a.5.5 0 0 0-.48-.63H13V7.5a1 1 0 0 1 1-.9h2.5a.5.5 0 0 0 .5-.5z">
													</path>
												</g>
											</g>
										</svg>
									</a>
									<a href="##">
										<svg className="mr-3" width="16px" height="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 310 310" style={{ enableBackground: "new 0 0 310 310" }} xmlSpace="preserve">
											<g id="XMLID_826_">
												<path id="XMLID_827_" d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73
	c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783
	c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598
	C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467
	c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977
	c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889
	c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597
	c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961
	c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895
	c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367
	c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998
	C307.394,57.037,305.009,56.486,302.973,57.388z"></path>
											</g>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white dark:bg-gray-900">
					<div className="py-8 container mx-auto text-center">
						<div className=" mb-8 lg:mb-16">
							<h2 className="mb-4 text-4xl tracking-tight font-extrabold text-black dark:text-white">Our team</h2>
							<p className="font-light text-gray-500 sm:text-xl dark:text-gray-400"> <span>Meet our team of dedicated professionals, each bringing a unique blend of expertise and passion to the table, working together to bring your vision to life.</span></p>
						</div>
						<div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3">
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={ameerImage} alt="Bonnie Avatar" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Ameer Hamza</a>
								</h3>
								<p>Web Developer(React + Nest Js)</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={huzaifaImage} alt="Helene Avatar" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Muhammad Huzaifa</a>
								</h3>
								<p>MERN Stack Developer</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={usamaImage} alt="usama TariqImage" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Usama Tariq</a>
								</h3>
								<p>Backend Developer(Django)</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={bhagChand} alt="BhagChand Avatar" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Bhag Chand</a>
								</h3>
								<p>IOS & Android</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={hamzaTariqImage} alt="Sofia Avatar" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Hamza Tariq</a>
								</h3>
								<p>Flutter Developer</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="text-center text-gray-500 dark:text-gray-400">
								<img className="mx-auto mb-4 w-40 h-40 rounded-full" src={sohail} alt="Leslie Avatar" />
								<h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
									<a href="##">Mr Sohail</a>
								</h3>
								<p>Flutter Developer</p>
								<ul className="flex justify-center mt-4 space-x-4">
									<li>
										<a href="##" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-gray-900 hover:text-gray-900 dark:hover:text-white dark:text-gray-300">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
										</a>
									</li>
									<li>
										<a href="##" className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
											<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
										</a>
									</li>
								</ul>
							</div>
													
						</div>
					</div>
				</div>
			</div>
			<div>
				<TechnologyStack />

				<div className='text-center py-5 space-y-3'>
					<h1 className='lg:text-5xl tablet:text-4xl sm:text-2xl font-bold'>Featured Projects</h1>
					<p className='md:text-xl sm:text-base text-text'>Using the latest technology and industry expertise, we built top-end Android and iOS-based applications that add value to the business and user experience.</p>
				</div>
				<ImageCarasoul />
				<ChooseUsCards />
				<RecentAchivements />

				<div>
					<div data-aos="fade-up" className="container flex flex-col items-center justify-between gap-4 md:py-[60px] sm:py-[20px]">
						<span className="md:text-4xl sm:text-xl font-bold md:mb-4 sm:mb-2">FAQ's</span>
						<Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 1} icon={<Icon id={1} open={open} />}>
							<AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(1)}>
								Why do you need a dedicated development team?
							</AccordionHeader>
							<AccordionBody className="text-text md:text-lg sm:text-sm">
								<SwipeRightAltIcon /> When you have a dedicated team of professionals, their primary focus is on your project alone. They are not divided among multiple projects or distracted by other responsibilities. This allows them to fully concentrate on your project’s goals, requirements, and timelines, ensuring better commitment and accountability.
							</AccordionBody>
						</Accordion>

						<Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 2} icon={<Icon id={2} open={open} />}>
							<AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(2)}>
								How can I select the right dedicated development team for my project?
							</AccordionHeader>
							<AccordionBody className="text-text text-lg">
								Selecting the right dedicated development team for your project requires careful consideration and evaluation.
								<SwipeRightAltIcon /> Start by clearly defining your project’s scope, goals, and specific requirements.
								<SwipeRightAltIcon /> Understand the technologies, platforms, and skill sets required for your project.
								<SwipeRightAltIcon /> Look for a dedicated development team with relevant expertise and experience in your industry and technology stack.
								<SwipeRightAltIcon /> Understand the development methodologies followed by a dedicated team.
							</AccordionBody>
						</Accordion>

						<Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 3} icon={<Icon id={3} open={open} />}>
							<AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(3)}>
								What are the advantages of hiring a dedicated software development team?
							</AccordionHeader>
							<AccordionBody className="text-text text-lg">
								<SwipeRightAltIcon /> IT professionals in dedicated development teams are skilled in various areas of software development. They bring in the best technologies and methodologies being followed currently in the industry. Their expertise enables them to tackle complex challenges, provide innovative solutions, and ensure high quality. They become familiar with your business processes, goals, and technical infrastructure. This familiarity leads to enhanced collaboration, improved productivity, and a deeper understanding of your project, which can result in better outcomes and ongoing success.
							</AccordionBody>
						</Accordion>

						<Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 4} icon={<Icon id={4} open={open} />}>
							<AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(4)}>
								Why should you choose SCube for hiring dedicated developers?
							</AccordionHeader>
							<AccordionBody className="text-text text-lg ">
								<SwipeRightAltIcon /> At SCube, you have the opportunity to hire IT professionals that specialize in software and web development, allowing you to effectively achieve your business goals. By trusting SCube as your digital transformation partner, you will benefit from the collective knowledge and experience of our dedicated developers. They stay up-to-date with the latest industry trends and best practices, ensuring that your project is executed using cutting-edge technologies and follows industry standards.          </AccordionBody>
						</Accordion>
					</div>
				</div>
				<ContactForm />
			</div>
			{isEngagementModelOpen && (
				<EngagementModel onClose={() => setEngagementModelOpen()} />
			)}
		</>
	);
}

export default DedicatedTeam;
