import React from 'react';
import spinner from 'assets/Images/spinner.svg'
import EastSharpIcon from '@mui/icons-material/EastSharp';
import pakistanFlagIcon from 'assets/Images/pkFlag-icon.png'
import emailIcon from 'assets/SVG/emailIcon.svg'
import { useContactFormContext } from '../service/ContactFormContext';

const ContactForm = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    subject,
    setSubject,
    description,
    setDescription,
    error,
    loading,
    handleSubmit,
    contactNumbers,
    contactEmails,
  } = useContactFormContext();

  return (
    <>
      <div className='container py-5 flex justify-between space-y-8 md:flex-row sm:flex-col'>
        <div className='flex flex-col md:gap-5 sm:gap-2 md:w-[40%]'>
          <div className='flex flex-col md:gap-3'>
            <p className='md:text-4xl sm:text-lg font-semibold'>Get in Touch with</p>
            <p className='md:text-7xl sm:text-4xl font-bold text-primaryText sm:mb-2 md:mb-0'>Our <span className='lg:block '>Experts</span></p>
            <p>Want to find out how SCube can help your organization? We’d love to hear from you.</p>
          </div>

          <div className=' flex flex-col md:gap-3 sm:gap-2'>
            <p className='md:text-3xl sm:text-xl md:font-semibold sm:font-bold'>Join our Partner network</p>
            <p className='md:text-xs sm:text-sm'>Become An SCube Partner To Launch, Run And Grow Your Business Globally. Kickstart Your Digital Journey</p>
            <span className='flex items-center gap-2 md:text-xl sm:text-lg font-semibold'>Become a Partner <span className='bg-gradiBg rounded-full md:p-2 sm:p-1 md:h-9 md:w-9 flex justify-center items-center text-white'><EastSharpIcon /></span> </span>
          </div>

          <div className='flex flex-col md:gap-3 sm:gap-2'>
            <p className='md:text-3xl sm:text-xl md:font-semibold sm:font-bold'>Contact Info:</p>
            <ul className='flex flex-col sm:justify-between leading-8'>
              {contactEmails.map((emailItem, index) => (
                <React.Fragment key={index}>
                  <li>
                    <a href='mailto:${emailItem.emailAddress}' title='Send Email to SCube' className='flex items-center gap-2'>
                      <figure><img src={emailIcon} alt="Email Icon" /></figure>
                      <span>{emailItem.emailAddress}</span>
                    </a>
                  </li>
                  </React.Fragment>
                   ))
              }
               {contactNumbers.map((phoneItems, index) => (
                <React.Fragment key={index}>
                  <li>
                    <a href='tel:+923126747241' title='Call Us' className='flex items-center gap-2'>
                      <figure>
                        <img className='w-6 h-5' src={pakistanFlagIcon} alt="Pakistan Flag Icon" />
                      </figure>
                      <span>{phoneItems.phoneNo}</span>
                    </a>
                  </li>
                </React.Fragment>
              ))
              }
            </ul>
          </div>
        </div>

        <div className='bg-formBg md:rounded-3xl sm:rounded-xl sm:p-5 md:w-[50%] text-dark'>
          <div className='flex flex-col gap-3'>
            <p className='md:text-4xl sm:text-xl md:font-semibold sm:font-bold md:mb-4 sm:text-center md:text-left'>Let’s Get to know you</p>

            <form onSubmit={handleSubmit}>
              <input type='text' name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' title='Please Enter Your Full Name Here!' className='border-[1px] border-solid border-text md:h-14 sm:h-12 px-3 w-full text-base shadow-none md:mb-4 sm:mb-3 md:rounded-xl sm:rounded-lg border-[1px] focus:border-primaryText focus:outline-none' />
              {error.name && <h1 className='text-danger text-xs mb-2'>{error.name}</h1>}

              <input type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' title='Please Enter Your Email Address Here!' className='border-[1px] border-solid border-text md:h-14 sm:h-12 px-3 w-full text-base shadow-none md:mb-4 sm:mb-3 md:rounded-xl sm:rounded-lg border-[1px] focus:border-primaryText focus:outline-none' />
              {error.email && <h1 className='text-danger text-xs mb-2'>{error.email}</h1>}

              <input type='tel' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} pattern='^\+?[0-9\-]*' placeholder='Phone Number' autoComplete='off' title='Please enter a valid phone number (only digits allowed)' className='border-[1px] border-solid border-text md:h-14 sm:h-12 px-3 w-full text-base shadow-none md:mb-4 sm:mb-3 md:rounded-xl sm:rounded-lg border-[1px] focus:border-primaryText focus:outline-none' />
              {error.phone && <h1 className='text-danger text-xs mb-2'>{error.phone}</h1>}

              <input type='text' name='subject' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject...' title='Please Enter Subject Here!' className='border-[1px] border-solid border-text md:h-14 sm:h-12 px-3 w-full text-base shadow-none md:mb-4 sm:mb-3 md:rounded-xl sm:rounded-lg border-[1px] focus:border-primaryText focus:outline-none' />
              {error.subject && <h1 className='text-danger text-xs mb-2'>{error.subject}</h1>}


              <textarea rows={5} name='description' onChange={(e) => setDescription(e.target.value)} value={description} placeholder='About Project text' title='Please Enter Complete Details..!' className='border-[1px] border-solid border-text p-3 w-full text-base shadow-none md:mb-4 sm:mb-3 md:rounded-xl sm:rounded-lg border-[1px] focus:border-primaryText focus:outline-none' />
              {error.description && <h1 className='text-danger text-xs mb-2'>{error.description}</h1>}

              <button className='bg-gradiBg bg-primaryBg hover:bg-none rounded-lg px-3 py-[10px] text-white font-semibold text-xl'>
                {loading ?
                  <span className='flex items-center justify-center p-0 m-0'>
                    <span>Submit</span>
                    <img className='h-8 w-10' src={spinner} alt="Loading...." />
                  </span>
                  :
                  <span>Submit <EastSharpIcon /></span>
                }
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactForm;
