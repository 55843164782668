//Cards
import card1 from 'assets/SVG/skankcnskc1 (4).svg'
import card2 from 'assets/SVG/skankcnskc1 (5).svg'
import card3 from 'assets/SVG/skankcnskc1 (6).svg'
import card4 from 'assets/SVG/skankcnskc1 (7).svg'
import card5 from 'assets/SVG/skankcnskc1 (8).svg'
import card6 from 'assets/SVG/skankcnskc1 (9).svg'
import card7 from 'assets/SVG/skankcnskc1 (15).svg'
import card8 from 'assets/SVG/skankcnskc1 (10).svg'

import AchivementClutch1 from 'assets/Images/clutch.png'
import AchivementItfirm from 'assets/Images/itfirm.png'
import AchivementClutch3 from 'assets/Images/clutch3.png'
import AchivementTopDev from 'assets/Images/topDev.png'
import AchivementAppFura from 'assets/Images/appFura.png'
import AchivementGoodFirm from 'assets/Images/goodFirm.png'

import AchivementSolution  from 'assets/Images/awsSolution.png'
import AchivementSecurity from 'assets/Images/awsSecurity.png'
import AchivementDeveloper  from 'assets/Images/awsDeveloper.png'
import AchivementMicroSoft  from 'assets/Images/microsoft.png'
import AchivementAssociate  from 'assets/Images/associate.png'

export const ApptunixCards=[
  {
    cardImage: card1,
    detail:'100% Transparency'
  },
  {
    cardImage: card2,
    detail:'Flexible engagement models'
  },
  {
    cardImage: card3,
    detail:'24×7 Support across all timezones'
  },
  {
    cardImage: card4,
    detail:'Pricing with flexibility in payment'
  },
  {
    cardImage: card5,
    detail:'On-time delivery after quality testing'
  },
  {
    cardImage: card6,
    detail:'State-of-the-art IT infrastructure'
  },
  {
    cardImage: card7,
    detail:'Strong technology competency'
  },
  {
    cardImage: card8,
    detail:'Seamless communication'
  },
];

export const AchivementsFirstSection=[
  {
    achivementCard : AchivementClutch1,
    tittle: 'Clutch',
    description: 'Top 10 companies in the 2022'
  },
  {
    achivementCard : AchivementItfirm,
    tittle: 'ItFirms',
    description: 'World’s Top Mobile App Development Companies 2022'
  },
  {
    achivementCard : AchivementClutch3,
    tittle: 'Clutch',
    description: 'Top Developers in the India 2022'
  },
  {
    achivementCard : AchivementTopDev,
    tittle: 'TopDevelopers',
    description: 'Top Mobile App Developers'
  },
  {
    achivementCard :AchivementAppFura ,
    tittle: 'Appfutura',
    description: 'Top App Development Company'
  },
  {
    achivementCard : AchivementGoodFirm,
    tittle: 'GoodFirms',
    description: 'Top Mobile App Developers UK'
  },
]

export const AchivementsSecondSection=[
  {
    achivementCard : AchivementSolution,
    tittle: 'AWS',
    description: 'Solution Architech Associate'
  },

  {
    achivementCard : AchivementSecurity,
    tittle: 'AWS',
    description: 'Security, Speciality'
  },

  {
    achivementCard : AchivementDeveloper,
    tittle: 'AWS',
    description: 'Developer Associate'
  },

  {
    achivementCard : AchivementMicroSoft,
    tittle: 'Microsoft',
    description: 'Associate'
  },
  
  {
    achivementCard : AchivementAssociate,
    tittle: 'Microsoft',
    description: 'Dynamic 365 Fundamentals'
  },
 
]

export const accordionData=[
  {
    heading:'How much does it cost for a mobile app developed by a top mobile app development company in Dubai?',
    description:'The cost to develop a basic mobile app in Dubai can range between 80k AED to 1100k AED. Apptunix as a premier mobile app development company in Dubai will be able to define the actual development cost based on the set of required features and the nature of the app (hybrid or native).'
  },
  {
    heading:'How much time does it take to build an app in Dubai?',
    description:'When it comes to mobile app development in Dubai, the time varies as per the functionality and complexity of the project. But on average, the midsize app project takes anywhere around 2-4 months.'
  },
  {
    heading:'How to launch an app in Dubai?',
    description:'It’s simple! Just hire Apptuix as your trusted Mobile App Development Company in Dubai, and watch your app launching on both Google Play and Apple App Store.'
  },
  {
    heading:'Do you offer mobile app support services in Dubai?',
    description:'As the best mobile app development company in Dubai, we not only offer you the advantage of support services but can also help you modify or update your existing app per your specific business requirement and as per the market need.'
  },
]


  export default ApptunixCards;