import React from 'react';

const PageNotFound = () => {
  return (
    <div className='text-center py-5'>
      <span className='text-3xl font-bold text-danger'>403 Forbidden</span>
      <hr/>
      <small className='text-xl text-primary'>Scube</small>
    </div>
  );
} 
export default PageNotFound;
