import React, { useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="white"
            className={`${id === open ? "rotate-180" : ""} md:h-10 md:w-10 p-2 sm:h-7 sm:w-7 rounded-full transition-transform bg-gradiBg `}>
            <path strokeLinecap="round " strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
export default function MobileAppQuestions() {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return (
        <>
            <div data-aos="fade-up" className="container flex flex-col items-center justify-between gap-4 md:py-[60px] sm:py-[20px]">
                <span className="md:text-4xl sm:text-xl font-bold md:mb-4 sm:mb-2">Top Frequently Asked Questions</span>
                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(1)}>
                        What is mobile app development and its types?
                        </AccordionHeader>
                    <AccordionBody className="text-text md:text-lg sm:text-sm">
                        Mobile application development is the process of developing applications for mobile platforms. Mobile app development can be of several types including native app development, Android app development, iOS app development, and hybrid mobile application development.
                    </AccordionBody>
                </Accordion>

                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 2} icon={<Icon id={2} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(2)}> 
                    What are the best languages for mobile app development?                                            
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg">
                    There are several programming languages suitable for mobile app development. Choosing one depends on several factors including the platforms chosen and features required. C++, Java, JavaScript, Kotlin, and Swift are some of the most popular languages preferred by a mobile app development company.
                    </AccordionBody>
                </Accordion>

                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 3} icon={<Icon id={3} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(3)}>                   
                    How profitable is developing an app for your business?                                            
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg">
                    The total revenue generated by the app market is expected to hit $756 billion by 2027. The total in-app revenue generated by apps crossed the $200 billion mark in 2022.
                    </AccordionBody>
                </Accordion>

                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 4} icon={<Icon id={4} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(4)}>              
                    What are the seven steps of mobile app development?                                            
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg ">
                    Business idea development, analysis and planning, UI/UX design, coding, application testing, deployment, and maintenance are the seven steps of mobile app development.
                    </AccordionBody>
                </Accordion>
                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left md:px-5 sm:px-3" open={open === 5} icon={<Icon id={5} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-[15px] font-bold hover:text-primaryText" onClick={() => handleOpen(5)}>              
                   How do I find the best mobile application development company?                                                                                    
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg ">
                    To find the best mobile app development services for your business requirements, you need to prepare a list of potential options and then go through each of them. You must talk with their representatives to know more about their offerings.
                    </AccordionBody>
                </Accordion>
            </div>
        </>
    )
}
