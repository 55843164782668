import React, { useState } from 'react'
import InternetOfthing from 'assets/png/Internet-of-Things dark.jpg'
import Cloudcomputing from 'assets/png/Cloud-Computing.jpg'
import Artificial from 'assets/png/AI-ML.jpg'
import Metaverse from 'assets/png/Metavarse.jpg'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import 'swiper/css';
import 'component/carousel/Imagecarasol.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';

export default function ModernTechnologies() {
    const [activeItem, setActiveItem] = useState(1)
    const handleMouseEnter = (index) => {
        if (activeItem !== index) {
            setActiveItem(index)
        }
    }
    const handleClick = () => {
        // Add your button click logic here
        console.log('Button clicked!');
    };

    return (
        <>
            <section className='py-5 sm:hidden md:block'>
                <div className='text-white text-center'>
                    <h2 className='font-black md:text-5xl tablet:text-3xl sm:text-2xl'>Modern Technologies For New-Age Apps</h2>
                    <p className='md:text-xl leading-10'>Leverage tried-and-trusted custom mobile application development technologies to transform
                        <span className='block'>your business idea the best way.</span>
                    </p>
                </div>
                {/* technologies navbar */}
                <div className='container pt-12 pb-12'>
                    <div className='text-white text-xl font-normal'>
                        <ul className='flex flex-row justify-between pb-0'>
                            <li className={`cursor-pointer ${activeItem === 1 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(1)}>
                                <h1 className='pb-4'>Internet of things</h1>
                            </li>
                            <li className={`cursor-pointer ${activeItem === 2 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(2)}>
                                <h1 className='pb-4'>Blockhain</h1>
                            </li>
                            <li className={`cursor-pointer ${activeItem === 3 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(3)}>
                                <h1 className='pb-4'> AI/ML</h1>
                            </li>
                            <li className={`cursor-pointer ${activeItem === 4 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(4)}>
                                <h1 className='pb-4'>Cloud Computing</h1>
                            </li>
                            <li className={`cursor-pointer ${activeItem === 5 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(5)}>
                                <h1 className='pb-4'>AR/VR</h1>
                            </li>
                            <li className={`cursor-pointer ${activeItem === 6 ? 'border-b-4 border-whiteBg' : ''}`} onMouseEnter={() => handleMouseEnter(6)}>
                                <h1 className='pb-4'>Metaverse</h1>
                            </li>
                        </ul>
                        <hr className='border-2' />
                    </div>
                    {/* modern technologies expandables */}
                    <div className='w-full'>
                        {/* Internet of things */}
                        <div id='text-1' className={`${activeItem === 1 ? '' : 'hidden'}`}>
                            <div id='text-1' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={InternetOfthing} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>Internet of Things</h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>50% of enterprises are taking key steps enhancing IoT spending. "Internet of Things" is not just a technology; it's a network of all networks that put a profound impact on every aspect of business. IoT has the potential to transform the way we live but also how we work.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>Our team will keep an eye on business operations using IoT-enabled applications that assist corporate growth inside a secure and well-built infrastructure. A house of highly professional IoT developers provides consulting services, ensuring you get sound advice on leveraging IoT's power.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>

                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>

                        {/* Blockchain */}
                        <div id='text-2' className={`${activeItem === 2 ? '' : 'hidden'}`}>
                            <div id='text-2' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={Cloudcomputing} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>Blockchain</h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>The global blockchain market size is projected to grow at an AGR of 40%. We understand the need for a safe and decentralized database. We are offering blockchain app development services based on the specifications, timeline, project size, and budget of your project.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>For all business applications, we offer end-to-end blockchain application testing and quality assurance services. To guarantee that your blockchain apps are tested on time, within your budget, and in accordance with all testing criteria, our team makes use of their knowledge in the application testing field.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>
                        {/* AI/ML */}
                        <div id='text-3' className={`${activeItem === 3 ? '' : 'hidden'}`}>
                            <div id='text-3' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={Artificial} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>AI/ML </h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>The global blockchain market size is projected to grow at an AGR of 40%. We understand the need for a safe and decentralized database. We are offering blockchain app development services based on the specifications, timeline, project size, and budget of your project.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>For all business applications, we offer end-to-end blockchain application testing and quality assurance services. To guarantee that your blockchain apps are tested on time, within your budget, and in accordance with all testing criteria, our team makes use of their knowledge in the application testing field.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>

                        {/* cloud computing */}
                        <div id='text-4' className={`${activeItem === 4 ? '' : 'hidden'}`}>
                            <div id='text-4' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={Cloudcomputing} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>Cloud Computing</h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>Cloud computing technology can increase your business scalability, security, and connectivity. Our mobile app development company incorporates cloud computing in the deployment of functional and responsive applications to carry out amazing programming.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>To guarantee that everything from business applications to integrated software and IT infrastructure is current and operates effectively, we provide end-to-end cloud-managed services. For our clients throughout the world, we provide a full variety of cloud consultation services, including cloud deployment, custom development, data migration, cloud modernization, audit, and testing services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>

                        {/* AR/VR */}
                        <div id='text-5' className={`${activeItem === 5 ? '' : 'hidden'}`}>
                            <div id='text-5' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={Cloudcomputing} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>AR/VR</h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>AR/VR apps have flooded the Google Play Store and Apple App Store with great response. Give your Gen-Z users an immersive experience with high-end AR/VR mobile app development services and create a new vision.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>Our experts can help you maximize the potential of cutting-edge technology like AR and VR. We thrive at providing the most dependable, cutting-edge, and next-generation AR/VR programs together with detailed animated graphics. We use the most advanced tools to deliver scalable AR/VR apps with comprehensive features. We can transform the experiences you envision for your app into reality.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>

                        {/* Metaverse */}
                        <div id='text-6' className={`${activeItem === 6 ? '' : 'hidden'}`}>
                            <div id='text-6' className={`p-3`}>
                                <div className='flex flex-row w-full'>
                                    <div className='w-1/2'>
                                        <figure className='w-full'>
                                            <img src={Metaverse} alt='Andriod' style={{ width: "100%", height: "100%" }} />
                                        </figure>
                                    </div>
                                    <div className='w-1/2 p-5 text-white'>
                                        <h2 className='text-4xl font-black mb-2'>Metaverse</h2>
                                        <p className='mb-2 leading-7 font-normal text-xl'>The potential of metaverse applications in today's real world is completely endless. Hire experienced mobile app developers to build user-friendly, highly engaging, and intuitive metaverse applications with immersive virtual experiences. Without a flawless and powerful 3D visualization, no one would be compelled to indulge in the Metaserve.</p>
                                        <p className='mb-2 leading-7 font-normal text-xl'>Utilize our 3D modeling services for the metaverse to draw users to your virtual presence. To safely expand your business into the metaverse, get in touch with our metaverse experts. By incorporating our business-focused metaverse technologies, you may create limitless business opportunities and experience flawless growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <button className='btn bg-gradiBg' onClick={handleClick}>Learn More <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='sm:block md:hidden bg-navyBlue'>
                <div className='container mx-auto'>
                    <div className='text-center text-white font-bold text-2xl py-4' style={{ lineHeight: '1.3' }}>
                        <h2>Modern Technologies For New-Age Apps</h2>
                    </div>
                    <Swiper
                        spaceBetween={40}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 1300,
                            disableOnInteraction: false,
                        }}

                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper " style={{ paddingBottom: '160px' }}
                        breakpoints={{
                            960: {
                                slidesPerView: 2.1,
                            },
                            760: {
                                slidesPerView: 1.5,
                            },
                            350: {
                                slidesPerView: 1,

                            },
                        }}
                    >
                        <SwiperSlide className='rounded-xl hover:cursor-grab'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={InternetOfthing} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>Internet of Things</h3>
                                    <p className='text-sm'>50% of enterprises are taking key steps enhancing IoT spending. "Internet of Things" is not just a technology; it's a network of all networks that put a profound impact on every aspect of business. IoT has the potential to transform the way we live but also how we work.</p>
                                    <p className='text-sm'>Our team will keep an eye on business operations using IoT-enabled applications that assist corporate growth inside a secure and well-built infrastructure. A house of highly professional IoT developers provides consulting services, ensuring you get sound advice on leveraging IoT's power.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className=' rounded-xl hover:cursor-grab overflow-hidden'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={Cloudcomputing} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>Blockchain</h3>
                                    <p className='text-sm'>The global blockchain market size is projected to grow at an AGR of 40%. We understand the need for a safe and decentralized database. We are offering blockchain app development services based on the specifications, timeline, project size, and budget of your project.</p>
                                <p className='text-sm'>For all business applications, we offer end-to-end blockchain application testing and quality assurance services. To guarantee that your blockchain apps are tested on time, within your budget, and in accordance with all testing criteria, our team makes use of their knowledge in the application testing field.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='rounded-xl hover:cursor-grab'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={Artificial} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>AI/ML</h3>
                                    <p className='text-sm'>The global blockchain market size is projected to grow at an AGR of 40%. We understand the need for a safe and decentralized database. We are offering blockchain app development services based on the specifications, timeline, project size, and budget of your project.</p>
                                    <p className='text-sm'>For all business applications, we offer end-to-end blockchain application testing and quality assurance services. To guarantee that your blockchain apps are tested on time, within your budget, and in accordance with all testing criteria, our team makes use of their knowledge in the application testing field.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className=' rounded-xl hover:cursor-grab'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={Cloudcomputing} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>Cloud Computing</h3>
                                    <p className='text-sm'>Cloud computing technology can increase your business scalability, security, and connectivity. Our mobile app development company incorporates cloud computing in the deployment of functional and responsive applications to carry out amazing programming.</p>
                                    <p className='text-sm'>To guarantee that everything from business applications to integrated software and IT infrastructure is current and operates effectively, we provide end-to-end cloud-managed services. For our clients throughout the world, we provide a full variety of cloud consultation services, including cloud deployment, custom development, data migration, cloud modernization, audit, and testing services.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='rounded-xl hover:cursor-grab'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={Artificial} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>AR/VR</h3>
                                    <p className='text-sm'>AR/VR apps have flooded the Google Play Store and Apple App Store with great response. Give your Gen-Z users an immersive experience with high-end AR/VR mobile app development services and create a new vision.</p>
                               <p className='text-sm'>Our experts can help you maximize the potential of cutting-edge technology like AR and VR. We thrive at providing the most dependable, cutting-edge, and next-generation AR/VR programs together with detailed animated graphics. We use the most advanced tools to deliver scalable AR/VR apps with comprehensive features. We can transform the experiences you envision for your app into reality.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className=' rounded-xl hover:cursor-grab'>
                            <div className='h-[700px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                <figure>
                                    <img src={Metaverse} alt="Business" />
                                </figure>
                                <div className='text-white space-y-1'>
                                    <h3 className='text-2xl font-black'>Metaverse</h3>
                                    <p className='text-sm'>The potential of metaverse applications in today's real world is completely endless. Hire experienced mobile app developers to build user-friendly, highly engaging, and intuitive metaverse applications with immersive virtual experiences. Without a flawless and powerful 3D visualization, no one would be compelled to indulge in the Metaserve.</p>
                                    <p className='text-sm'>Utilize our 3D modeling services for the metaverse to draw users to your virtual presence. To safely expand your business into the metaverse, get in touch with our metaverse experts. By incorporating our business-focused metaverse technologies, you may create limitless business opportunities and experience flawless growth.</p>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </>
    )
}
