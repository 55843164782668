import React, { useState } from 'react';
import ServicesInDubaiAndroid from 'assets/SVG/servicesInDubaiImage1.svg'
import ServicesInDubaiIOS from 'assets/SVG/servicesInDubaiImage2.svg'
import ServicesInDubaiFlutter from 'assets/SVG/servicesInDubaiImage3.svg'
import ServicesInDubaReact from 'assets/SVG/servicesInDubaiImage4.svg'
import ServicesInDubaPwd from 'assets/SVG/servicesInDubaiImage1 (2).svg'
import ServicesInDubaWeb from 'assets/SVG/servicesInDubaiImage6.svg'
import ServicesInDubaWearable from 'assets/SVG/servicesInDubaiIage1 (2).svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'component/carousel/Imagecarasol.css';

// import required modules
import { Autoplay } from 'swiper/modules';

const ServicesInDubai = () => {
    const [activeItem, setActiveItem] = useState(1)

    const handleMouseEnter = (index) => {
        if (activeItem !== index) {
            setActiveItem(index)
        }
    }

    return (
        <section className='container mx-auto'>
            {/* Android Development */}
            <div className='w-full h-full tablet:mb-[220px] sm:mb-[150px] mt-[70px]'>
                <div className='text-center lg:m sm:my-[1rem] text-dark' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                    <h1 className='lg:text-5xl sm:text-[25px] font-bold leading-tight mb-3'>SCube Software Development <span className='lg:block'>Services In Pakistan</span></h1>
                    <p className='tablet:text-xl sm:text-lg font-normal mb-5'>Leverage our decade-long extensive experience of creating high-performing, digitally <span className='md:block'>transformative, and feature-packed mobile apps.</span></p>
                </div>
                <div className='tablet:flex sm:hidden'>
                    <div className='w-[45%] z-20'>
                        <ul className='flex flex-col text-black gap-4' data-aos="fade-up" data-aos-duration="800">
                            <li className={`bg-white flex text-wrap items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 1 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(1)} >

                                <img src={ServicesInDubaiAndroid} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>
                                    Android Application Development</h2>
                            </li>

                            <li className={`bg-white flex text-wrap items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 2 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(2)}>

                                <img src={ServicesInDubaiIOS} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>

                                    iOS Application Development</h2>
                            </li>

                            <li className={`bg-white hover:bg-hovgra flex text-wrap  z-10 items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 3 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(3)}>
                                <img src={ServicesInDubaiFlutter} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>

                                    Flutter Application Development</h2>
                            </li>

                            <li className={`bg-white flex text-wrap  items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 4 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(4)}>
                                <img src={ServicesInDubaReact} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>

                                    React Native Application Development</h2>
                            </li>

                            <li className={`bg-white flex text-wrap  items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 5 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(5)}>
                                <img src={ServicesInDubaPwd} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>
                                    Progressive Web Development</h2>
                            </li>

                            <li className={`bg-white flex text-wrap  items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 6 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(6)}>
                                <img src={ServicesInDubaWeb} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>

                                    Wearable Application Development</h2>
                            </li>

                            <li className={`bg-white flex text-wrap  items-center gap-3 shadow-lg p-[25px] rounded-xl ${activeItem === 7 ? 'bg-hovgra' : ''}`}
                                onMouseEnter={() => handleMouseEnter(7)}>
                                <img src={ServicesInDubaWearable} alt='ServicesInDubai' />
                                <h2 className='text-3xl font-bold '>

                                    Web Application Development</h2>
                            </li>

                        </ul>
                    </div>
                    <div className='w-[55%] py-5' data-aos="zoom-in">
                        <div id='text-1' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 1 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px', }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className=' w-full h-full brightnessFilter' src={ServicesInDubaiAndroid} alt='Andriod' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>Android Application Development</h2>
                                <span className='text-md'>With comprehensive research and analysis, it is pretty evident that Android Application Development is leading the industry. As a #1 Android Mobile App Development Company Pakistan, SCube offers top-notch & tailored native app development services for distinctive Android devices.</span>
                            </div>
                        </div>

                        {/* 2 */}
                        <div id='text-2' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 2 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaiIOS} alt='IOS' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>iOS Application Development</h2>
                                <span className='text-md'>As a leading iOS Mobile App Development Company in Pakistan, SCube is building diversified iOS solutions that can not only help you lead the market but also ensure high ROI. With the latest technological upgrades, our team of technocrats knows how to uplift your business vision with an innovative solution.</span>
                            </div>
                        </div>

                        {/* 3 */}
                        <div id='text-3' className={`p-5 p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 3 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaiFlutter} alt='Flutter' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>Flutter Application Development</h2>
                                <span className='text-md'>Want to explore the perks of a future-ready Flutter Mobile App Development in Pakistan? Leverage services from SCube leading experts to access the best product market and then get going with designing, developing, testing, and delivering future-ready Flutter apps as per your specific business requirements.</span>
                            </div>
                        </div>

                        {/* 4 */}
                        <div id='text-4' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 4 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaReact} alt='React' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>React Native Application Development</h2>
                                <span className='text-md'>Target your potential audience with React Native Mobile App Development in Pakistan. SCube can prioritize your business needs by molding the app accordingly. Our experts are highly efficient in curating excellent React native apps, be it from scratch or adding a new framework to your existing application.</span>
                            </div>
                        </div>
                        {/* 5 */}
                        <div id='text-5' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 5 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaPwd} alt='Services in Dubai' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>Progressive Web Application Development</h2>
                                <span className='text-md'>As a top Progressive Mobile App Development Company in Pakistan, SCube can give your users the best of both web and mobile apps with extremely smooth animations and navigation. Our tech experts can develop the apps with special attention to providing the feel of a mobile application</span>
                            </div>
                        </div>
                        {/* 6 */}
                        <div id='text-6' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 6 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaWeb} alt='Web development' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>Wearable Application Development</h2>
                                <span className='text-md'>Get full advantage of Wearable App Development in Pakistan that not only delivers excellence but also helps to retain users. We optimize the intent of the user and get going with the app development accordingly. Whether it’s ideation, development, and the deployment of service our Pakistan app development.</span>
                            </div>
                        </div>
                        {/* 7 */}
                        <div id='text-7' className={`p-5 bg-dark -ml-10 rounded-3xl ${activeItem === 7 ? '' : 'hidden'}`} style={{ position: 'sticky', top: '100px' }}>
                            <div className='py-4 px-5 text-white space-y-10'>
                                <figure className='w-[13%] '>
                                    <img className='mb-4 w-full h-full brightnessFilter' src={ServicesInDubaWearable} alt='Services in Pakistan' />
                                </figure>
                                <h2 className='text-4xl font-bold mb-4'>Web Application Development</h2>
                                <span className='text-md'>Increase the profitability, availability, and efficiency of your business with Web App Development in Pakistan without affecting users’ app expectations. With explicit technologies and market analysis, we create web solutions that cater to cost efficiency, enhanced performance, and diversified growth potentials.</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Carasoul */}
                <div className='tablet:hidden sm:block'>
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 1700,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className="mySwiper "
                        
                    >
                        <SwiperSlide className='w-full'>
                            <div className="bg-dark rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaiAndroid} alt='Andriod' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>Android Application Development</h2>
                                    <span className='text-md'>With comprehensive research and analysis, it is pretty evident that Android Application Development is leading the industry. As a #1 Android Mobile App Development Company Pakistan, SCube offers top-notch & tailored native app development services for distinctive Android devices.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaiIOS} alt='IOS' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>IOS Application Development</h2>
                                    <span className='text-md'>As a leading iOS Mobile App Development Company in Pakistan, SCube is building diversified iOS solutions that can not only help you lead the market but also ensure high ROI. With the latest technological upgrades, our team of technocrats knows how to uplift your business vision with an innovative solution.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark  rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaiFlutter} alt='Flutter' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>Flutter Application Development</h2>
                                    <span className='text-md'>Want to explore the perks of a future-ready Flutter Mobile App Development in Pakistan? Leverage services from SCube leading experts to access the best product market and then get going with designing, developing, testing, and delivering future-ready Flutter apps as per your specific business requirements.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark  rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaReact} alt='React NAtive' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>React Native Application Development</h2>
                                    <span className='text-md'>Target your potential audience with React Native Mobile App Development in Pakistan. SCube can prioritize your business needs by molding the app accordingly. Our experts are highly efficient in curating excellent React native apps, be it from scratch or adding a new framework to your existing application.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaPwd} alt='PWD' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'> Progressive Web Application Development</h2>
                                    <span className='text-md'>As a top Progressive Mobile App Development Company in Pakistan, SCube can give your users the best of both web and mobile apps with extremely smooth animations and navigation. Our tech experts can develop the apps with special attention to providing the feel of a mobile application.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaWeb} alt='Web development' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>Wearable Application Development</h2>
                                    <span className='text-md'>Get full advantage of Wearable App Development in Pakistan that not only delivers excellence but also helps to retain users. We optimize the intent of the user and get going with the app development accordingly. Whether it’s ideation, development, and the deployment of service our Pakistan app development.</span>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='w-full'>
                            <div className="bg-dark  rounded-3xl">
                                <div className='p-4 text-white space-y-6'>
                                    <figure className=''>
                                        <img className='w-full h-full brightnessFilter' src={ServicesInDubaWearable} alt='Web development' />
                                    </figure>
                                    <h2 className='text-2xl font-bold mb-3'>Web Application Development</h2>
                                    <span className='text-md'>Increase the profitability, availability, and efficiency of your business with Web App Development in Pakistan without affecting users’ app expectations. With explicit technologies and market analysis, we create web solutions that cater to cost efficiency, enhanced performance, and diversified growth potentials.</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default ServicesInDubai;
