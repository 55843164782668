// background Images
import Bg1 from 'assets/png/portflio/bg/bg1.png'
import Bg2 from 'assets/png/portflio/bg/bg2.png'
import Bg3 from 'assets/png/portflio/bg/bg3.png'
import Bg4 from 'assets/png/portflio/bg/bg4.png'
import Bg5 from 'assets/png/portflio/bg/bg5.png'
import Bg6 from 'assets/png/portflio/bg/bg6.png'
import Bg7 from 'assets/png/portflio/bg/bg7.png'
import Bg8 from 'assets/png/portflio/bg/bg8.png'
import Bg9 from 'assets/png/portflio/bg/bg9.png'
import Bg10 from 'assets/png/portflio/bg/bg10.png'
import Bg11 from 'assets/png/portflio/bg/bg11.png'
import Bg12 from 'assets/png/portflio/bg/bg12.png'
import Bg13 from 'assets/png/portflio/bg/bg13.png'
import Bg14 from 'assets/png/portflio/bg/bg14.png'
import Bg15 from 'assets/png/portflio/bg/bg15.png'
import Bg16 from 'assets/png/portflio/bg/bg16.png'
import Bg17 from 'assets/png/portflio/bg/bg17.png'
import Bg18 from 'assets/png/portflio/bg/bg18.png'
import Bg19 from 'assets/png/portflio/bg/bg19.png'
import Bg20 from 'assets/png/portflio/bg/bg20.png'
import Bg21 from 'assets/png/portflio/bg/bg21.png'
import Bg22 from 'assets/png/portflio/bg/bg22.png'
import Bg23 from 'assets/png/portflio/bg/bg23.png'
// import Bg24 from 'assets/png/portflio/bg/bg24.png'
// import Bg25 from 'assets/png/portflio/bg/bg25.png'

// logos
import OpenBot from 'assets/png/portflio/logos/openbotsLogo.svg'
import Coversatelogo from 'assets/png/portflio/logos/ConversateAI.png'
import CarGuruLogo from 'assets/png/portflio/logos/800carguru.avif'
import DubaiPolice from 'assets/png/portflio/logos/Efaad - Dubai Police Students Platform.gif'
import RoomAtoZLogo from  'assets/png/portflio/logos/RoomAtoZLogo.svg'
import Alefeducation from  'assets/png/portflio/logos/AlefEducationLogo.svg'
import TrendiLogo  from  'assets/png/portflio/logos/Trendi_Logo.avif'
import PetstoreLogo  from  'assets/png/portflio/logos/The-Pet-Store-logo.avif'
import LiveliLogo  from  'assets/png/portflio/logos/Liveli-logo.svg'
import WakeletLogo  from  'assets/png/portflio/logos/wakeletlogo.webp'
import LDSLogo  from  'assets/png/portflio/logos/LDS-Logo.png'
import ECCCLogo from 'assets/png/portflio/logos/ECCC-Logo.png'
import IMCDOCLogo  from  'assets/png/portflio/logos/IMC-logo.webp'
import FloamLogo from 'assets/png/portflio/logos/Floam-logo.png'
import wisepelican  from  'assets/png/portflio/logos/wisePelican-logo.svg'
import Aliveprostudios  from  'assets/png/portflio/logos/AliveProStudios-Logo.svg'
import ListivaLogo from 'assets/png/portflio/logos/Listiva-logo.webp'
import EasyShop from 'assets/png/portflio/logos/EasyShop.webp'
import AllOutLogo  from  'assets/png/portflio/logos/AllOutLogo.webp'
import FarmDaylogo from 'assets/png/portflio/logos/FarmDaylogo.webp'
import OLELogo from 'assets/png/portflio/logos/OLELogo.webp'
import KloudKlubLogo from 'assets/png/portflio/logos/KloudklubLogo.webp'


// mobile image 
import OpenBootmobile from 'assets/png/portflio/mobileScreenshot/openBot-mobile.png'
import KloudKlubmobile from '../assets/png/portflio/mobileScreenshot/KloudKlub-mobile.png'
import Conversatemobile from '../assets/png/portflio/mobileScreenshot/ConversateAI-mobile.png';
import GuruMobile from '../assets/png/portflio/mobileScreenshot/guru800-mobile.png'
import DubaiPoliceMobile from '../assets/png/portflio/mobileScreenshot/DubaiPolice-mobile.png';
import RoomAtoZMobile from '../assets/png/portflio/mobileScreenshot/RoomAtoZ-mobile.png';
import AlefEducationMobile from '../assets/png/portflio/mobileScreenshot/alefeducation-mobile.jpeg';
import Petstoremobile from '../assets/png/portflio/mobileScreenshot/Petstore-mobile.png';
import LiveliMobile from '../assets/png/portflio/mobileScreenshot/Liveli-mobile.png';
import Trendimobile from '../assets/png/portflio/mobileScreenshot/Trendi-mobile.png';
import Wakeletmobile from '../assets/png/portflio/mobileScreenshot/Wakelet-mobile.png'
import AllOutmobile from '../assets/png/portflio/mobileScreenshot/AllOut-mobile.png';
import AliveProStudiiosmobile from '../assets/png/portflio/mobileScreenshot/AliveProStudiios-mobile.png';
import Listivamobile from '../assets/png/portflio/mobileScreenshot/Listiva-mobile.png'
import Floammobile from '../assets/png/portflio/mobileScreenshot/Floam-mobile.png'
import WisePelicanmobile from '../assets/png/portflio/mobileScreenshot/WisePelican-mobile.png'
import IMCDOCmobile from '../assets/png/portflio/mobileScreenshot/IMCDoc-mobile.png';
import ECCCmobile from '../assets/png/portflio/mobileScreenshot/ECCC-mobile.png';
import OLEmobile from '../assets/png/portflio/mobileScreenshot/OLE-mobile.png'
import FarmDayVillegemobile from '../assets/png/portflio/mobileScreenshot/FarmDayVillege-mobile.png'
import LDSmobile from "../assets/png/portflio/mobileScreenshot/LDS-mobile.png"
import EasyShopmobile from '../assets/png/portflio/mobileScreenshot/EasyShop-mobile.png'
const PortfolioModal = [
    {
        // Automation
        id: 1,
        type:"automation",
        titleImg: OpenBot,
        title: 'OpenBots',
        description: `Business automation platform to include all the automation tools in a single bundle conveniently; Intelligent Document Processing (IDP) with Generative AI, Robotic Process Automation (RPA), BPO services, and a business center for non- developers.`,
        image: Bg1,
        downloads: "",
        platform: "Web",
        mobileImg: OpenBootmobile,
        titleColor: "#5245AD",
        weblink:"https://openbots.ai/",
        androidlink:"",
        iOSlink:"",
    },
      {
        // Communication
        id: 2,
        type:"communication",
        titleImg: KloudKlubLogo,
        title: 'Kloud Klub',
        description: `Business automation platform to include all the automation tools in a single bundle conveniently; Intelligent Document Processing (IDP) with Generative AI, Robotic Process Automation (RPA), BPO services, and a business center for non- developers.`,
        image: Bg2,
        downloads: "10+",
        platform: "Android,Web and iOS",
        mobileImg: KloudKlubmobile,
        titleColor: "#009EF7",
        weblink:"https://kloudklub.com/",
        androidlink:"https://play.google.com/store/apps/details?id=com.kloudklub.chat",
        iOSlink:"https://apps.apple.com/us/app/kloud- klub-chat/id6446267589",
    },
    {
        id: 3,
        type:"communication",
        titleImg: Coversatelogo,
        title: 'Conversate',
        description: `Extend the capability of the software using Conversate’s webhhooks and Zapier API integration. Conversate natively integrates with workflow management systems like Slack. Manage your conversations without logging into the Conversate dashboard. Improve your work flow and your customer service ratings by having one space to manage all forms of communication with your clients.`,
        image: Bg3,
        downloads: "",
        platform: "Web",
        mobileImg: Conversatemobile,
        titleColor: "#009EF7",
        weblink:"https://conversate.us/",
        androidlink:"",
        iOSlink:"",
    },
       // Car Rental
       {
        id: 4,
        type:"carRental",
        titleImg: CarGuruLogo,
        title: '800CarGuru',
        description: `Extend the capability of the software using Conversate’s webhhooks and Zapier API integration. Conversate natively integrates with workflow management systems like Slack. Manage your conversations without logging into the Conversate dashboard. Improve your work flow and your customer service ratings by having one space to manage all forms of communication with your clients.`,
        image: Bg4,
        downloads: "",
        platform: "Web",
        mobileImg: GuruMobile,
        titleColor: "#669a01",
        weblink:"https://800carguru.ae/",
        androidlink:"",
        iOSlink:"",
    },
     // Education
     {
        id: 5,
        type:"education",
        titleImg: DubaiPolice,
        title: 'Efaad - Dubai Police Students Platform',
        description: `Dubai Police supports its cadets and officers to achieve their academic goals and have a lasting successful career path in the future.`,
        image: Bg5,
        downloads: "10+",
        platform: "Web",
        web:"Web",
        mobileImg: DubaiPoliceMobile,
        titleColor: "#2D9C72",
        weblink:"https://efaad.dubaipolice.gov.ae/Efaad/%23/",
        androidlink:"",
        iOSlink:"",
    },
    {
        id: 6,
        type:"education",
        titleImg: RoomAtoZLogo,
        title: 'RoomAtoZ',
        description: `Online “One-on-One” educational platform that is beneficial for both students and tutors.`,
        image: Bg6,
        downloads: "10+",
        platform: "Web",
        mobileImg: RoomAtoZMobile,
        titleColor: "#6BD700",
        weblink:"https://roomatoz.com/",
        androidlink:"",
        iOSlink:"",
    },
    {
        id: 7,
        type:"education",
        titleImg: Alefeducation,
        title: 'Alef education',
        description: `Education redefined — where every learner and educator fulfills their unique potential. Making education accessible to all and improving outcomes through AI-powered personalized learning and the latest pedagogical frameworks rooted in data science.`,
        image: Bg7,
        downloads: "10+",
        platform: "Web",
        mobileImg:AlefEducationMobile,
        titleColor: "#0A9576",
        weblink:"https://www.alefeducation.com/",
        androidlink:"",
        iOSlink:"",
    },
    // Ecommerce App
{
    id: 8,
    type:"ecommerceApp",
    titleImg: TrendiLogo,
    title: 'TrendiIQ',
    description: `LiveLi is a Social Networking and Entertainment HuStep into style and sophistication with our premier online destination for shoes and clothing. At our ecommerce website, we curate a diverse selection of the latest fashion trends, blending timeless elegance with contemporary flair.`,
    image: Bg8,
    downloads: "",
    platform: "Web",
    mobileImg: Trendimobile,
    titleColor: "#AD8E9C",
    weblink:"https://trendi-iq.com/",
    androidlink:"",
    iOSlink:"",
},
{
    id: 9,
    type:"ecommerceApp",
    titleImg: PetstoreLogo,
    title: 'PetStore',
    description: `PetStore provides the perfect spot for pets and home reptiles the services and treatment they deserves.`,
    image: Bg9,
    downloads: "",
    platform: "Web",
    web:"Web",
    mobileImg: Petstoremobile,
    titleColor: "#00A6B5",
    weblink:"https://thepetstore.ae/",
    androidlink:"",
    iOSlink:"",
},
{
    id: 10,
    type:"ecommerceApp",
    titleImg: PetstoreLogo,
    title: 'The PetShop',
    description: `PetStore provides the perfect spot for pets and home reptiles the services and treatment they deserves.`,
    image: Bg10,
    downloads: "10+",
    platform: "Android and iOS",
    mobileImg: Petstoremobile,
    titleColor: "#00A6B5",
    weblink:"",
    androidlink:"https://play.google.com/store/apps/details?id=com.builtinsoft.thepetstore",
    iOSlink:" https://apps.apple.com/us/app/the-pet-store/id1475137339",
},
{
    id: 11,
    type:"ecommerceApp",
    titleImg: EasyShop,
    title: 'Easy Shop',
    description: `Easy and secure platform for people to discover and shop the products they love. With fast delivery, easy payment and return options and a 24-hour customer service, find everything you need at competitive prices.`,
    image: Bg11,
    downloads: "10+",
    platform: "Android and iOS",
    web:"Web",
    mobileImg: EasyShopmobile,
    titleColor: "#1A6BA4",
    weblink:"",
    androidlink:"https://play.google.com/store/apps/details?id=se.datema.EasyShop&hl=en&gl=US",
    iOSlink:"https://apps.apple.com/pk/app/easy- shop-online/id1612641277",
},
// Entertainment
{
    id: 12,
    type:"entertainment",
    titleImg: LiveliLogo,
    title: 'Liveli',
    description: `LiveLi is a Social Networking and Entertainment Hub. LiveLi Showcases users Lifestyle, Entertainment, talents and Social life. We Connects users to real life fans, family & friends. Users can earn money from creating lively experiences.`,
    image: Bg12,
    downloads: "10+",
    platform: "Android,Web and iOS",
    web:"Web",
    mobileImg: LiveliMobile,
    titleColor: "#311653",
    weblink:"https://liveli.app/",
    androidlink:"https://play.google.com/store/apps/details?id=com.it.liveli&hl=en",
    iOSlink:"https://apps.apple.com/us/app/liveli/id1523495613",
},
{
    id: 13,
    type:"entertainment",
    titleImg: WakeletLogo,
    title: 'Wakelet',
    description: `Save any kind of content into beautiful collections you can share with your students, your learning communities, and the world! Easy and enjoyable way to save, organize and share content from across the web.`,
    image: Bg13,
    downloads: "10+",
    platform: "Android,Web and iOS",
    mobileImg: Wakeletmobile,
    titleColor: "#017EC7",
    weblink:"https://www.wakelet.com/",
    androidlink:"https://play.google.com/store/apps/details?id=com.wakelet.wakelet&hl=en",
    iOSlink:"https://apps.apple.com/gb/app/wakelet/id1041331738 ",
},
// Finance
{
    id: 14,
    type:"finance",
    titleImg: LDSLogo,
    title: 'LDS',
    description: `Making it easy to keep track of your tithing and donations for single users and family’s.`,
    image: Bg14,
    downloads: "10+",
    platform: "Android,Web and iOS",
    mobileImg: LDSmobile,
    titleColor: "#6328E6",
    weblink:"https://ldstithingreport.com/",
    androidlink:"https://apps.apple.com/us/app/lds-tithing-report/id1235742891?ls=1",
    iOSlink:" https://play.google. com/store/apps/details?id=com.dpk. ldstithingreport",
},
// Gaming
{
    id: 15,
    type:"gaming",
    titleImg: OLELogo,
    title: 'OLE App',
    description: `- This app has open matches for challenges as you can enjoy the challenge and face them with other players.Players can select their preferred referees and set their matches. They can book their choice of ground.`,
    image: Bg15,
    downloads: "10+",
    platform: "Android and iOS",
    web:"Web",
    mobileImg: OLEmobile,
    titleColor: "#05608B",
    weblink:"",
    androidlink:"https://play.google.com/store/apps/details?id=ae.oleapp",
    iOSlink:"https://apps.apple.com/ae/app/ole/id1472879986",
},
{
    id: 16,
    type:"gaming",
    titleImg: FarmDaylogo,
    title: 'Farm Day Village',
    // description: `Save any kind of content into beautiful collections you can share with your students, your learning communities, and the world! Easy and enjoyable way to save, organize and share content from across the web.`,
    image: Bg16,
    downloads: "10+",
    platform: "Android,Web and iOS",
    web:"Web",
    mobileImg: FarmDayVillegemobile,
    titleColor: "#669a01",
    weblink:"https://farmdayvillage.com/",
    androidlink:"https://play.google.com/store/apps/details?id=com.bag.farm.day.village.farming.games",
    iOSlink:"https://apps.apple.com/us/app/farm- day-village-offline-games/id1409676937",

},
    // Healthcare
    {
        id: 17,
        type:"healthcare",
        titleImg: ECCCLogo,
        title: 'ECCC',
        description: `A health care application that can proper you the better knowledge of the medical events held around the year. This helps the citizens to be aware of the national-level health conferences and events.`,
        image: Bg17,
        downloads: "10+",
        platform: "Android,Web and iOS",
        web:"Web",
        mobileImg: ECCCmobile,
        titleColor: "#7E92A6",
        weblink:"https://eccc-dubai.com/",
        androidlink:"",
        iOSlink:"",
    },
    {
        id: 18,
        type:"healthcare",
        titleImg: IMCDOCLogo,
        title: 'IMC DOC',
        description: `Extend the capability of the software using Conversate’s webhhooks and Zapier API integration. Conversate natively integrates with workflow management systems like Slack. Manage your conversations without logging into the Conversate dashboard. Improve your work flow and your customer service ratings by having one space to manage all forms of communication with your clients.`,
        image: Bg18,
        downloads: "10+",
        platform: "Android",
        web:"Web",
        mobileImg: IMCDOCmobile,
        titleColor: "#005289",
        weblink:"",
        androidlink:"https://play.google.com/store/apps/details?id=sa.med.imc.doctorapp",
        iOSlink:"",
    },

// Music
{
    id: 19,
    type:"music",
    titleImg: FloamLogo,
    title: 'Floam',
    description: `In the symphony of online music exploration, floam.co emerges as a vibrant note, resonating with creativity and community. Like a conductor guiding an orchestra, Floam orchestrates a harmonious blend of sounds and styles, offering a platform for music enthusiasts to immerse themselves in a rich tapestry of tunes. From indie melodies to electronic beats, Floam's virtual stage is a playground where artists and listeners converge, sharing their passions and discoveries. With each click and scroll, users embark on a sonic journey, navigating through curated playlists and discovering hidden gems. `,
    image: Bg19,
    downloads: "",
    platform: "Web",
    web:"Web",
    mobileImg: Floammobile,
    titleColor: "#08357F",
    weblink:"https://floam.co/",
    androidlink:"",
    iOSlink:"",
},
// Marketting
{
    id: 20,
    type:"marketting",
    titleImg: wisepelican,
    title: 'Wise Pelican',
    description: `Make Direct Mail Marketing as simple and straightforward as possible for our customers.`,
    image: Bg20,
    downloads: "",
    platform: "Web",
    web:"Web",
    mobileImg: WisePelicanmobile,
    titleColor: "#4A1442",
    weblink:"https://wisepelican.com/",
    androidlink:"",
    iOSlink:" ",
},
{
    id: 21,
    type:"marketting",
    titleImg: Aliveprostudios,
    title: 'Alive ProStudios',
    description: `Create or enhance strategies, brands, UVPs [unique value propositions], products, communications, tools, tactics, systems,    and company cultures to acquire customers and support growth.`,
    image: Bg21,
    downloads: "",
    platform: "Web",
    web:"Web",
    mobileImg: AliveProStudiiosmobile,
    titleColor: "#191C19",
    weblink:"https://aliveprostudios.com/",
    androidlink:"",
    iOSlink:" ",
},
// Real Estate
{
    id: 22,
    type:"realEstate",
    titleImg: ListivaLogo,
    title: 'Listiva',
    description: `Goal to offer users a tool that is up to date data that will make it simple for you to search for your next deal. With Listiva what you are getting is the experience of using a system that is used by real estate heavy hitters who have built a massive real estate empire by using niche data. This is the niche data that top performers utilize.`,
    image: Bg22,
    downloads: "",
    platform: "Web",
    web:"Web",
    mobileImg: Listivamobile,
    titleColor: "#427DA5",
    weblink:"https://listiva.com/",
    androidlink:"",
    iOSlink:" ",
},
// travel and Tourism 
{
    id: 23,
    type:"travelAndTourism",
    titleImg: AllOutLogo,
    title: 'All Out:  Trips, Adventure in UAE',
    description: `For zero interruptions and non-stop enjoyment users have an easy and smooth step-by-step guide to reserve their spots at their fun places.Users can book grounds, courts, and yachts for their loved ones for privacy ahead of time.`,
    image: Bg23,
    downloads: "10+",
    platform: "Android,Web and iOS",
    web:"Web",
    mobileImg: AllOutmobile,
    titleColor: "#5AB2EF",
    weblink:"https://allout.ae/",
    androidlink:"https://play.google.com/store/apps/details?id=com.AllOut.app",
    iOSlink:"https://apps.apple.com/pk/app/all-out-trips-adventures-uae/id1670310616",
},
]
export default PortfolioModal;