import React from 'react';
import ApptunixCards from 'service/DashboardModules';
import PalmTreeFirst from 'assets/SVG/Palm-Tree-left.svg'
import PalmTreeSecond from 'assets/SVG/skankcnskc1 (12).svg'

const ChooseUsCards = () => {
  return (
    <div>
      <h1 data-aos="fade-up" className='sm:text-[20px] md:text-[48px] text-center p-4 font-bold text-black'>Why Choose SCube?</h1>
            <ul className='container w-[100%] mx-auto grid lg:grid-cols-4 tablet:grid-cols-2 md:grid-cols-3 sm:grid-cols-1 gap-3 custom-transition'>
                {ApptunixCards.map((item, index) => (
                    <li key={index} data-aos="fade-up" data-aos-delay="400" className='rounded-xl md:p-4 sm:p-2 sm:pb-1 tablet:pb-5   hover:bg-hovgra shadow-md border-opacity-25 '>
                        <figure className='flex sm:flex-row tablet:flex-col md:justify-center tablet:items-start sm:items-center sm:p-3 sm:gap-4'>
                            <img className='tablet:pb-[25px]' src={item.cardImage} alt='Technology Stack' />
                            <figcaption>
                                <h3 className='sm:text-sm sm:font-[500] tablet:text-[18px] tablet:font-[630] text-black lg:font-[700] leading-7'>{item.detail}</h3>
                            </figcaption>
                        </figure>
                    </li>
                ))}
            </ul>
            <div className='flex justify-between items-end overflow-hidden'>
                <figure className='max-w-[30%]'>
                    <img className='-ml-7 -mb-6 w-full' src={PalmTreeFirst} alt='Palm Tree' />
                </figure>
                <figure className='max-w-[20%]'>
                    <img className='-mb-2 w-full' src={PalmTreeSecond} alt='Palm Tree' />
                </figure>
            </div>
    </div>
  );
}

export default ChooseUsCards;
