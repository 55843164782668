import React from 'react';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import spinner from 'assets/Images/spinner.svg'
import CloseIcon from '@mui/icons-material/Close';
import { useContactFormContext } from '../service/ContactFormContext';

export default function EngagementModel({ onClose }) {

  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    subject,
    setSubject,
    description,
    setDescription,
    loading,
    error,
    handleSubmit,
  } = useContactFormContext();

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-dark bg-opacity-75">
      <div className="md:w-[65%] tablet:w-[55%] h-[90%] bg-formBg rounded-3xl mx-3 flex justify-center m-auto">
        <button className='absolute lg:right-[215px] sm:right-2 tablet:right-[160px]  top-3 w-10 h-10 rounded-full bg-formBg hover:bg-danger hover:text-white flex items-center justify-center'
          onClick={onClose}>
          <CloseIcon /></button>
        <div className="flex sm:flex-col tablet:flex-row justify-center">
          <div className="bg-engagementModalBgImage bg-cover bg-no-repeat rounded-l-3xl text-white tablet:w-1/2  flex flex-col gap-4 px-7 py-5">
            <p className="sm:text-2xl tablet:text-3xl md:text-4xl font-bold ">Let’s Build Your Dream App Together!</p>
            <p className="tablet:text-xl">Take a Step Forward to Bring Your Ideas to Life</p>
          </div>
          <div className="tablet:w-1/2 h-full">
            <form onSubmit={handleSubmit} className="flex flex-col gap-[11px] px-[20px] py-[45px]">
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                className="border-[1px] border-solid border-text md:h-12 sm:h-8 px-2 w-full text-base shadow-none rounded-lg border-[1px] focus:border-primary focus:outline-none"
              />
              {error.name && <h1 className='text-danger text-xs'>{error.name}</h1>}
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="border-[1px] border-solid border-text md:h-12 sm:h-8 px-2 w-full text-base shadow-none rounded-lg border-[1px] focus:border-primary focus:outline-none"
              />
              {error.email && <h1 className='text-danger text-xs'>{error.email}</h1>}
              <input type='tel'
                name='phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)} pattern='^\+?[0-9\-]*'
                placeholder='Phone Number' autoComplete='off'
                title='Please enter a valid phone number (only digits allowed)'
                className="border-[1px] border-solid border-text md:h-12 sm:h-8 px-2 w-full text-base shadow-none rounded-lg border-[1px] focus:border-primary focus:outline-none"
              />
              {error.phone && <h1 className='text-danger text-xs'>{error.phone}</h1>}
              <input
                type="text"
                name="subject"
                value={subject}
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                className="border-[1px] border-solid border-text md:h-12 sm:h-8 px-2 w-full text-base shadow-none rounded-lg border-[1px] focus:border-primary focus:outline-none"
              />
              {error.subject && <h1 className='text-danger text-xs '>{error.subject}</h1>}

              <textarea
                rows={5}
                placeholder="About Project text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border-[1px] border-solid border-text px-2 w-full text-base shadow-none rounded-lg border-[1px] focus:border-primary focus:outline-none"
              />
              {error.description && <h1 className='text-danger text-xs'>{error.description}</h1>}
              <div>
                <button className="bg-gradiBg hover:bg-primary rounded-lg px-3 py-2 text-white font-semibold text-xl">
                  {loading ?
                    <span className='flex items-center justify-center p-0 m-0'>
                      <span>Submit</span>
                      <img className='h-8 w-10' src={spinner} alt="Loading...." />
                    </span>
                    :
                    <span>Submit <EastSharpIcon /></span>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
