import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import backGroundImageAwardsBg from 'assets/Images/awards_bg.jpg'
import awardMan from 'assets/Images/awrd_mn.png'
import { AchivementsFirstSection, AchivementsSecondSection } from 'service/DashboardModules';
const RecentAchivements = () => {

const [achivementIsShow, setAchivementIsShow] = useState(true)

useEffect(() => {
  AOS.init({
      duration: 1000,
      once: true
  });
}, []);

  return (
    <div className='bg-cover bg-no-repeat bg-center h-full z-20' style={{ backgroundImage: `url(${backGroundImageAwardsBg})` }} >
      <div className='sm:py-16 lg:py-0'>
        <div data-aos ="fade-up" className='text-center text-white mb-5 lg:pt-10'>
          <h1 className='lg:text-5xl tablet:text-4xl sm:text-2xl font-bold mb-3'>Our Recent Achievements</h1>
          <p className='text-lg'>An opportunity in Pakistan to get a competitive edge with our <span className='tablet:block'> innovation & excellence.</span>
          </p>
        </div>

        <div className='container mx-auto flex sm:flex-col lg:flex-row  min-h-screen w-full'>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='sm:text-start cursor-pointer lg:text-7xl tablet:text-3xl sm:text-xl font-bold lg:w-1/2 flex sm:flex-col tablet:flex-row lg:flex-col tablet:justify-center lg:justify-start lg:gap-10 sm:gap-4 sm:py-8 lg:py-0' >
            <h1 className={achivementIsShow ? "text-white" : "text-text"} onMouseOver={() => setAchivementIsShow(true)}>Awards & <span className='lg:block'>Recognition</span></h1>
            <h1 className={!achivementIsShow ? "text-white" : "text-text"} onMouseOver={() => setAchivementIsShow(false)}>Certifications</h1>
            <figure className='sm:hidden lg:block'>
              <img src={awardMan} alt='Award man' />
            </figure>
          </div>

          <div className='flex items-start justify-stretch sm:w-full lg:w-1/2'>
            {achivementIsShow ?
              <div className='grid tablet:grid-cols-3 sm:grid-cols-2 gap-3 text-center '>
                {AchivementsFirstSection.map((item, index) => (
                  <div data-aos="fade-up" data-aos-anchor-placement="center-bottom"  key={index} className='w-[300px] max-w-full flex flex-col items-center justify-center bg-white rounded-3xl py-[20px] px-[10px]'>
                    <figure>
                      <img className='max-h-[120px]' src={item.achivementCard} alt='Card' />
                    </figure>
                    <p className='text-base font-bold m-2'>{item.tittle}</p>
                    <p className='tablet:text-md sm:text-sm'>{item.description}</p>
                  </div>
                ))}
              </div>
              :
              <div className='grid tablet:grid-cols-3 sm:grid-cols-2 gap-3 text-center bg-black-500'>
                {AchivementsSecondSection.map((item, index) => (
                  <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" key={index} className='w-[300px] text-balance max-w-full flex flex-col items-center justify-center bg-white rounded-3xl py-[20px] px-[10px]'>
                    <figure>
                      <img className='max-h-[120px]' src={item.achivementCard} alt='Card' />
                    </figure>
                    <p className='text-base font-bold m-2'>{item.tittle}</p>
                    <p className='tablet:text-md sm:text-sm'>{item.description}</p>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentAchivements;
