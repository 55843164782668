import React, { useState } from 'react';
import CTAfirstImage from 'assets/Images/CTACard1.png'
import industriesCatterLogo1 from 'assets/SVG/Industries-catter1.svg'
import industriesCatterLogo2 from 'assets/SVG/Industries-catter2.svg'
import industriesCatterLogo3 from 'assets/SVG/Industries-catter3.svg'
import industriesCatterLogo4 from 'assets/SVG/Industries-catter4.svg'
import industriesCatterLogo5 from 'assets/SVG/Industries-catter5.svg'
import industriesCatterLogo6 from 'assets/SVG/Industries-catter6.svg'
import industriesCatterLogo7 from 'assets/SVG/Industries-catterTravel.svg'
import industriesCatterLogo8 from 'assets/SVG/Industries-catterSport.svg'
import industriesCatterLogo9 from 'assets/SVG/Industries-catterDating.svg'
import industriesCatterLogo10 from 'assets/SVG/Industries-catterCommerce.svg'
import industriesCatterLogo11 from 'assets/SVG/Industries-catterLogistic.svg'
import industriesCatterLogo12 from 'assets/SVG/Industries-catterRealState.svg'
import industriesCatterLogo13 from 'assets/SVG/Industries-catterFitness.svg'

import industriesCatterImage1 from 'assets/Images/Industries-catter10.png'
import industriesCatterImage3 from 'assets/Images/Industries-catter12.png'
import industriesCatterImage4 from 'assets/Images/Industries-catter13.png'
import industriesCatterImage5 from 'assets/Images/Industries-catter14.png'
import industriesCatterImage6 from 'assets/Images/Industries-catter01.png'
import industriesCatterImage7 from 'assets/Images/Industries-catter02.png'
import industriesCatterImage8 from 'assets/Images/Industries-catter03.png'
import industriesCatterImage9 from 'assets/Images/Industries-catter04.png'
import industriesCatterImage10 from 'assets/Images/Industries-catter05.png'
import industriesCatterImage11 from 'assets/Images/Industries-catter06.png'
import industriesCatterImage12 from 'assets/Images/Industries-catter07.png'
import industriesCatterImage13 from 'assets/Images/Industries-catter08.png'
import CircleIcon from '@mui/icons-material/Circle';
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';

import 'swiper/css';
import 'component/carousel/Imagecarasol.css'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const IndustriesWeCatter = () => {
    const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);

    const [activeItem, setActiveItem] = useState(1)
    const handleMouseHover = (index) => {
        if (activeItem !== index) {
            setActiveItem(index)
        }
    }

    return (
        <div className='bg-[#002033]'>
            <div className='container mx-auto'>
                <div className='relative text-white'>
                    {/* FirstImage */}
                    <div className='absolute -top-[150px] flex w-full tablet:h-[300px] sm:h-[250px] bg-secondaryBg rounded-3xl z-20' >
                        <figure data-aos="zoom-in-up" className='absolute -top-35 right-14 bottom-0 sm:hidden md:block'>
                            <img src={CTAfirstImage} alt='First' className='h-[420px] w-auto' />
                        </figure>
                        <div className='flex flex-col gap-4 justify-center py-5 lg:pl-10 sm:p-5 lg:p-0 text-black sm:text-center lg:text-start rounded-3xl sm:w-full lg:bg-transparent w-full overflow-hidden'>
                            <h1 data-aos="fade-left" className='lg:text-4xl sm:text-2xl font-bold w-full leading-relaxed text-white' style={{ lineHeight: '1.4' }}>Hire a<span className='lg:block'> Best Software Development <span className='lg:block'>Company in <span className='text-green-400'>Pakistan.</span></span></span></h1>
                            <div>
                                <button className=' p-[12px] font-semibold rounded-lg bg-whiteBg hover:text-white hover:bg-primaryBg transition-all duration-500 ease-in-out'
                                    onClick={() => setEngagementModelOpen(true)}>
                                    Schedule a free consultation<span><EastSharpIcon /></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Industries we catter section */}
                    <div className='relative w-full'>
                        <div className='flex flex-col items-center justify-center'>
                            <div data-aos="fade-up" data-aos-duration="1500" className='tablet:max-w-[65%] text-center lg:mt-[240px] sm:mt-[180px]'>
                                <h1 className='lg:text-5xl sm:text-[25px] tablet:text-2xl font-bold mb-3'>Industries We Cater To</h1>
                                <p className='tablet:text-lg sm:text-md'>With more than 1000 Affiliate Partners, 200+ Tech Partners, and 150+ Solution Partners, we are a house to certified native and cross-platform mobile app developers, designers, and other experts in <span className='text-green-500'>Pakistan</span>.</p>
                            </div>
                        </div>
                        {/* Scroling cards */}
                        <div className='tablet:block sm:hidden'>
                            <div className='pb-[100px] pt-5 w-full flex justify-between before:absolute before:left-[65px] before:bottom-[0px]  before:bg-gray-500 before:bg-opacity-50 before:h-[100%] before:w-[2px] before:content-[""] z-10'>
                                <div data-aos="fade-up" data-aos-duration="1500" className='w-[32%]'>
                                    <ul className='flex flex-col gap-4'>
                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center justify-between rounded-xl custom-transition ${activeItem === 1 ? 'bg-white text-[#EE386B]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(1)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>
                                                On-demand App solution
                                            </p>
                                        </li>
                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 2 ? 'bg-white text-[#00ce00]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(2)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>
                                                Healthcare Services
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 3 ? 'bg-white text-[#7441ff]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(3)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Social Networking
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 4 ? 'bg-white text-[#318cff]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(4)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Education
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 5 ? 'bg-white text-[#ccbe00]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(5)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Video Streaming
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 6 ? 'bg-white text-[#ff4a4a]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(6)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'> Fitness
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 7 ? 'bg-white text-[#bd46ff]' : 'text-text '}`}
                                            onMouseEnter={() => handleMouseHover(7)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Travel
                                            </p>
                                        </li>


                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 8 ? 'bg-white text-[#06b5db]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(8)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Sports Betting
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 9 ? 'bg-white text-[#d67300]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(9)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Dating
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 10 ? 'bg-white text-[#00ce00]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(10)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'> E-Commerce
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 11 ? 'bg-white text-[#EE386B]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(11)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Logistics
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 12 ? 'bg-white text-[#00ce00]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(12)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'> Real Estate
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 13 ? 'bg-white text-[#7441ff]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(13)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Fintech
                                            </p>
                                        </li>

                                        <li className={`relative bg-tranparent py-3 px-5 h-[90px] flex items-center rounded-xl custom-transition ${activeItem === 14 ? 'bg-white text-[#318cff]' : 'text-text'}`}
                                            onMouseEnter={() => handleMouseHover(14)}>
                                            <span><CircleIcon fontSize='large' /></span>
                                            <p className='text-xl font-bold pl-10'>Construction
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="700" className='w-[65%]'>
                                    <div id='section1' className={`sticky top-10 ${activeItem === 1 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#FFC7DC] px-5 py-[40px] rounded-xl flex flex-col items-start gap-3'>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo2} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>On-demand App solution</h1>
                                            <p className='text-xl w-5/6'>Choose the right tech partner to create your advanced app and add great value to your on-demand business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage1} alt='Industries we are catter' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* second-section */}
                                    <div id='section1' className={`sticky top-10 ${activeItem === 2 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#b4ffb4] px-5 py-[40px] rounded-xl flex flex-col items-start gap-3'>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo3} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Healthcare Services</h1>
                                            <p className='text-xl w-5/6'>Bringing innovation to fill the gap between medical professionals & patients with our state-of-the-art technology.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage1} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>

                                    {/* 3rd-section */}
                                    <div id='section3' className={`sticky top-10 ${activeItem === 3 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#d6c7ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo4} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Social Networking</h1>
                                            <p className='text-xl'>We empower your social networking approach with our strongly built digital solutions and complete tech stack.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage3} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>

                                    {/* 4th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 4 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#b4d5ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo5} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Education</h1>
                                            <p className='text-xl'>Uplifting Edtech startups with a blend of comprehensive learning & educational mobility operations.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage4} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>

                                    {/* 5th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 5 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#fffbc7] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo6} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Video Streaming</h1>
                                            <p className='text-xl'>Leverage our proficiency in delivering world-class entertaining platforms & build fantastic video streaming apps.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage5} alt='Video Streaming' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 6th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 6 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#ffc7c7] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo1} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Fitness</h1>
                                            <p className='text-xl'>Discover new growth opportunities with a customizable & robust solution that serves every fitness business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage6} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 7th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 7 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#ebc7ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo7} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Travel</h1>
                                            <p className='text-xl'>Effortlessly integrate your travel business with a high-powered management app & streamline business operations.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage7} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 8th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 8 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#c7f5ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo8} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Sports Betting</h1>
                                            <p className='text-xl'>Give your user a great experience and each minute updates from anywhere with our feature-rich app development.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage8} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 9th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 9 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#ffe5c7] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo9} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Dating</h1>
                                            <p className='text-xl'>We help you launch a tailor-made engaging and interactive dating app that helps your customers find their love.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage9} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 10th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 10 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#ddffc7] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo10} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>E-Commerce</h1>
                                            <p className='text-xl'>Skyrocket your e-commerce marketplace sales and reach the maximum audience with our user-friendly app solutions.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage10} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 11th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 11 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#b4ffb4] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo11} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Logistics</h1>
                                            <p className='text-xl'>Logistics app development simplifies your business processes & minimizes functional hurdles to boost efficiency.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage11} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 12th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 12 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#b4ffb4] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo12} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Real Estate</h1>
                                            <p className='text-xl'>Give convenient services to your customers to buy, sell, or rent properties in Pakistan with real estate app development solutions.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage12} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 13th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 13 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#d6c7ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo13} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Fintech</h1>
                                            <p className='text-xl'>Accelerate digital innovation with our full-stack, resilient, and scalable fintech software development services in Pakistan.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage13} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                    {/* 14th-section */}
                                    <div id='section4' className={`sticky top-10 ${activeItem === 14 ? '' : 'hidden'}`}>
                                        <div className='text-black bg-[#b4d5ff] p-5 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo12} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-3xl font-semibold'>Construction</h1>
                                            <p className='text-xl'>100% customizable and world-class construction software solutions in Pakistan that fully cater to the needs of your business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage13} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Carasoul */}
                        <div className='tablet:hidden sm:block mt-4'>
                            <Swiper
                                slidesPerView={1}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 1500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                                className="mySwiper "
                                style={
                                    { paddingBottom: '10%' }
                                }
                            >
                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#FFC7DC] p-3 rounded-xl flex flex-col items-start gap-3'>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo2} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>On-demand App solution</h1>
                                            <p className='text-xl w-5/6'>Choose the right tech partner to create your advanced app and add great value to your on-demand business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage1} alt='Industries we are catter' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#b4ffb4] p-3 rounded-xl flex flex-col items-start gap-3'>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo3} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Healthcare Services</h1>
                                            <p className='text-xl w-5/6'>Bringing innovation to fill the gap between medical professionals & patients with our state-of-the-art technology.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage1} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#d6c7ff] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo4} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Social Networking</h1>
                                            <p className='text-xl'>We empower your social networking approach with our strongly built digital solutions and complete tech stack.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage3} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#b4d5ff] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo5} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Education</h1>
                                            <p className='text-xl'>Uplifting Edtech startups with a blend of comprehensive learning & educational mobility operations.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage4} alt='Industries Catter' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#fffbc7] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo6} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Video Streaming</h1>
                                            <p className='text-xl'>Leverage our proficiency in delivering world-class entertaining platforms & build fantastic video streaming apps.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage5} alt='Video Streaming' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#ffc7c7] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo1} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Fitness</h1>
                                            <p className='text-xl'>Discover new growth opportunities with a customizable & robust solution that serves every fitness business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage6} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#ebc7ff] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo7} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Travel</h1>
                                            <p className='text-xl'>Effortlessly integrate your travel business with a high-powered management app & streamline business operations.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage7} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#c7f5ff] p-3 rounded-xl flex flex-col items-start gap-2'>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo8} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Sports Betting</h1>
                                            <p className='text-xl'>Give your user a great experience and each minute updates from anywhere with our feature-rich app development.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage8} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#ffe5c7] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo9} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Dating</h1>
                                            <p className='text-xl'>We help you launch a tailor-made engaging and interactive dating app that helps your customers find their love.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage9} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#ddffc7] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo10} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>E-Commerce</h1>
                                            <p className='text-xl'>Skyrocket your e-commerce marketplace sales and reach the maximum audience with our user-friendly app solutions.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage10} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#b4ffb4] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo11} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Logistics</h1>
                                            <p className='text-xl'>Logistics app development simplifies your business processes & minimizes functional hurdles to boost efficiency.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage11} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#b4ffb4] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo12} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Real Estate</h1>
                                            <p className='text-xl'>Give convenient services to your customers to buy, sell, or rent properties in Pakistan with real estate app development solutions.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage12} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#d6c7ff] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo13} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Fintech</h1>
                                            <p className='text-xl'>Accelerate digital innovation with our full-stack, resilient, and scalable fintech software development services in Pakistan.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage13} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='w-full'>
                                    <div>
                                        <div className='sm:h-[330px] md:h-auto text-black bg-[#b4d5ff] p-3 rounded-xl flex flex-col items-start gap-2 '>
                                            <figure className='w-[90px]'>
                                                <img src={industriesCatterLogo12} alt='Industries Catter' />
                                            </figure>
                                            <h1 className='text-2xl font-bold'>Construction</h1>
                                            <p className='text-xl'>100% customizable and world-class construction software solutions in Pakistan that fully cater to the needs of your business.</p>
                                        </div>
                                        <div className='pt-5'>
                                            <figure>
                                                <img src={industriesCatterImage13} alt='Fitness' />
                                            </figure>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    {/* LAstImage */}
                    <div className='flex justify-between w-full tablet:h-[300px] sm:h-[230px] sm:bg-white md:rounded-3xl sm:rounded-xl lg:bg-caterSecondImage overflow-hidden'>
                        <div data-aos="fade-left" className='flex flex-col gap-4 justify-center py-5 lg:pl-10 sm:p-5 lg:p-0 text-black sm:text-center lg:text-start rounded-3xl sm:w-full lg:bg-transparent sm:bg-white' >
                            <h1 className='md:text-4xl sm:text-[22px] font-bold w-full leading-relaxed' style={{ lineHeight: '1.4' }}>Looking for end-to-end Mobile App<span className='lg:block'> Development Services in UAE</span> </h1>
                            <div>
                                <button className='btn bg-gradiBg'
                                    onClick={() => setEngagementModelOpen(true)}>
                                    Talk To Our Experts &nbsp;<span><EastSharpIcon /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isEngagementModelOpen &&
                <EngagementModel onClose={() => setEngagementModelOpen()} />
            }
        </div>
    );
}

export default IndustriesWeCatter;
