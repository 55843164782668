import React from 'react';
import Innovative1 from 'assets/png/innovative1.png';
import Innovative2 from 'assets/png/innovative2.png';
import Innovative3 from 'assets/png/innovative3.png';
import Innovative4 from 'assets/png/innovative4.png';
import Innovative5 from 'assets/png/innovative5.png';
import Innovative6 from 'assets/png/innovative6.png';
import Innovative7 from 'assets/png/innovative7.png';
import Innovative8 from 'assets/png/innovative8.png';
import Innovative9 from 'assets/png/innovative9.png';

export default function AppcardDevelopment() {
    return (
        <div className='grid md:grid-cols-3 gap-4 mx-auto'>
            {/* card 1 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative1})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center items-center pt-32 text-white'>
                            <h4 className='absolute font-black text-3xl'>Education</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    As a leading education app development company, we helped investors redefine learning through an integrated set of eLearning elements, tools, and resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 2 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative2})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Fitness</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    We employ the most sophisticated technologies to help you create, market, and support your fitness solution in order to make you stand apart from your competitors.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 3 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative3})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Logistics</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    Simplify highly challenging and complex logistics & transport services easily through comprehensive logistics app development solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 4 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative4})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Education</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div className=''>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    As a leading education app development company, we helped investors redefine learning through an integrated set of eLearning elements, tools, and resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 5 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative5})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Fitness</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div className=''>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    We employ the most sophisticated technologies to help you create, market, and support your fitness solution in order to make you stand apart from your competitors.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 6 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative6})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Logistics</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    Simplify highly challenging and complex logistics & transport services easily through comprehensive logistics app development solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 7 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative7})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Education</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    As a leading education app development company, we helped investors redefine learning through an integrated set of eLearning elements, tools, and resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 8 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative8})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Fitness</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div className=''>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    We employ the most sophisticated technologies to help you create, market, and support your fitness solution in order to make you stand apart from your competitors.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 9 */}
            <div className="flip-card h-[280px] md:w-[350px] sm:w-[300px]">
                <div className="flip-card-inner" style={{ backgroundImage: `url(${Innovative9})` }}>
                    {/* card Front */}
                    <div className="flip-card-front">
                        <div className='relative flex justify-center text-center pt-32 text-white'>
                            <h4 className='absolute font-black text-[32px]'>Logistics</h4>
                        </div>
                    </div>
                    {/* Card Back */}
                    <div className="flip-card-back">
                        <div className=''>
                            <div className='relative text-center pt-20 px-10'>
                                <p>
                                    Simplify highly challenging and complex logistics & transport services easily through comprehensive logistics app development solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
