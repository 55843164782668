import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetRequest,apiPostRequest } from './apiService';
const ContactFormContext = createContext();

export const useContactFormContext = () => {
  return useContext(ContactFormContext);
};
export const ContactFormProvider = ({ children }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [Valid, setValid] = useState(true);
  const[contactNumbers, setContactNumbers]= useState([])
  const[contactEmails, setContactEmails]= useState([])

  // Get Contact Info From Backend
  const getPhoneNumber= async()=>{
   try {
     const response = await apiGetRequest('admin/contactNumber')
     setContactNumbers(response.data.phonesNumber);
     setContactEmails(response.data.emailAddress);
   } catch (error) {
    console.error('Error fetching contact numbers:', error);
   }
  }
  useEffect(()=>{
    getPhoneNumber()  
  },[])

// Submit Form
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    let validationError = {};

    if (name === '' || name === null) {
      isValid = false;
      validationError.name = "This Field is Required"
    }
    else if (name.length <= 3) {
      isValid = false;
      validationError.name = "Please Enter At Least 4 Characters."
    }

    else if (email === '' || email === null) {
      isValid = false;
      validationError.email = "Please Enter a Valid Email Address"
    }
    else if (phone === '' || phone === null) {
      isValid = false;
      validationError.phone = "This Field is Required"
    }

    else if (subject === '' || subject === null) {
      isValid = false;
      validationError.subject = "This Field is Required"
    }
    else if (subject.length < 10) {
      isValid = false;
      validationError.subject = "Please Enter At Least 10 Characters"
    }
    else if (description === '' || description === null) {
      isValid = false;
      validationError.description = "Please Enter At Least 20 Characters"
    }
    if (isValid) {
      try {
        setError(false);
        setLoading(true);

        await new Promise(resolve => setTimeout(resolve, 800));
        const response = await apiPostRequest('contactUs', {
          full_name: name,
          email: email,
          phone_no: phone,
          subject: subject,
          description: description
        })
        console.log(response);
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setDescription('');

        setLoading(false);
        navigate('/');
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    } else {
      setError(validationError);
      setValid(isValid);
    }
  };

  const contextValue = {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    subject,
    setSubject,
    description,
    setDescription,
    error,
    Valid,
    setValid,
    loading,
    handleSubmit,
    contactNumbers,
    contactEmails,
  };

  return (
    <ContactFormContext.Provider value={contextValue}>
      {children}
    </ContactFormContext.Provider>
  );
};
