import React from 'react';
import FlipCardArrowShape from 'assets/Images/shape-arrow.svg'
import dubaiImage from 'assets/Images/dubai-image.png'
const RotateCards = () => {
    return (
        <div className='text-white bg-[#002033] '>
            <div data-aos="fade-up" className='md:pt-[80px] sm:pt-[50px] pb-5'>
            <h2 className='md:text-5xl sm:text-2xl font-bold text-center mb-3 '>Our Process</h2>
            <p className='md:text-lg sm:text-md text-center sm:px-5 md:px-0'>Partner with SCube for a 100% transparent and streamlined Mobile App Development <span className='block'> with flexibility and scalability as per your specific business requirements.</span></p>
          </div>
            {/* Flip Card */}
            <div className='relative lg:before:absolute before:content-[""] before:w-full before:h-[1px] before:bg-white before:top-1/2'>
            <ul className='container mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 place-items-center gap-4'>
              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 lg:rounded-full sm:rounded-xl overflow-hidden">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center bg-darkBlue lg:rounded-full sm:rounded-xl">
                  <div className="rotate-card-front p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 1 </h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>Consultation</p>
                  </div>
                  <div className="rotate-card-back p-5  flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>We understand client requirements, identify objectives, research competitors, select the right platform, and choose a monetization method.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#FF7D88] opacity-65 blur-2xl z-[-1]"></div>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1400" data-aos-delay="500" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 lg:rounded-full sm:rounded-xl overflow-hidden">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center bg-darkBlue lg:rounded-full sm:rounded-xl">
                  <div className="rotate-card-front p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 2 </h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>Analysis and Planning</p>
                  </div>
                  <div className="rotate-card-back p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>This is the practical phase where functional & non-functional requirements, product roadmap, & technology stack are taken into account.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#AC91FF] opacity-65 blur-2xl -z-10 "></div>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" data-aos-delay="800" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 lg:rounded-full sm:rounded-xl overflow-hidden">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center bg-darkBlue lg:rounded-full sm:rounded-xl">
                  <div className="rotate-card-front p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 3</h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>UI/UX Design</p>
                  </div>
                  <div className="rotate-card-back p-5  flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>This is the designing phase where the work is done on information architecture & workflows, wireframes, style guides, mockups, and prototypes.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#FFA585] opacity-65 blur-2xl -z-10 "></div>
                </div>
              </li>
              <li className='z-10 sm:hidden lg:block'>
                <figure>
                  <img className='object-contain' src={FlipCardArrowShape} alt='Flip Card arrow' />
                </figure>
              </li>
              <li className='z-10 sm:hidden lg:block'>
                <figure>
                  <img className='object-contain ' src={FlipCardArrowShape} alt='Flip Card arrow' />
                </figure>
              </li>
              <li className='z-10 sm:hidden lg:block'>
                <figure>
                  <img className='object-contain' src={FlipCardArrowShape} alt='Flip Card arrow' />
                </figure>
              </li>

              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 overflow-hidden lg:rounded-full sm:rounded-xl">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center lg:rounded-full sm:rounded-xl bg-darkBlue">
                  <div className="rotate-card-front p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 4 </h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>App Development</p>
                  </div>
                  <div className="rotate-card-back p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>When it comes to mobile app development in Pakistan, we employ professional developers for both the back-end and front-end.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#FF91B0] opacity-65 blur-2xl -z-10 "></div>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000" data-aos-delay="500" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 overflow-hidden lg:rounded-full sm:rounded-xl">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center lg:rounded-full sm:rounded-xl bg-darkBlue">
                  <div className="rotate-card-front p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 5 </h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>Quality Assurance & Testing</p>
                  </div>
                  <div className="rotate-card-back p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>We understand client requirements, identify objectives, research competitors, select the right platform, and choose a monetization method.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#63FFC2] opacity-65 blur-2xl -z-10 "></div>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" data-aos-delay="500" className="rotate-card bg-transparent w-full lg:h-[360px] tablet:h-[270px] sm:h-[220px] perspective-1000 overflow-hidden lg:rounded-full sm:rounded-xl">
                <div className="rotate-card-inner text-pretty text-white relative w-full h-full text-center lg:rounded-full sm:rounded-xl bg-darkBlue">
                  <div className="rotate-card-front flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <h2 className='text-3xl mb-4'>Stage 6 </h2>
                    <p className='sm:text-[28px] tablet:text-[40px] font-bold'>Lunch & Development</p>
                  </div>
                  <div className="rotate-card-back p-5 flex flex-col items-center justify-center absolute w-full h-full lg:rounded-full sm:rounded-xl border-[1px] border-gray-500 border-solid">
                    <p className='md:text-xl leading-relaxed'>Once we are satisfied with the app, it is presented to the client. After approval, the app is finally deployed or handed over to the client.</p>
                  </div>
                  <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-0 w-40 h-40 rounded-full bg-[#827BFF] opacity-65 blur-2xl -z-10 "></div>
                </div>
              </li>
            </ul>
          </div>
            <figure className='opacity-50 mix-blend-luminosity w-full'>
                <img src={dubaiImage} alt='Background_Image' />
            </figure>
        </div>
    );
}

export default RotateCards;
