import React from 'react';
import ContactForm from '../component/ContactForm';
const PrivacyPolicy = () => {
    return (
        <div className='container mx-auto overflow-hidden'>
            <div className='flex flex-col gap-y-5 py-4'>
                <h1 className='text-black md:text-5xl sm:text-3xl font-bold text-center'>Privacy Policy</h1>
                <p className='md:text-xl text-text'>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your experience.</p>
                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>When do we collect information?</h2>
                    <p className='md:text-xl text-text'>We collect information from you when you subscribe to a newsletter, fill out a form or enter information on our site.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>How do we use your information?</h2>
                    <p className='md:text-xl text-text'>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
                    <p className='md:text-xl text-text'>To personalize user’s experience and to allow us to deliver the type of content and product offerings in which you are most interested.</p>
                    <p className='md:text-xl text-text'>To administer a contest, promotion, survey or other site feature.</p>
                    <p className='md:text-xl text-text'>To send periodic emails regarding your order or other products and services.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>How do we protect visitor information?</h2>
                    <p className='md:text-xl text-text'>We do not use vulnerability scanning and/or scanning to PCI standards and we do not use Malware Scanning.   We do not store any personal identifiable information on our servers.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Do we use ‘cookies’?</h2>
                    <p className='md:text-xl text-text'>Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services, We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>We use cookies to:</h2>
                    <p className='md:text-xl text-text'>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.   You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer, Mozilla Firefox or Google Chrome) settings.   Each browser is a little different, so look at your browser’s Help menu to learn the correct way to modify your cookies.   If you disable cookies off, some features will be disabled. It won’t affect the users’ experience that make your site experience more efficient and some of our services will not function properly.   However, you can still submit inquiries.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Third Party Disclosure</h2>
                    <p className='md:text-xl text-text'>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Third Party Links</h2>
                    <p className='md:text-xl text-text'>We do not include or offer third party products or services on our website.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Third Party Trademarks</h2>
                    <p className='md:text-xl text-text'> Please note that the use of trademarked terms used in content and Meta tags of this website is just for advertising purpose and they are the trademarks of their respective owners only. This is the way in which the web development industry refers to the development of a website or similar to some existing websites or apps like Uber, YouTube and WhatsApp. We certify that the whole code and design is developed by our in-house team of developers and designers and nothing has been or will be utilized from other websites. SCube website is by no means connected with other brand’s website.</p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Google</h2>
                    <p className='md:text-xl text-text'> Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en   We along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies, compile data regarding user interactions with ad impressions, and other ad services functions as they relate to our website. </p>
                    <br />
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Opting-out:</h2>
                    <p className='md:text-xl text-text'>Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising initiative opt out page or permanently using the Google Analytics Opt Out Browser add on. </p>
                </div>

                <div className='flex flex-col gap-y-2'>
                    <h2 className='md:text-3xl sm:text-2xl font-bold'>Contacting Us</h2>
                    <p className='md:text-xl text-text'>If there are any questions regarding this privacy policy, you may contact us using the information below. https://www.xx.com C-127 Phase VIII Industrial Area Mohali India 160071 xxx@SCube.com</p>
                </div>
            </div>
            <ContactForm/>
        </div>
    );
}

export default PrivacyPolicy;
