import React, { useState } from 'react';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ImageRecognization from '../assets/Images/Ar-vr-Footer03.webp'
import gammingApps from '../assets/Images/Ar-vr-Footer04.webp'
import ArSupport from '../assets/Images/Ar-vr-Footer05.webp'
import Ecommerce from '../assets/Images/Ar-vr-Footer06.webp'
import globalBusiness from '../assets/Images/Ar-vr-Footer02.webp'
import StarsBg from '../assets/Images/Ar-vr-Footer12.png'
import consultingImage from '../assets/Images/face-scan.png'
import integrationImage from '../assets/Images/360-degrees.png'
import headponesImage from '../assets/Images/headphones.png'
import industriesWeServeImage from '../assets/Images/Ar-vr-Footer13.webp'
import scubeRoadMap from '../assets/Images/Ar-vr-Footer14.webp'
import imageProcessing from '../assets/Images/image-processing.png'
import poweringVenture from '../assets/Images/Ar-vr-Footer15.webp'
import ContactForm from '../component/ContactForm';
import EngagementModel from 'Pages/EngagementModel';

export default function ARVRfooter() {
  const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);

  return (
    <>
      <div class='relative bg-black w-full'>
        <div class='container mx-auto'>
          <div className='md:py-5'>
            <div class='lg:bg-AVRDevservicesBgImage bg-contain bg-right bg-no-repeat w-[90%] md:h-screen flex md:items-center sm:py-5'>
              <div class='lg:w-[50%] sm:w-full'>
                <div class='text-white flex flex-col space-y-4'>
                  <h1 class='md:text-5xl sm:text-3xl  font-bold'>AR/VR <span className='lg:block'>Development</span>  Services</h1>
                  <p class='tablet:text-xl sm:text-md'>Leverage leading technologies like AR and VR to deliver immersive customer experience.</p>
                  <div className='mt-5'>
                    <button class='bg-purpleGradient btn' onClick={() => setEngagementModelOpen(true)}>
                      Get Started &nbsp;<span> <EastSharpIcon /></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isEngagementModelOpen && (
            <EngagementModel onClose={() => setEngagementModelOpen()} />
          )}

          <div className='md:text-center sm:text-start space-y-4 text-white py-5'>
            <h1 className='md:text-5xl sm:text-2xl font-bold'>Engage Your Target Audience and Build Loyalty</h1>
            <p className='text-xl'>Deliver unique experiences to engage more users with our AR/VR development services.</p>
            <h1 className='md:text-5xl sm:text-3xl font-bold pt-[50px]'>Augmented Reality App Development Services</h1>
          </div>

          {/* Swiper */}
          <Swiper
            spaceBetween={40}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={
              {
                350: {
                  slidesPerView: 1
                },
                760: {
                  slidesPerView: 2,
                },
                960: {
                  slidesPerView: 3,
                },
              }
            }
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            style={{ paddingBottom: '80px' }}
          >
            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='w-full h-full object-cover scale-[1.2] -mt-[60px]' src={ImageRecognization} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>Image-Recognition</h2>
                  <p>Enable visual search for an intuitive product engagement and visual navigation.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='w-full h-full object-cover scale-[1.2] -mt-[60px]' src={gammingApps} alt='Image Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>2D and 3D Gaming Apps</h2>
                  <p>Expand the play field and add interesting characters that blends within the user's environment to make games enticing.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={ArSupport} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>AR Support & Maintanence</h2>
                  <p>We ensure regular updates and the latest versions to run applications seamlessly with the AR development process.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={Ecommerce} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>ECommerce Integration</h2>
                  <p>Implement marker-based and sensor-based AR to make shopping experience fun and memorable.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={globalBusiness} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>Global Business</h2>
                  <p>Augmented reality business opportunities stem from the unique ability to deliver information and streamline processes.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className='md:mt-5 sm:my-3 text-white text-center'>
            <button class='bg-purpleGradient btn' onClick={() => setEngagementModelOpen(true)}>
              Get Started <span> <EastSharpIcon /></span>
            </button>
          </div>
          {/* <h1 className='md:text-5xl sm:text-3xl font-bold text-center lg:py-[80px] sm:py-5 text-white'>Virtual Reality App Development Services</h1>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 200000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              350: { slidesPerView: 1 },
              760: { slidesPerView: 2 },
              960: { slidesPerView: 3 }
            }}

            pagination={{
              clickable: true,
            }}

            modules={[Autoplay, Pagination]}
            style={{ paddingBottom: '80px' }}
          >

            <SwiperSlide className='flex flex-col cursor-grab active:cursor-grabbing shadow-custom-shadow'>
              <div className='h-[450px] w-full text-white '>
                <div className=' h-[300px] hover:h-[450px] w-full flex flex-col items-center justify-center  rounded-lg bg-cover bg-center ' style={{ backgroundImage: `url(${globalBusiness})` }}>
                <div className='space-y-3 p-3'>
                  <h1 className='text-xl font-bold'>Presentation and Sales Tools</h1>
                  <p className='text-sm font-medium'>Add auditory and visual flair to business presentations for sales pitch, involve VCs, or other administration processes.</p>
                </div>
                </div>
                <div className='space-y-3 p-3'>
                  <h1 className='text-xl font-bold'>Presentation and Sales Tools</h1>
                  <p className='text-sm font-medium'>Add auditory and visual flair to business presentations for sales pitch, involve VCs, or other administration processes.</p>
                </div>
              </div>

            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>

            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={ArSupport} alt='Imag-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>AR Support & Maintanence</h2>
                  <p>We ensure regular updates and the latest versions to run applications seamlessly with the AR development process.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={Ecommerce} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>ECommerce Integration</h2>
                  <p>Implement marker-based and sensor-based AR to make shopping experience fun and memorable.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-black rounded-lg flex flex-col cursor-grab active:cursor-grabbing w-full shadow-custom-shadow'>
              <div className='h-[450px]'>
                <figure className='relative rounded h-[270px] overflow-hidden'>
                  <img className='h-full scale-[1.2] -mt-[60px]' src={globalBusiness} alt='Image-Recognition' />
                </figure>
                <div className='text-white p-3 space-y-1'>
                  <h2 className='text-xl font-bold'>Global Business</h2>
                  <p>Augmented reality business opportunities stem from the unique ability to deliver information and streamline processes.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className='mt-5 text-white text-center flex justify-center flex-col items-center space-y-14'>
            <button class='bg-purpleGradient btn' onClick={() => setEngagementModelOpen(true)}>
              Get Started <span> <EastSharpIcon /></span>
            </button>
            <figure className=''>
              <img src={StarsBg} alt="Stars" />
            </figure>
            <h1 className='md:text-5xl sm:text-3xl font-bold'>Get Premium Access To Our Precise AR/VR Services</h1>
          </div> */}

          <div className='w-full flex flex-wrap items-stretch justify-center gap-4 py-5'>
            <div className='md:w-[30%] bg-[#18191d] h-auto p-4 space-y-3 text-white border-[1px] rounded-xl'>
              <div className='flex items-center space-x-2'>
                <figure className='w-12'>
                  <img src={consultingImage} alt="Consulting Image here" />
                </figure>
                <p className='text-2xl font-semibold'>Consulting</p>
              </div>
              <div>
                <p className='text-lg'>We offer R&D-based app development strategies to eradicate your groundwork for thriving projects and build a proper business concept with integrity.</p>
              </div>
            </div>

            <div className='md:w-[30%] bg-[#18191d] h-auto p-4 space-y-3 text-white border-[1px] rounded-xl'>
              <div className='flex items-center space-x-2'>
                <figure className='w-12'>
                  <img src={integrationImage} alt="Integration-Image here" />
                </figure>
                <p className='text-2xl font-semibold'>AR/VR app development</p>
              </div>
              <div>
                <p className='text-lg'>Our platform-agnostic apps and 3D games give you exceptional performance, responsiveness, and engaging features.</p>
              </div>
            </div>

            <div className='md:w-[30%] bg-[#18191d] h-auto p-4 space-y-3 text-white border-[1px] rounded-xl'>
              <div className='flex items-center space-x-2'>
                <figure className='w-12'>
                  <img src={consultingImage} alt="Consulting image here" />
                </figure>
                <p className='text-2xl font-semibold'>UI/UX design</p>
              </div>
              <div>
                <p className='text-lg'>We design thrilling experiences for emerging consumer behavior, ultimate interactivity, visual appeal, and immersive entertainment.</p>
              </div>
            </div>

            <div className='md:w-[30%] bg-[#18191d] h-auto p-4 space-y-3 text-white border-[1px] rounded-xl'>
              <div className='flex items-center space-x-2'>
                <figure className='w-12'>
                  <img src={headponesImage} alt="Headphones Image here" />
                </figure>
                <p className='text-2xl font-semibold'>Metaverse ecosystems</p>
              </div>
              <div>
                <p className='text-lg'>Metaverse ecosystems- From supply and demand to multi-platform enterprise, we design a Metaverse of your own to deal with full coverage of alternative reality ecosystem.</p>
              </div>
            </div>

            <div className='md:w-[30%] bg-[#18191d] h-auto p-4 space-y-3 text-white border-[1px] rounded-xl'>
              <div className='flex items-center space-x-2'>
                <figure className='w-12'>
                  <img src={integrationImage} alt="Integration Image here" />
                </figure>
                <p className='text-2xl font-semibold'>Integrations</p>
              </div>
              <div>
                <p className='text-lg'>Our team builds custom or platform-based software with the AR, VR, and MR integration process from connected devices to sensors.</p>
              </div>
            </div>
          </div>

          <div className='text-white'>
            <h1 className='md:text-5xl sm:text-2xl font-bold text-center'>Industries We Serve With Our AR/VR App Development Solutions</h1>
            <div className='grid md:grid-cols-2 gap-5 py-5'>
              <div >
                <figure className='sticky top-10'>
                  <img className='mt-3' src={industriesWeServeImage} alt="Industries web server Image" />
                </figure>
              </div>
              <div className='space-y-5'>
                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Healthcare</h1>
                  <p className='text-lg'>We deliver compatible augmented reality apps to assist healthcare specialists in surgical operations with accurate AR data and actionable reports.</p>
                </div>

                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Real Estate</h1>
                  <p className='text-lg'>With our virtual interior design functionality of real estate AR apps, we can transform an empty house or apartment into an furnished, cozy living space.</p>
                </div>
                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Education & Training</h1>
                  <p className='text-lg'>We guide professionals to access product assembly, medical procedures, equipment installation, etc in different educational sectors.</p>
                </div>
                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Games</h1>
                  <p className='text-lg'>We specialize in creating AR game consoles that capture the attention of users and provide better connectivity with characters.</p>
                </div>
                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Retail</h1>
                  <p className='text-lg'>Our AR retail apps can work for clothing, jewelry, furniture and car stores to help them boost sales with 3D models of products in the real world.</p>
                </div>

                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Social Media</h1>
                  <p className='text-lg'>Get fully functional AR filters and VR-enabled environments to help companies promote their products on the internet with high engagement.</p>
                </div>

                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Sports</h1>
                  <p className='text-lg'>Our AR and VR based mobility solutions cater better user engagement, marketing, security, or training for sportspersons, fans, and others.</p>
                </div>

                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Travel</h1>
                  <p className='text-lg'>Enjoy sightseeing tours or museum/gallery rounds to the next level with amazing AR visuals of specified locations, directions, and historical information.</p>
                </div>
                <div className='bg-[#19191a] p-3 space-y-4 rounded-lg'>
                  <h1 className='text-2xl font-semibold inline-block border-b-2 border-white'>Entertainment</h1>
                  <p className='text-lg'>Create delightful user experience with online performances on live events that attrcats your audience and help them feel the real stage.</p>
                </div>
              </div>
            </div>

          </div>
          <div>
            <div className='text-center text-white space-y-4'>
              <h1 className='md:text-[45px] sm:text-2xl font-bold'>Scube Roadmap To AR & VR App Development</h1>
              <p className='md:text-xl sm:md font-medium'>Our virtual and augmented reality application process is streamlined with utmost precision and personalization to make your app one of a kind. Here are steps included in our AR/VR app development process:</p>
            </div>
            <div className='grid md:grid-cols-2 py-5'>
              <div className='flex flex-col gap-y-4 '>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>01</span>
                  <p className='font-semibold text-lg text-text'>Comprehending and analyzing business requirements.</p>
                </div>

                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>02</span>
                  <p className='font-semibold text-lg text-text'>Optimizing the scope and development technologies.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>03</span>
                  <p className='font-semibold text-lg text-text'>Creating the architectural app data flow in detail.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>04</span>
                  <p className='font-semibold text-lg text-text'>Curating the app visuals with high user experience design.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>05</span>
                  <p className='font-semibold text-lg text-text'>The management of project and code engineering.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>06</span>
                  <p className='font-semibold text-lg text-text'>Integration of third-party APIs for mobile as well as web.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>07</span>
                  <p className='font-semibold text-lg text-text'>Top-notch automated, manual, or user acceptance testing.</p>
                </div>

                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>08</span>
                  <p className='font-semibold text-lg text-text'>Encompass the best analytics and monitoring tools.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>09</span>
                  <p className='font-semibold text-lg text-text'>Analysing user data and marketing for enhancements.</p>
                </div>
                <div className='bg-whiteBg flex items-center justify-start gap-2 p-2 rounded-xl'>
                  <span className='bg-purpleGradient rounded-full text-white text-2xl font-semibold w-12 h-12 flex items-center justify-center'>10</span>
                  <p className='font-semibold text-lg text-text'>Complete customer support and product maintenance.</p>
                </div>
              </div>
              <div>
                <figure className='sticky top-10'>
                  <img src={scubeRoadMap} alt="SCube Road Map" />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: 'transparent linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%) 0% 0% no-repeat padding-box' }}>
          <div className='container mx-auto py-5'>
            <h1 className='md:text-5xl sm:text-3xl font-bold text-center text-white mb-14'>Powering Ventures With Next-Gen <span className='lg:block'>Techniques</span></h1>
            <div className='grid md:grid-cols-3 gap-5'>
              <div className='flex items-center flex-col gap-3'>
                <div className='flex flex-col gap-2 text-white p-4 rounded-xl' style={{ background: 'transparent linear-gradient(180deg, #020202 0%, #1a1818 100%) 0% 0% no-repeat padding-box' }}>
                  <figure className='w-14'>
                    <img src={imageProcessing} alt="Image-Processing" />
                  </figure>
                  <h1 className='text-2xl font-bold'>Image recognition</h1>
                  <p className='text-lg '>AR models identify objects, images and places with artificial intelligence software for overlaying animations, sound, HTML content etc.</p>
                </div>

                <div className='flex flex-col gap-2 text-white p-4 rounded-xl' style={{ background: 'transparent linear-gradient(180deg, #020202 0%, #1a1818 100%) 0% 0% no-repeat padding-box' }}>
                  <figure className='w-14'>
                    <img src={imageProcessing} alt="Image-Processing" />
                  </figure>
                  <h1 className='text-2xl font-bold'>3D recognition and tracking</h1>
                  <p>Due to this technique, any application an enhance large spaces and buildings to recognize three-dimensional objects like boxes, cups, cylinders, and more.</p>
                </div>
              </div>

              <div className='flex items-center flex-col gap-3 lg:mt-40'>
                <figure className='sm:hidden md:block'>
                  <img src={poweringVenture} alt="Next-generation powering" />
                </figure>
                <div className='flex flex-col gap-2 text-white  p-4 rounded-xl' style={{ background: 'transparent linear-gradient(180deg, #020202 0%, #1a1818 100%) 0% 0% no-repeat padding-box' }}>
                  <figure className='w-14'>
                    <img src={imageProcessing} alt="Image-Processing" />
                  </figure>
                  <h1 className='text-2xl font-bold'>Object Recognition</h1>
                  <p>Unlock AR functionalities to identify textual or visual information of different objects and positions in space using the gadget only.</p>
                </div>
              </div>

              <div className='flex items-center flex-col gap-3'>
                <div className='flex flex-col gap-3 text-white  p-4 rounded-xl' style={{ background: 'transparent linear-gradient(180deg, #020202 0%, #1a1818 100%) 0% 0% no-repeat padding-box' }}>
                  <figure className='w-14'>
                    <img src={imageProcessing} alt="Image-Processing" />
                  </figure>
                  <h1 className='text-2xl font-bold'>Light Estimation</h1>
                  <p>Render the realistic effects of the captured area with a proper model of lightning and shading using advanced APIs.</p>
                </div>
                <div className='flex flex-col gap-2 text-white  p-4 rounded-xl' style={{ background: 'transparent linear-gradient(180deg, #020202 0%, #1a1818 100%) 0% 0% no-repeat padding-box' }}>
                  <figure className='w-14'>
                    <img src={imageProcessing} alt="Image-Processing" />
                  </figure>
                  <h1 className='text-2xl font-bold'>Motion Tracking</h1>
                  <p>Identify surface points track 3D elements live to inhabit the real world with the camera of a device using Motion tracking technology.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-AVRtechPartner w-full h-full text-white'>
          <div className='container mx-auto md:py-[70px] sm:py-5'>
            <h1 className='md:text-5xl sm:text-2xl font-bold md:mb-14 sm:mb-5'>Why Choose Scube as Your <span className='md:block'>AR/VR Tech Partner?</span></h1>
            <div className='grid md:grid-cols-2 gap-5'>
              <div className='grid md:grid-cols-2 gap-3'>
                <div className=' bg-AVRcardIotIntegrated bg-center bg-cover bg-no-repeat rounded-xl h-[230px] flex flex-col justify-end gap-2 p-3'>
                  <h1 className='text-xl font-bold'>Iot Integrated</h1>
                  <p className='text-md font-medium'>Visual Recognition Solutions</p>
                </div>

                <div className='bg-AVRcardIndustry bg-center bg-cover bg-no-repeat rounded-xl h-[230px] flex flex-col justify-end gap-2 p-3'>
                  <h1 className='text-xl font-bold'>Industry's</h1>
                  <p className='text-md font-medium'>Talented & Skilled Developers</p>
                </div>

                <div className='bg-AVRcardHighest bg-center bg-cover bg-no-repeat rounded-xl h-[230px] flex flex-col justify-end gap-2 p-3'>
                  <h1 className='text-xl font-bold'>Industry's</h1>
                  <p className='text-md font-medium'>Talented & Skilled Developers</p>
                </div>

                <div className='bg-AVRcardLargest bg-center bg-cover bg-no-repeat rounded-xl h-[230px] flex flex-col justify-end gap-2 p-3'>
                  <h1 className='text-xl font-bold'>Industry's</h1>
                  <p className='text-md font-medium'>Talented & Skilled Developers</p>
                </div>
              </div>

              <div className='bg-AVRcard3dGraphics bg-center bg-cover bg-no-repeat rounded-xl h-full flex flex-col justify-end gap-3 p-4'>
                <h1 className='text-5xl font-bold'>3d</h1>
                <p className='text-2xl font-medium'>Graphics & Dynamic Content Development</p>
              </div>
            </div>
          </div>

        </div>
        <div className='container mx-auto'>
          <div className='py-[70px] space-y-16'>
            <div className='bg-AVRFooterCustomerEngagement bg-center bg-no-repeat bg-cover rounded-xl p-[40px]'>
              <div className='flex md:flex-row sm:flex-col md:items-center sm:space-y-3 justify-between'>
                <h1 className='md:text-5xl sm:text-xl font-bold text-white'>Drive Customer Engagement & <span className='lg:block'>High Rois With Our</span>Immersivear/VR App Solutions.</h1>
                <div>
                  <button class='bg-purpleGradient btn' onClick={() => setEngagementModelOpen(true)}>
                    Get Started &nbsp;<span> <EastSharpIcon/></span>
                  </button>
                </div>
              </div>
            </div>

            <div className='text-white'>
              <ContactForm />
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
