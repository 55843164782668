import { Link } from 'react-router-dom';
import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/pagination';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import accessTerminalImage from 'assets/Images/nft-market-place1.png'
import FreeConsultaionImage from 'assets/Images/nft-market-place2.png'
import coinsImage from 'assets/Images/CoinPng.png'
import coinsImage1 from 'assets/Images/CoinPng1.png'
import whyChooseUSImage from 'assets/Images/nft-market-place3.png'
import ArrowWhychooseUs from 'assets/SVG/nft-market-placeArrow1.svg'
import advancefeaturesMarketPlace from 'assets/Images/nft-market-place4.svg'
import LaunchSecuredMarketPlace from 'assets/Images/nft-market-place5.png'
import EngagementModel from 'Pages/EngagementModel';

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import ImageCarasoul from 'component/carousel/ImageCarasoul';
import ContactForm from 'component/ContactForm';

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="white"
            className={`${id === open ? "rotate-180" : ""} h-10 w-10 p-2 rounded-full transition-transform bg-gradiBg `}>
            <path strokeLinecap="round " strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
const NftMarketPlace = () => {

    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    const [isEngagementModelOpen, setEngagementModelOpen] = useState(false);

    return (
        <div className='relative'>
            <div className='bg-navyBlueBg text-white overflow-hidden'>
                <div className='flex md:flex-row sm:flex-col items-center sm:gap-y-16 sm:justify-center py-[100px]'>
                    <div className='flex flex-col sm:text-center md:text-start md:items-start md:gap-5 sm:gap-5 md:w-1/2 md:pl-[60px]'>
                        <h2 className='md:text-5xl sm:text-3xl font-bold leading-tight'>Develop Your Own NFT Marketplace</h2>
                        <p className='md:text-xl sm:text-md'>We are a predominant NFT marketplace development company that develops NFT marketplace & contributes to the exclusive users of crypto collectives.</p>
                        <div>
                            <button class='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>
                                Get a Quote<span><EastSharpIcon /></span>
                            </button>
                        </div>
                    </div>

                    <div className='md:w-1/2 relative z-10'>
                        <img className='md:h-[500px] sm:h-[200px] min-w-min md:ml-11' src={accessTerminalImage} alt='NFT_Picture' />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full rounded-full bg-primaryBg opacity-30 blur-2xl -z-10 pt-[100%]">
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='bg-blueGreyBg'>
                <div className='container mx-auto py-[100px]  flex flex-col gap-4'>
                    <div>
                        <h2 className='md:text-5xl sm:text-3xl font-bold mb-3'>We Have Expertise In</h2>
                        <p className='text-xl text-text'>Our power-packed NFT marketplace development services</p>
                    </div>

                    <div className='grid md:grid-cols-2 sm:gap-y-8 md:gap-0'>
                        <div className='md:px-[40px] sm:p-4 md:py-[50px] transition duration-400 ease-in-out transform sm:bg-whiteBg md:bg-transparent md:hover:bg-whiteBg hover:drop-shadow-md'>
                            <h2 className='md:text-3xl sm:text-2xl font-semibold text-black pb-2'>Innovative Technology</h2>
                            <p className='md:text-xl sm:text-md text-text'>Our NFT marketplace platform comes with so many innovative features & functionality. We use advanced tech-stack with blockchain-based security that supports multiple cross-chain connectivity.</p>
                        </div>
                        <div className='md:px-[40px] sm:p-4 md:py-[50px] transition duration-400 ease-in-out transform sm:bg-whiteBg md:bg-transparent md:hover:bg-whiteBg hover:drop-shadow-md'>
                            <h2 className='md:text-3xl sm:text-2xl font-semibold text-black pb-2'>Cross-Chain Connectivity</h2>
                            <p className='md:text-xl sm:text-md  text-text'>We offer cross-chain connectivity support for our white label NFT Marketplace and deal in many blockchains like Polkadot, solana, etc.</p>
                        </div>

                        <div className='md:px-[40px] sm:p-4 md:py-[50px] transition duration-400 ease-in-out transform sm:bg-whiteBg md:bg-transparent md:hover:bg-whiteBg hover:drop-shadow-md'>
                            <h2 className='md:text-3xl sm:text-2xl font-semibold text-black pb-2'>Personalization & Customization</h2>
                            <p className='md:text-xl sm:text-md text-text'>In our NFT marketplace development services, we let you customize modules, functionality and even add some additional features that you require.</p>
                        </div>
                        <div className='md:px-[40px] sm:p-4 md:py-[50px] transition duration-400 ease-in-out transform sm:bg-whiteBg md:bg-transparent md:hover:bg-whiteBg hover:drop-shadow-md'>
                            <h2 className='md:text-3xl sm:text-2xl font-semibold text-black pb-2'>Dedicated Support Team</h2>
                            <p className='md:text-xl sm:text-md text-text'>Our team of tech-experts for whitelabel NFT marketplace development have expertise is resolving any issues & helping in any kind of support for our NFT Marketplace solutions.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='bg-greyLightBg'>
                <div className='container mx-auto '>
                    <div className='flex items-center w-full md:py-[90px] sm:py-6'>
                        <div className='md:w-1/2 flex flex-col space-y-5'>
                            <h2 className='md:text-5xl sm:text-2xl leading-tight font-bold'style={{lineHeight: '1.4'}}>Are you looking for a white label NFT marketplace solution?</h2>
                            <div>
                                <button class='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>
                                    Get a Free Consultaion! <EastSharpIcon /></button>
                            </div>
                        </div>

                        <div className='md:block sm:hidden absolute right-16'>
                            <img src={FreeConsultaionImage} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-transparent'>
                <div className='container mx-auto md:py-[100px] sm:py-10'>
                    <div className='flex flex-col gap-y-4'>
                        <h3 className='md:text-5xl sm:text-2xl font-bold'>Use Cases where NFT <span className='block'>Marketplaces Excel </span></h3>
                        <p className='text-text md:text-xl leading-10'>Get to know various sectors where you can taste revenue with our NFT marketplace platforms</p>
                    </div>
                    <ul className='grid md:grid-cols-3 gap-y-10 pt-5'>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:drop-shadow-xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>01</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Sports</h4>
                            <p className='text-text'>List Digital cards of sports superstars to tickle the craziness of sports fans all around the globe.ERC-1155</p>
                        </li>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:drop-shadow-xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>02</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Music</h4>
                            <p className='text-text'>Create an extensive marketplace to hold favorite music 's proprietorship.</p>
                        </li>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:drop-shadow-xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>03</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Games</h4>
                            <p className='text-text'>Develop an exclusive NFT gaming with grand visual experience and lure them into buying in-game attributes.</p>
                        </li>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:drop-shadow-xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>04</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Digital Collectables</h4>
                            <p className='text-text'>Build your own NFT marketplace to showcase your digital collections and lure your buyers to buy them.</p>
                        </li>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:drop-shadow-xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>05</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Photographs</h4>
                            <p className='text-text'>Display your photography skills and sell your digital photographs for certain NFTs.</p>
                        </li>
                        <li className='border-l border-gray-300 p-4 hover:bg-whiteBg hover:shadow-2xl hover:text-primaryText transition duration-400 ease-in-out transform'>
                            <p className='text-xl font-bold'>06</p> <br />
                            <h4 className='text-xl font-bold mb-2'>Domains</h4>
                            <p className='text-text'>Create a unique NFT marketplace with lustrous domain names that people desire to buy.</p>
                        </li>
                    </ul>
                </div>
            </div>


            {/* Swiper */}
            <div className='bg-greyLightBg md:py-[70px] sm:py-10'>
                <div className='container mx-auto'>
                    <h1 className='sm:text-2xl md:text-4xl font-bold text-center sm:mb-8 md:mb-16'>Supported NFT Standards</h1>
                    <Swiper
                        spaceBetween={30}
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                        }}

                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay]}
                        className="p-0"
                        breakpoints={{
                            350: { slidesPerView: 1 },
                            760: { slidesPerView: 2 },
                            960: { slidesPerView: 3 }
                        }}
                    >

                        <SwiperSlide className='bg-whiteBg min-h-[220px] rounded-xl flex flex-col items-center justify-center cursor-grab active:cursor-grabbing'>
                            <figure className='w-[60px] h-[60px] '>
                                <img src={coinsImage1} />
                            </figure>
                            <p className='text-xl font-bold'> ERC-721</p>
                        </SwiperSlide >
                        <SwiperSlide className='bg-whiteBg min-h-[220px] rounded-xl flex flex-col items-center justify-center cursor-grab active:cursor-grabbing'>
                            <figure className='w-[60px] h-[60px] '>
                                <img src={coinsImage} />
                            </figure>
                            <p className='text-xl font-bold'> ERC-1155</p>
                        </SwiperSlide >

                        <SwiperSlide className='bg-whiteBg min-h-[220px] rounded-xl flex flex-col items-center justify-center cursor-grab active:cursor-grabbing'>
                            <figure className='w-[60px] h-[60px] '>
                                <img src={coinsImage1} />
                            </figure>
                            <p className='text-xl font-bold'> ERC-165</p>
                        </SwiperSlide >

                        <SwiperSlide className='bg-whiteBg min-h-[220px] rounded-xl flex flex-col items-center justify-center cursor-grab active:cursor-grabbing'>
                            <figure className='w-[60px] h-[60px] '>
                                <img src={coinsImage} />
                            </figure>
                            <p className='text-xl font-bold'> ERC-2891</p>
                        </SwiperSlide >
                    </Swiper>
                </div>
            </div>

            <div className='bg-blueGreyBg'>
                <div className='container mx-auto py-[90px]'>
                    <h1 className='md:text-5xl sm:text-3xl font-bold mb-5'>Why choose SCube?</h1>
                    <div className='w-full flex'>
                        <div className='sm:hidden md:block w-[35%] flex items-center relative'>
                            <figure>
                                <img className='animate-spin' src={whyChooseUSImage} alt='Why choose Us Logo' style={{ animationDuration: '5s' }} />
                                <img className='w-[120px] h-[60px] absolute top-[110px] left-20 ' src={ArrowWhychooseUs} alt='ArrowImage' />
                            </figure>
                        </div>

                        <div className='md:w-[65%] flex flex-col space-y-5'>
                            <p className='text-xl font-bold text-text'>We provide you with cost-effective blockchain solutions built on top of cutting-edge technology stacks</p>
                            <hr />
                            <div className='grid md:grid-cols-2 gap-4'>
                                <div >
                                    <h2 className='text-xl font-bold'>Expert Team</h2>
                                    <p className='text-text'>We have a team of expert & experienced blockchain developers who have delivered high-quality blockchain software solutions all over the world.</p>
                                </div>
                                <div>
                                    <h2 className='text-xl font-bold'>Simple & Agile</h2>
                                    <p className='text-text'>For NFT platform development, we use agile approach to provide the best solution possible through innovation, collaboration, & customization.</p>
                                </div>

                                <div>
                                    <h2 className='text-xl font-bold'>Constant Innovation</h2>
                                    <p className='text-text'>Weekly updates to keep our technology at the top in result of which you get to provide better product & services to your users.</p>
                                </div>

                                <div>
                                    <h2 className='text-xl font-bold'>Help & Support</h2>
                                    <p className='text-text'>Get all your queries regarding job postings, escrow management, refunds and other concerns answered by official associates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container py-[90px]'>
                <div>
                    <h1 className='md:text-5xl sm:text-3xl font-bold text-black mb-[60px]'>Advanced Features <span className='block'>Of Our NFT Marketplace</span></h1>
                </div>
                <div className='flex md:flex-row sm:flex-col'>
                    <div className='md:w-[65%]'>
                        <ul className="grid md:grid-cols-2 gap-4 relative">
                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">Storefront</h2>
                                <p>Create a decentralized environment for your brand to improve security and transparency.</p>
                            </li>
                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">IoT</h2>
                                <p>With IoT technology, you can improve productivity & customer experience by reducing downtime & risk factors.</p>
                            </li>

                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">Search</h2>
                                <p>NFT Marketplace platform come up with a search bar which lets users to search NFTs of certain categories.</p>
                            </li>

                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">Create Listing</h2>
                                <p>With this feature user can create their own nfts and submit it. They can upload their own files by providing suitable token information.</p>
                            </li>

                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">Buy and Bid</h2>
                                <p>This Feature lets user buy or bid on their favourite NFTs if they are listed on the platform.</p>
                            </li>

                            <li className='pl-4 pt-5 pb-[30px] pr-[35px] bg-greyMediumBg hover:bg-primaryBg  hover:text-white rounded-md'>
                                <h2 class="text-xl font-bold mb-2">Crypto Wallet</h2>
                                <p>Our Marketplace connects users wallet so that they can store,send and receive NFTs.</p>
                            </li>
                        </ul>
                    </div>
                    <div className='md:w-[35%] flex flex-col items-center justify-center mx-auto md:gap-y-10 sm:pt-10 md:pt-0'>
                        <figure className='sm:hidden md:block'>
                            <img src={advancefeaturesMarketPlace} alt='Advance Features' />
                        </figure>
                        <div>
                            <button class='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>Let's Connect <span><EastSharpIcon /></span></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-blackBg'>
                <div className='flex items-center md:py-[90px] sm:py-10 container w-full'>
                    <div className='md:w-[55%] sm:flex md:block flex-col items-center justify-center'>
                        <h1 className='text-white md:text-5xl sm:text-center md:text-start sm:text-2xl font-bold mb-4' style={{ lineHeight: 1.4}}>Launch Your Highly Secured NFT Marketplace With Experts TODAY!</h1>
                        <div>
                           <button class='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>
                                Let's Connect &nbsp;<span><EastSharpIcon /></span>
                            </button>
                        </div>
                    </div>
                    <div className='sm:hidden md:block w-[45%]'>
                        <figure>
                            <img src={LaunchSecuredMarketPlace} alt='Launch Hight NFT MarketPlace' />
                        </figure>
                    </div>
                </div>
            </div>

            <div className="container flex flex-col items-center justify-between gap-4 py-[60px]">
                <span className="md:text-4xl sm:text-2xl text-center font-bold mb-4">Top Frequently Asked Questions</span>
                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left px-5" open={open === 1} icon={<Icon id={1} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-xl font-semibold hover:text-primaryText" onClick={() => handleOpen(1)}>What is an NFT marketplace?</AccordionHeader>
                    <AccordionBody className="text-text text-lg ">
                        NFT marketplace are the decentralized platform acting as a online web store running on a blockchain platform like etherum, used for storing & selling nft tokens
                    </AccordionBody>
                </Accordion>

                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left px-5" open={open === 2} icon={<Icon id={2} open={open} />}>
                    <AccordionHeader className=" md:text-2xl sm:text-xl font-semibold hover:text-primaryText" onClick={() => handleOpen(2)}>
                        What is the time and cost for White Label NFT Marketplace Development?
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg">
                        The time and cost for white label NFT marketplace development completely depends on the features and tech stack you want to use in your nft marketplace.
                    </AccordionBody>
                </Accordion>

                <Accordion className="bg-white shadow-custom rounded-lg border-[none] text-left px-5" open={open === 3} icon={<Icon id={3} open={open} />}>
                    <AccordionHeader className="md:text-2xl sm:text-xl font-semibold hover:text-primaryText" onClick={() => handleOpen(3)}>
                        What are the benefits of NFT Marketplace development?
                    </AccordionHeader>
                    <AccordionBody className="text-text text-lg">
                        NFT Marketplace development includes a smart contract that acts as an intermediary in transactions, releasing the payment post receiving the confirmation from the buyer’s wallet.
                        The Smart Contract in the development of the NFT Marketplace analyses wallet transactions to ensure there are no fraudulent attempts. The NFT Marketplace with its Authentication and Verification mode traces back the seller’s identity through the platform.
                    </AccordionBody>
                </Accordion>
                <span className='md:text-5xl sm:text-2xl font-semibold text-center mt-5'>
                    Explore Our Outstanding App Development Dubai Portfolio
                </span>
            </div>
            <ImageCarasoul />
            {/* Form */}
            <ContactForm />

            {isEngagementModelOpen && (
                <EngagementModel onClose={() => setEngagementModelOpen()} />
            )}
        </div>
    );
}

export default NftMarketPlace;
