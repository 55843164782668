import React, { useEffect, useState } from 'react'
import AndroidVideo from '../assets/video/mobille_page.mp4'
import Tick from '../assets/png/tick.png'
// shapping img
import Business from 'assets/png/busnss.png'
import Business1 from 'assets/png/shp1.png'
import Business2 from 'assets/png/shp2.png'
import Business3 from 'assets/png/shp3.png'
import Business4 from 'assets/png/shp4.png'
import Business5 from 'assets/png/shp1.png'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Fly from 'assets/png/fly.png';
import Like from 'assets/png/better_everything.png';
import Focus from 'assets/png/focus.png';
import ScheduleMeeting from 'assets/png/mbl_cta4.png'
// video
import AndroidVideo2 from 'assets/video/android.mp4'
// page component call below
import ModernTechnologies from 'Pages/ModernTechnologies';
// app development Testimonial
import AndroidCarousel from '../component/carousel/Appdevelopment'
// App development Methodology
import ApptMethodology from './AppDevelopmentMethodology';
// custom mobile app development
import CustomMobile from '.././assets/png/chlng_cta.png'
import DotedLine from '.././assets/png/doted_line.png'
// App cards section
import AppCard from '../component/AppcardDevelopment'
// TechnologyStack
import TechnologyStack from '../component/carousel/TechnologyStack'
// why choose Us
import ChooseUsCards from '../component/ChooseUsCards';
//  ImageCarasoul
import ImageCarasoul from '../component/carousel/ImageCarasoul';
// our Partner
import OurPartner from '../component/OurPartner';
// Mobile App Frequently ask questions
import MobileAppQuestions from '../component/MobileAppQuestions';
// contact form
import ContactForm from '../component/ContactForm'
import EngagementModel from 'Pages/EngagementModel';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'component/carousel/Imagecarasol.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';

export default function MobileAppDevelopment({ openEngagementModel }) {
    const [EngagementModelOpen, setEngagementModelOpen] = useState(false);

    useEffect(() => {
        AOS.init({
          duration: 1200,
          delay: 100,
          once: true,
        //   disable: 'mobile'
        });
      }, []);

    return (
        <>
            <div>
                <section className='container mx-auto md:ps-20 md:mt-32 sm:mt-20 md:mb-36 sm:mb-5 h-3/4' >
                    <div className='flex md:flex-row sm:flex-col w-full overflow-hidden'>
                        <div className='md:w-[46%] sm:mt-[180px] md:mt-0'>
                            <h1 className='font-bold md:text-4xl tablet:text-3xl sm:text-2xl' style={{ lineHeight: '1.4' }}>
                                Start Your Success Journey with <span className='md:text-[40px] sm:text-3xl text-primaryText'>Mobile App Development Company </span>
                            </h1>
                            <figure>
                                <img className='absolute -left-4 top-72' src="https://www.apptunix.ae/wp-content/uploads/2023/10/bnr_crcl.png" alt="Circle" />
                            </figure>
                            <p className='tablet:text-xl leading-8 mt-3'>Join hands with high-skilled mobile app developers and curate seamless digital transformation to stay
                                ahead of the curve.
                            </p>
                            <div className='my-4'>
                                <button className='btn bg-gradiBg' onClick={() => setEngagementModelOpen(true)}> Build Your Dream App <span><EastOutlinedIcon /></span></button>
                            </div>
                        </div>
                        <div className='md:w-[64%] right-0 h-[100%] object-contain absolute -z-10 md:top-[40%] transform translate-x-0 translate-y-[-55px]'>
                            <video className='h-[60]' loop autoPlay muted playsInline src={AndroidVideo} ></video>
                        </div>
                    </div>
                </section>
                <section className='container mx-auto'>
                    <div data-aos="zoom-in" className='text-center overflow-hidden'>
                        <h2 className='font-black md:text-5xl tablet:text-3xl sm:text-2xl mb-3'>Elevate User Experience With Custom <br />
                            <span className='text-primaryText sm:text-xl md:text-4xl font-bolder'>Mobile Application Development</span>
                        </h2>
                        <p className='md:text-xl sm:text-md leading-6'>We offer mobile app development services for the modern era that exceeds market norms and industry standards. </p>
                    </div>
                    <div className='flex md:flex-row sm:flex-col mt-8 relative '>
                        <div className='md:w-1/2 md:sticky top-0'>
                            <figure className='-z-50'>
                                <img className='align-middle max-w-[100%]' src="https://www.apptunix.ae/wp-content/uploads/2023/10/sec2.png" alt="android devlopment" />
                            </figure>
                        </div>

                        <div className='md:w-1/2 ps-2 pt-2 pb-10' >
                            <div className='overflow-hidden'>
                                <ul className='font-extrabold md:text-2xl  h-full'>
                                    <div data-aos="fade-up" className='h-[20%] flex flex-column space-y-5 overflow-hidden'>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            Cutting-Edge Digital Solutions
                                        </li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            C-Suite Consultation
                                        </li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            Next-Gen Designs For User Delight</li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            Dedicated Mobile App Developers</li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>

                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            Quality Assurance & Testing</li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>
                                            Agile methodology framework</li>
                                        <li className='flex flex-row p-6 bg-whiteBg hover:bg-gradiBgtopdown shadow rounded hover:text-white'>
                                            <figure>
                                                <img src={Tick} alt="check-mark" />
                                            </figure>Secured NDA Agreements
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* main section for shapping digital landscape */}
                <section className='bg-navyBlue md:mt-5 relative z-10 pt-5'>
                    <div className='container mx-auto sm:hidden md:block'>
                        <div data-aos="fade-up" className='text-center text-white font-bold md:text-5xl tablet:text-3xl sm:text-xl md:pt-2' style={{ lineHeight: '1.3' }}>
                            <h2>Shaping The Digital Landscape With</h2>
                            <span className='md:block'>Excellence & Expertise</span>
                        </div>
                        <div>
                            <p className='text-xl text-white text-center font-normal pt-2'>Our specialized procedure is designed for startups, mid-scale businesses, and large enterprises to help <span className='block'>
                                them achieve fantastic results & exceptional ROI.</span></p>
                        </div>

                        {/* for list + image */}
                        <div className='container mx-auto flex flex-row py-4'>
                            <div className="flex px-5 pt-3">
                                <span className="border-r-[3px] h-[86%] border-white px-[10px]"></span>
                            </div>
                            <div className='flex flex-column gap-5'>
                                {/* step 1 */}
                                {/* <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[73px] absolute'></div> */}
                                <div className='flex md:flex-row sm:flex-col justify-between'>
                                    <div className='md:w-[50%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>1 Step</span>
                                        </div>

                                        <h3 className='text-3xl font-black'>Ideal Valdation</h3>
                                        <p className='text-xl'>Share your project scope, goals, and technical requirements with us. The objective of this preliminary stage is to validate your app idea and make sure it has viability and success potential. This entails market research, determining your target market, and gauging the demand for your app. We construct a development schedule, a budget, and a plan for the project.</p>
                                    </div>
                                    <figure>
                                        <img src={Business} alt="Business" />
                                    </figure>
                                </div>

                                {/* step 2 */}
                                <div className='flex flex-row justify-between w-[95%]'>
                                    <div className='w-[45%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>2 Step</span>
                                        </div>
                                        <h3 className='text-3xl font-black'>Strategy Development</h3>
                                        <p className='text-xl'>After your concept has been proven, we must create a thorough app strategy. We will execute a strategy that fits your business objective and budget. This includes outlining the functions and features of your app, developing user personas, and designing the user experience (UX). The platforms (iOS, Android, web) and technology stack (programming languages, frameworks, databases) you'll utilize to create the app must also be chosen.</p>
                                    </div>
                                    <figure>
                                        <img src={Business1} alt="Business" />
                                    </figure>
                                </div>
                                {/* step 3 */}
                                <div className='flex flex-row justify-between  w-[95%]'>
                                    <div className='w-[50%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>3 Step</span>
                                        </div>
                                        <h3 className='text-3xl font-black'>App Deployment</h3>
                                        <p className='text-xl'>Our custom app developers curate the app infrastructure accordingly. The app is built in accordance with the guidelines established during the planning phase. Our developers and designers build the user interface (UI), add the app's features, and make sure everything runs properly. In this stage, databases, servers, and any other infrastructure required to run the app are also set up.</p>
                                    </div>
                                    <figure>
                                        <img src={Business2} alt="Business" />
                                    </figure>
                                </div>
                                {/* step 4 */}
                                <div className='flex flex-row justify-between w-[95%]'>
                                    <div className='w-[50%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>4 Step</span>
                                        </div>
                                        <h3 className='text-3xl font-black'>Launch & Market</h3>
                                        <p className='text-xl'>Post development, we handle the app launch and marketing aspects with you. We carry out a launch strategy once your app is finished and prepared for distribution. This entails developing marketing collateral, generating awareness via social media and press releases, and getting ready for the app store submission procedure. Maximizing downloads and visibility during the launch period is the objective.</p>
                                    </div>
                                    <figure>
                                        <img src={Business3} alt="Business" />
                                    </figure>
                                </div>
                                {/* step 5 */}
                                <div className='flex flex-row justify-between w-[95%]'>
                                    <div className='w-[45%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>5 Step</span>
                                        </div>
                                        <h3 className='text-3xl font-black'>Final Testing</h3>
                                        <p className='text-xl'>To make sure the app is free of errors and functions as intended, thorough testing is essential both before and after the launch. Our QA professionals take righteous action on data-driven analytics and insights. This covers testing for functionality, usability, and compatibility across a range of hardware and operating systems. Beta testing is used to find problems and gather important feedback for future improvements.</p>
                                    </div>
                                    <figure>
                                        <img src={Business4} alt="Business" />
                                    </figure>
                                </div>
                                {/* step 6 */}
                                <div className='flex flex-row justify-between w-[95%]'>
                                    <div className='w-[50%] text-white'>
                                        <div className='flex flex-row'>
                                            <div className='h-4 w-1 rounded-full bg-transparent outline-red-500 outline-4 border-blue-400 border-8 left-[137px] absolute'></div>
                                            <span className='text-grayishBlueText font-semibold text-sm'>6 Step</span>
                                        </div>
                                        <h3 className='text-3xl font-black'>Funding</h3>
                                        <p className='text-xl'>To ensure the success and longevity of your app, financial management is crucial. We are glad to offer guidance in preparing your investor fund pitch. Personal savings, loans, investors, crowdsourcing, or grants are all potential funding options. It's crucial to remember that developing an app is an iterative process, and constant updates and upgrades are frequently required.</p>
                                    </div>
                                    <figure>
                                        <img src={Business5} alt="Business" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Swpier 1 */}
                    <div className='sm:block md:hidden bg-navyBlue'>
                        <div className='container mx-auto'>
                            <div className='text-center text-white font-bold text-2xl py-4' style={{ lineHeight: '1.3' }}>
                                <h2>Shaping The Digital Landscape With Excellence & Expertise</h2>
                            </div>
                            <Swiper
                                spaceBetween={40}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 1400,
                                    disableOnInteraction: false,
                                }}

                                navigation={true}
                                modules={[Autoplay, Navigation]}
                                className="mySwiper " style={{ paddingBottom: '130px' }}
                                breakpoints={{
                                    960: {
                                        slidesPerView: 2.1,
                                    },
                                    760: {
                                        slidesPerView: 1.5,
                                    },
                                    350: {
                                        slidesPerView: 1,

                                    },
                                }}
                            >
                                <SwiperSlide className='rounded-xl hover:cursor-grab'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>Ideal Valdation</h3>
                                            <p className='text-sm'>Share your project scope, goals, and technical requirements with us. The objective of this preliminary stage is to validate your app idea and make sure it has viability and success potential. This entails market research, determining your target market, and gauging the demand for your app. We construct a development schedule, a budget, and a plan for the project.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className=' rounded-xl hover:cursor-grab overflow-hidden'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business1} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>Strategy Development</h3>
                                            <p className='text-sm'>After your concept has been proven, we must create a thorough app strategy. We will execute a strategy that fits your business objective and budget. This includes outlining the functions and features of your app, developing user personas, and designing the user experience (UX). The platforms (iOS, Android, web) and technology stack (programming languages, frameworks, databases) you'll utilize to create the app must also be chosen.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='rounded-xl hover:cursor-grab'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business2} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>App Deployment</h3>
                                            <p className='text-sm'>Our custom app developers curate the app infrastructure accordingly. The app is built in accordance with the guidelines established during the planning phase. Our developers and designers build the user interface (UI), add the app's features, and make sure everything runs properly. In this stage, databases, servers,
                                                and any other infrastructure required to run the app are also set up.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className=' rounded-xl hover:cursor-grab'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business3} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>Launch & Market</h3>
                                            <p className='text-sm'>Post development, we handle the app launch and marketing aspects with you. We carry out a launch strategy once your app is finished and prepared for distribution. This entails developing marketing collateral, generating awareness via social media and press releases, and getting ready for the app store submission procedure.
                                                Maximizing downloads and visibility during the launch period is the objective.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='rounded-xl hover:cursor-grab'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business4} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>Final Testing</h3>
                                            <p className='text-sm'>To make sure the app is free of errors and functions as intended, thorough testing is essential both before and after the launch. Our QA professionals take righteous action on data-driven analytics and insights. This covers testing for functionality,
                                                usability, and compatibility across a range of hardware and operating systems. Beta testing is used to find problems and gather important feedback for future improvements.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className='h-[300px] rounded-xl hover:cursor-grab'>
                                    <div className='h-[550px] space-y-4 justify-center border rounded-xl border-white border-2 p-4'>
                                        <figure>
                                            <img src={Business5} alt="Business" />
                                        </figure>
                                        <div className='text-white space-y-1'>
                                            <h3 className='text-2xl font-black'>Funding</h3>
                                            <p className='text-sm'>To ensure the success and longevity of your app, financial management is crucial. We are glad to offer guidance in preparing your investor fund pitch. Personal savings, loans, investors, crowdsourcing, or grants are all potential funding options.
                                                It's crucial to remember that developing an app is an iterative process, and constant updates and upgrades are frequently required.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                    {/*think card */}
                    <div className='container flex justify-between w-full sm:h-[300px] sm:bg-blackBg md:rounded-3xl sm:rounded-xl lg:bg-ThinkingMobileAppCardBg overflow-hidden'>
                        <div data-aos="fade-up" className='flex flex-col gap-4 justify-center py-5 lg:pl-10 sm:p-5 lg:p-0 sm:text-center lg:text-start rounded-3xl sm:w-full lg:bg-transparent sm:bg-black' >
                            <h1 className='md:text-3xl sm:text-xl text-white font-bold w-full leading-relaxed' style={{ lineHeight: '1.4' }}>Code Meets Creativity: Building
                                <span className='md:block'>Apps that Inspire</span> </h1>
                            <p className='md:text-xl text-white'>Our mobile app development company is here to elevate your
                                <span className='md:block'>business potential with future-ready and innovative apps.</span></p>
                            <div>
                                <button className='md:text-[20px] p-[12px] font-semibold rounded-lg bg-whiteBg hover:text-white hover:bg-primaryBg transition-all duration-500 ease-in-out'
                                    onClick={() => setEngagementModelOpen(true)}>
                                    Let's Develop Brilliance &nbsp;<span><EastSharpIcon /></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Modern Technologies For New-Age Apps */}
                    <ModernTechnologies />
                </section>


                {/* Your Vision, Our Expertise */}
                <section className='container mx-auto py-5'>
                    <div className='text-center'>
                        <h2 className='font-black md:text-5xl sm:text-3xl'>Your Vision, Our Expertise</h2>
                        <p className='md:text-xl font-normal'>Our tech-driven custom app developers always stay on their toes to build the best mobile
                            <span className='block'>
                                application to help you achieve exponential growth in the market. We bring the best for you!
                            </span>
                        </p>
                    </div>
                    {/* app development Carousel */}
                    <div className='mt-10 container sm:hidden md:block'>
                        <AndroidCarousel />
                        <div className='flex justify-center'>
                            <button className='btn bg-gradiBg' onClick={() => setEngagementModelOpen(true)}>Build Your App Today &nbsp;<span><EastOutlinedIcon /></span></button>
                        </div>
                    </div>
                </section>
                {/* App Development Methodology */}
                <section className='sm:hidden md:block py-10 container mx-auto'>
                    <div className='text-center'>
                        <h2 className='font-black md:text-5xl sm:text-2xl'>We Follow A Power-Packed App Development Methodology</h2>
                        <p className='md:text-xl font-normal pt-3'>Our technical process sets us apart, we utilize the latest programming languages to ensure your app stands out with exceptional functionality and performance.
                        </p>
                    </div>
                    <ApptMethodology />
                </section>
                <section>
                    {/*  */}
                    <div className='container z-10 flex justify-between w-full sm:h-[300px]  md:rounded-3xl sm:rounded-xl' style={{ backgroundImage: `url(${CustomMobile})` }}>
                        <div className='flex flex-col gap-4 justify-center py-5 lg:pl-10 sm:p-5 lg:p-0 sm:text-center lg:text-start rounded-3xl sm:w-full lg:bg-transparent sm:bg-transparent' >
                            <h2 className='md:text-3xl sm:text-xl relative font-semibold text-white'>
                                Custom
                                <span className='text-black'>Mobile App Development </span>
                                to
                                <span className='md:block'>
                                    Meet Business-Specific Challenges
                                    <p className='font-normal md:text-xl sm:text-xs'>
                                        Our experienced custom app developers will help you transform your
                                        <span className='block'>
                                            business digitally with an ubermodern tech suite.
                                        </span>
                                    </p>
                                </span>
                            </h2>
                            <div>
                                <button className='md:text-[20px] p-[12px] font-semibold rounded-lg bg-whiteBg hover:text-white hover:bg-primaryBg transition-all duration-500 ease-in-out'
                                    onClick={() => setEngagementModelOpen(true)}>
                                    Let's Develop Brilliance &nbsp;<span><EastSharpIcon /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Starts from Here */}
                    <div className='relative -z-10 bg-darkBlueShadeBg mt-[-100px] pb-[140px] w-full pt-32 text-white'>
                        <div className='container mx-auto'>
                            <div className='flex md:flex-row sm:flex-col justify-between items-center'>
                                <div className='w-1/2 flex items-center oject-contain'>
                                    <img src={Fly} alt="fly" />
                                </div>
                                <div className='md:w-1/2 space-y-5'>
                                    <h4 className='md:text-4xl sm:text-2xl font-semibold'>
                                        Create a Buzz Around Your Business App Prior to Its Launch
                                    </h4>
                                    <p className='font-normal md:text-xl leading-7'>
                                        Leverage our pre-launch and post-launch marketing services to generate excitement around your solution. Increase the anticipation of your users and give them more than what they want. Businesses with a pre-launch marketing strategy have 25% more chances of launching a well-rounded product.
                                    </p>
                                    <div >
                                        <p className='btn bg-gradiBg' onClick={() => setEngagementModelOpen(true)}>Grow your business &nbsp;<span><EastOutlinedIcon /></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* 2nd innn */}
                            <div className='flex md:flex-row sm:gap-y-5 sm:flex-col justify-between pt-10'>
                                <div className='flex md:flex-row gap-y-3 sm:flex-col md:w-1/2 md:border-none sm:border border-white rounded-xl sm:p-3 md:p-0'>
                                    <figure className='md:w-1/2'>
                                        <img className='w-14' src={Like} alt='like' />
                                    </figure>
                                    <div>
                                        <h3 className='md:text-3xl sm:text-2xl font-medium '>
                                            Pre-Launch Marketing
                                        </h3>
                                        <p className='leading-7 font-normal md:text-xl'>
                                            Our marketing wizards perform extensive research on the targeted audience and competitors to come up with the best pre-launch marketing campaigns.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex md:flex-row gap-y-3 sm:flex-col md:w-1/2 md:ps-6 md:border-none sm:border border-white rounded-xl sm:p-3 md:p-0'>
                                    <figure className='md:w-1/2'>
                                        <img className='w-14' src={Focus} alt='like' />
                                    </figure>
                                    <div className='md:ps-8'>
                                        <h3 className='md:text-3xl sm:text-2xl font-medium '>
                                            Post-Launch Marketing
                                        </h3>
                                        <p className='leading-7 font-normal md:text-xl'>
                                            Post-launch marketing campaigns help to increase your app’s visibility and reach. Combined with the ASO best practices, Apptunix ensures more app installs, better engagement, and increased revenue growth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* video section */}
                            <div className='flex md:flex-row sm:flex-col justify-between items-center mt-20'>
                                <div className='md:w-1/2 space-y-6'>
                                    <h4 className='md:text-4xl sm:text-2xl font-semibold'>
                                        Everything You Need To Build, Deploy & Launch An Outstanding App
                                    </h4>
                                    <p className='font-normal md:text-xl leading-7'>
                                        Startups- With user-friendly features and a smooth interface, our mobile app development company empowers entrepreneurs to upend the market, enthrall audiences, and fuel exponential growth.
                                        <br />
                                        SMEs- We support SMEs in developing mobile apps that are centered on their unique needs. Hire dedicated mobile app developers to create native, cross-platform, or hybrid apps.
                                        <br />
                                        Enterprises- Global businesses get a competitive edge with our enterprise mobile app development services engineered with smart technology, strong security protocols, and scalable architectures to achieve high trademarks.
                                    </p>
                                </div>
                                <div className='md:w-1/2 flex items-center justify-center'>
                                    <video loop="true" autoPlay="true" muted="false" playsInline="" src={AndroidVideo2} __idm_id__="1261570" style={{ height: "500px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* custom mobile ending */}
                    <div className='container mx-auto'>
                        <div className='flex justify-center items-center'>
                            <div className='z-10 py-5 px-4 space-y-3 bg-white rounded-3xl relaive mt-[-100px] mb-[100px] shadow text-center md:w-[900px]'>
                                <h2 className='md:text-3xl sm:text-2xl relative font-semibold'>
                                    Great Ideas. Brilliantly Executed.</h2>
                                <p className='font-normal md:text-xl'>
                                    We are committed to delivering projects on time and meeting deadlines with the utmost diligence.
                                </p>
                            </div>

                        </div>
                        <div className='sm:hidden md:block mt-[-100px]'>
                            <figure className='flex justify-center items-center'>
                                <img src={DotedLine} alt="DotedLine" />
                            </figure>
                        </div>
                    </div>

                    <div className='grid md:grid-cols-4 gap-4 container'>
                        <div className="block bg-white p-6 shadow-md text-center px-10 py-8" style={{ boxShadow: '0 2px 4px -1px #FF8700, 0 2px 4px -1px #FF8700' }}>
                            <h5 className="text-2xl font-bold text-[#FF8700]">10+</h5>
                            <p className="block font-normal text-gray-700 dark:text-gray-400">Years of Experience in <br /> Mobile App Development</p>
                        </div>
                        <div
                            className="block bg-white shadow-md text-center px-10 py-8" style={{ boxShadow: '0 2px 4px -1px #00B400, 0 2px 4px -1px #FF8700' }}>
                            <h5 className="text-2xl font-bold text-[#00B400]">30+</h5>
                            <p className="block font-normal text-gray-700 dark:text-gray-400">Multi-Million Dollar <br /> Businesses Built</p>
                        </div>
                        <div
                            className="block bg-white shadow-md text-center px-10 py-8" style={{ boxShadow: '0 2px 4px -1px #177DFF, 0 2px 4px -1px #FF8700' }}>
                            <h5 className="text-2xl font-bold text-[#177DFF]">100+</h5>
                            <p className="block font-normal text-gray-700 dark:text-gray-400">Skilled Professionals at <br /> Your Service</p>
                        </div>
                        <div
                            className="block bg-white py-8 shadow-md text-center px-10" style={{ boxShadow: '0 2px 4px -1px #EE2671, 0 2px 4px -1px #FF8700' }}>
                            <h5 className="text-2xl font-bold text-[#EE2671]">500+</h5>
                            <p className="block font-normal text-gray-700 dark:text-gray-400">Custom App Solutions <br /> Delivered</p>
                        </div>
                    </div>
                    <div className='flex flex-row justify-center mt-16'>
                        <button className='btn bg-gradiBg' onClick={() => setEngagementModelOpen(true)}>Explore Pricing &nbsp;<span><EastOutlinedIcon /></span></button>
                    </div>

                </section>
                <section className='container mx-auto flex flex-col gap-5 py-5'>
                    <div className='text-center'>
                        <h2 className='font-extrabold md:text-5xl sm:text-2xl'>Transforming 20+ Industries With Innovation</h2>
                        <p className='md:text-xl font-normal pt-3'>We have been delivering stellar mobile app development services for a decade now. As a top mobile application development company, Apptunix has architected and maintained thousands of apps for VC-backed startups, government organizations, and Fortune 500 companies worldwide.
                        </p>
                    </div>
                    <div>
                        <AppCard />
                    </div>
                </section>
                {/* TechnologyStack section */}
                <section>
                    <TechnologyStack />
                    <div className='container mx-auto'>
                        <div className='py-20'>
                            <div className='flex justify-between w-full h-[250px] bg-black rounded-3xl'>
                                <div className='py-5 lg:pl-10 lg:p-0 text-white sm:text-center lg:text-start lg:w-full sm:w-full'>
                                    <h1 className='lg:text-4xl sm:text-2xl font-bold leading-relaxed' style={{ lineHeight: '1.3' }}>Want to Champion Your Business Vertical?</h1>
                                    <p>Connect With Our Expert Business Consultants</p>
                                    <div className='md:pt-12 sm:mt-3'>
                                        <button class='bg-gradiBg btn' onClick={() => setEngagementModelOpen(true)}>
                                            Schedule a Call &nbsp;<span><EastSharpIcon /></span>
                                        </button>
                                    </div>
                                </div>
                                <div className='lg:w-[50%] lg:block sm:hidden bg-gradient-to-b from-secondaryText to-primaryText clip-path-polygon rounded-r-3xl'>
                                    <img className='lg:w-full h-full pl-6' src={ScheduleMeeting} alt='Pakistan_City' />
                                </div>
                            </div>
                        </div>
                        <h1 className='md:text-5xl sm:text-2xl text-center md:font-bold sm:font-semibold leading-snug pb-5'>Explore Our Outstanding App Development Pakistan Portfolio</h1>
                    </div>
                </section>
                {/* Why choose Appentix */}
                <section>
                    <ChooseUsCards />
                </section>
                {/* ImageCarasoul */}
                <section className='pt-12'>
                    <h2 className='font-bold md:text-5xl sm:text-2xl text-center pb-12'>Become A Part Of Over 1,000+ Businesses  Who<span className="md:block text-text"> Accomplished Marvels With Us</span></h2>
                    <ImageCarasoul />
                </section>
                {/* Our Partners */}
                <section className='text-center flex flex-col gap-y-4'>
                    <h2 className='font-extrabold md:text-5xl sm:text-3xl text-center'>Our Partners</h2>
                    <p className='font-normal'>Apptunix partnered up with top leading brands to provide a scalable<span className="md:block"> gateway for business growth.</span></p>
                    {/* Our partner component */}
                    <OurPartner />
                </section>
                {/* Quuestions Related to Mobile Appp development */}
                <section>
                    <MobileAppQuestions />
                </section>
                {/* Form Control */}
                <section>
                    <ContactForm />
                </section>
                {EngagementModelOpen && (
                    <EngagementModel onClose={() => setEngagementModelOpen()} />
                )}
            </div>
        </>
    )
}
