import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import slide1 from 'assets/Images/ImageCaraousal1.png'
import slide2 from 'assets/Images/ImageCaraousal2.png'
import slide3 from 'assets/Images/ImageCaraousal3.png'
import slide4 from 'assets/Images/ImageCaraousal4.png'
import slide5 from 'assets/Images/ImageCaraousal5.png'
import slide6 from 'assets/Images/ImageCaraousal6.png'
import slide7 from 'assets/Images/ImageCaraousal7.png'
import slide8 from 'assets/Images/ImageCaraousal8.png'
import slide9 from 'assets/Images/ImageCaraousal9.png'

// slides Images
import playstoreLogo from 'assets/Images/swiper-slider-02.png'
import applestoreLogo from 'assets/Images/swiper-slider01.png'

//country logos
import ukLogo from 'assets/Images/swiper-slider10.png'
import australiaLogo from 'assets/Images/swiper-slider03.png'
import kuwaitLogo from 'assets/Images/swiper-slider05.png'
import uaeLogo from 'assets/Images/swiper-slider05.png'
import bahrainLogo from 'assets/Images/swiper-slider07.png'
import usaLogo from 'assets/Images/swiper-slider09.png'
import canadaLogo from 'assets/Images/swiper-slider-4.png'

// Import Swiper styles
import 'swiper/css';
import 'component/carousel/Imagecarasol.css';

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';

export default function ImageCarasoul() {
    return (
        <>
            <Swiper
                spaceBetween={40}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 1700,
                    disableOnInteraction: false,
                }}

                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper " 
                breakpoints={{
                    960: {
                        slidesPerView: 2.1,
                    },
                    760: {
                        slidesPerView: 1.5,
                    },
                    350: {
                        slidesPerView: 1,
                    
                    },
                }}
            >
                <SwiperSlide className='bg-[#d386ac] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide1} alt='Slide-1' />
                        </span>
                        <div className='w-1/2'>
                            <h2 className='text-3xl font-semibold line-clamp-3'> Beauty Services</h2>
                            <p className='text-sm font-semibold line-clamp-3'>This app lets users get the best stylist, hairstyle, massage, and other beauty services based on their location. To start using the service, users need to create a profile.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>5K</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={australiaLogo} /></span>Australia</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#fbb03a] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide2} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            {/* <img src='' alt='Null' /> */}
                            <h2 className='text-3xl font-semibold'> Moving & Delivery app</h2>
                            <p className='text-sm font-semibold line-clamp-3'>A Kuwait-based on demand pickup and delivery service that allows its users to schedule their pickup anytime and anywhere.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>5K+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={kuwaitLogo} /></span>Kuwait</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-dark rounded-xl py-3 px-3 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide3} alt='Slide-3' />
                        </span>
                        <div className='w-1/2'>
                            <h2 className='text-3xl font-semibold'>Food Delivery App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>A food delivery app tailored specifically for Expo City in Dubai, offering convenient and efficient food ordering and delivery services within the expo city grounds.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>10K+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={uaeLogo} /></span>UAE</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#5a73e9] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide4} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            <h2 className='text-3xl font-semibold'> Shopping App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>This shopping app is your no.1 fashion & beauty destination in Saudi Arabia, United Arab Emirates, Kuwait, Oman, Bahrain, Qatar & Iraq.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>10M+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={uaeLogo} /></span>UAE</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#c8a44b] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide5} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            <h2 className='text-3xl font-semibold'> Food Delivery App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>A Bahrain-based on demand food ordering and delivering platform that enables safe and timely delivery to its customers.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>10k+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={bahrainLogo} /></span>Bahrain</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#245abc] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide6} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            <h2 className='text-3xl font-semibold'> Travelling App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>This app is your travel partner whenever you need a place to stay, offering more than two million distinctive locations in 190 countries.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>10M+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={uaeLogo} /></span>UAE</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#aa93d7] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide7} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            <h2 className='text-3xl font-semibold'> Fitness <br/>App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>This is an online health & fitness platform that offers workout instructions, exercise sessions, meal plans and more to its users.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>500+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center gap-1'> <span><img src={ukLogo} /></span>UK</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#da0006] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide8} alt='Slide-1' />
                        </span>
                        <div className='w-1/2 '>
                            <h2 className='text-3xl font-semibold'>Fast Food App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>They are a very well-known restaurant chain that offers a plethora of food items across the globe via its outlets. It also allows placing orders.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>100M+</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center'> <span><img src={usaLogo} /></span>USA</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='bg-[#101010] rounded-xl py-3 px-4 hover:cursor-grab'>
                    <div className='flex items-center gap-4 text-white' >
                        <span className='w-1/2'>
                            <img className='w-100' srcSet={slide9} alt='Slide-1' />
                        </span>
                        <div className='w-1/2'>
                            <h2 className='text-3xl font-semibold'>Food Delivery App</h2>
                            <p className='text-sm font-semibold line-clamp-3'>A food delivery app that connects users with a broad range of local restaurants to order their favorite cuisine in the comfort of their homes and skip waiting for long reservations.</p>
                            <div className='flex items-center gap-5 my-3'>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold '>Downloads</span>
                                    <span className='text-lg font-bold'>500++</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-semibold'>Region</span>
                                    <span className='text-lg font-bold inline-flex items-center'><span><img src={canadaLogo} /></span>Canada</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-lg font-bold'>Available on:</span>
                                <div className='flex gap-2 items-center pt-2  overflow-hidden'>
                                    <figure><img src={playstoreLogo} alt='playStore' /></figure>
                                    <figure><img src={applestoreLogo} alt='appleStore' /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </>
    );
}