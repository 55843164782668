import React from 'react';
import AppdevelopmentCarouselModel from './appdevelopmentCarousel'
import Slider from 'react-slick';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', color:"black",left:"33%",top:"70px",zIndex:"1" }}
            onClick={onClick}
        >
            <WestIcon/>
        </div>
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', color:"black",right:"35%",top:"70px",zIndex:"1" }}
            onClick={onClick}
        >
         <EastIcon/>   
        </div>
    );
};

const Appdevelopment = () => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 10000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };
    return (
        <>
            <div className='my-10'>
                <Slider {...settings}>
                    {AppdevelopmentCarouselModel.map((item, index) => (
                        <div key={index}className={`testimonial-slide px-16 bg-color-${item.id}`}style={{backgroundColor:item.bgcolor}}>
                           <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </Slider>
                </div>
        </>
    );
};

export default Appdevelopment;
