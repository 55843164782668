const AppdevelopmentCarousel=[
    {
        id:1,
        title:"Android Mobile",
        title_type:" App Development",
        description:"We curate web-based Android applications to cater to a large audience of Android users. Our mobile app development company is an expert at creating high-end native apps utilizing modern, platform-specific programming languages and tools that adhere to all performance metrics.",
        bgcolor:"linear-gradient(to right, rgb(248, 65, 131), rgb(128, 41, 72))",
    },
    {
        id:2,
        title:"iPhone Mobile",
        title_type:"App Development ",
        description:"Our expert team designs cutting-edge and future-ready applications that maximize engagement with iPhone users. When developing iOS apps, our skilled developers use advanced tools like Swift and Objective-C to meet each client's specific needs.",
        bgcolor:"linear-gradient(to right, rgb(124, 114, 255), rgb(83, 77, 175))",
    },
    {
        id:3,
        title:"Hybrid Mobile App",
        title_type:" Development Services ",
        description:"By combining native and web app technologies in a novel way, you can acquire very useful cross-platform apps that excel in a variety of settings. You can create hybrid or cross-platform apps with our hybrid mobile app development services to cater a variety of platforms.",
        bgcolor:"linear-gradient(to right, rgb(34, 219, 194), rgb(41, 128, 101))",
    },
    {
        id:4,
        title:"Custom Mobile ",
        title_type:"Application Development",
        description:"We develop solutions that are specifically created to satisfy your goals and specifications while offering a distinctive app experience. Our custom mobile application development company creates highly effective and aesthetically pleasing apps for iOS and Android stores.",
        bgcolor:"linear-gradient(to right, rgb(255, 156, 113), rgb(126, 70, 45))",
    },
    { 
        id:5,
        title:"Android Mobile",
        title_type:" App Development",
        description:"We curate web-based Android applications to cater to a large audience of Android users. Our mobile app development company is an expert at creating high-end native apps utilizing modern, platform-specific programming languages and tools that adhere to all performance metrics.",
        bgcolor:"linear-gradient(to right, rgb(248, 65, 131), rgb(128, 41, 72))",    
    },
    {
        id:6,
        title:"iPhone Mobile",
        title_type:"App Development ",
        description:"Our expert team designs cutting-edge and future-ready applications that maximize engagement with iPhone users. When developing iOS apps, our skilled developers use advanced tools like Swift and Objective-C to meet each client's specific needs.",
        bgcolor:"linear-gradient(to right, rgb(124, 114, 255), rgb(83, 77, 175))",
    },
    {
        id:7,
        title:"Hybrid Mobile App",
        title_type:" Development Services ",
        description:"By combining native and web app technologies in a novel way, you can acquire very useful cross-platform apps that excel in a variety of settings. You can create hybrid or cross-platform apps with our hybrid mobile app development services to cater a variety of platforms.",
        bgcolor:"linear-gradient(to right, rgb(34, 219, 194), rgb(41, 128, 101))",
    },
    {
        id:8,
        title:"Custom Mobile ",
        title_type:"Application Development",
        description:"We develop solutions that are specifically created to satisfy your goals and specifications while offering a distinctive app experience. Our custom mobile application development company creates highly effective and aesthetically pleasing apps for iOS and Android stores.",
        bgcolor:"linear-gradient(to right, rgb(255, 156, 113), rgb(126, 70, 45))",
    },
  ]
  export default AppdevelopmentCarousel