import React from 'react'
import Block1 from 'assets/png/blockchain development/blksrvc-1.jpg'
import Block2 from 'assets/png/blockchain development/blksrvc-2.jpg'
import Block3 from 'assets/png/blockchain development/blksrvc-3.jpg'
import Block4 from 'assets/png/blockchain development/blksrvc-4.jpg'
import Block5 from 'assets/png/blockchain development/blksrvc-5.jpg'
import Block6 from 'assets/png/blockchain development/blksrvc-6.jpg'
import Block7 from 'assets/png/blockchain development/blksrvc-7.jpg'
import Block8 from 'assets/png/blockchain development/blksrvc-8.jpg'
import Block9 from 'assets/png/blockchain development/blksrvc-9.jpg'
export default function BlockchainServiceCards() {
    return (
        <>
            <div className="container mx-auto grid md:grid-cols-3 tablet:grid-cols-2 gap-3 pb-20 text-white">
                {/* column 1 */}
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500" className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <figure>
                            <img className="rounded-xl" src={Block1} alt="Block1" />
                        </figure>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">Blockchain-Powered Metaverse Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Get your business future-ready for interactive virtual and artificial space with blockchain-powered metaverse solutions. From frontend development to hosting your project, our blockchain development company handles everything.</p>
                        </div>
                    </div>
                {/* column 2 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1400" 
                className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block2} alt="Block2" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">NFT Marketplace Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">To make it simple to buy, sell, and bid on multi-chain compatible NFTs, our blockchain development company builds NFT marketplaces that are both transparent and feature-rich.</p>
                        </div>
                    </div>
                {/* column 3 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" data-aos-delay="800"
                className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block3} alt="Block3" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">Blockchain Wallet Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Enabling the interchange of multiple digital assets & currencies through the creation of cryptographic wallets with both hot & cold storage capabilities</p>
                        </div>
                    </div>
                {/* column 4 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block4} alt="Block4" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">Smart Contract Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">From finance to insurance, we create solutions tailored perfectly to empower enterprises with a secure smart contract aligned with their business requirements. We provide services for diverse industries and business models.</p>
                        </div>
                    </div>
                {/* column 5 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1400"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block5} alt="Block5" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">dApp Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Creating and restructuring the traditional mobile application processes with enterprise-grade decentralized applications from planning and designing to development and marketing to maximize ROI.</p>
                        </div>
                    </div>
                {/* column 6 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" data-aos-delay="800"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block6} alt="Block6" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">Real Estate Tokenization</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Make global real estate investments much easier with the top blockchain software development company. Tokenize your real estate properties with fractional ownership and offer them to potential buyers by significantly reducing the barrier to entry and increasing the security simultaneously.</p>
                        </div>
                    </div>
                {/* column 7 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block7} alt="Block7" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">DeFi 2.0 Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Create decentralized platforms and financial products with the power of DeFi 2.0 to stand out in the crypto space. Utilize the most trusted DeFi protocols backed by the Ethereum blockchain using our premier DeFi development services.</p>
                        </div>
                    </div>
                {/* column 8 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1400"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block8} alt="Block8" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">Cryptocurrency Trading Bot</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Our Cryptocurrency Trading Bot Development service is an all-inclusive, institutional-grade framework with numerous exchange connectors and 24*7 operations to render first-class outcomes.</p>
                        </div>
                    </div>
                {/* column 9 */}
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000" data-aos-delay="800"
                 className="text-white bg-cardPrimary p-[12px] max-w-sm rounded-lg">
                        <div className=''>
                            <img className="rounded-xl" src={Block9} alt="Block9" />
                        </div>
                        <div className="p-3">
                            <a href="##">
                                <h3 className="mb-2 text-[26px] font-semibold tracking-tight">IDO Development</h3>
                            </a>
                            <p className="mb-3 pt-2 text-base font-normal">Raise capital in crypto tokens with End-to-end IDO development solutions and efficient real-time digital asset trading systems. We offer strategy design, token development, token listing advisory, and more.</p>
                        </div>
                    </div>
            </div>
        </>
    )
}
